import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import './HomeSoftware.css'; // Stelle sicher, dass du eine CSS-Datei mit diesem Namen hast

const softwareComponents = [
    { id: 1, link: '/eos', screen: './img/mac_eos.webp', title: 'Das All-In-One Ordinationssystem', text: 'Die Software für Fachärzte und Allgemeinmediziner. Zuverlässig, schnell und bewährt.', logo: './img/eos_logo.webp', backgroundImage: './img/background_software_1.webp', gradient: 'linear-gradient(to top, #303455 0%, transparent 100%)' },
    { id: 2, link: '/termion', screen: './img/termion_display.webp', title: 'Terminbuchung & Patientenverwaltung', text: 'Unkompliziert, vollautomatisch und rund um die Uhr für ihre Patienten verfügbar.', logo: './img/termion_logo.webp', backgroundImage: './img/termion_screen.webp', gradient: 'linear-gradient(to top, #E26D60 0%, transparent 100%)' },
    { id: 3, link: '/dental', screen: './img/Mockup_Dental_Pro.webp', title: 'Software für Zahnärzte', text: 'Dental Pro besticht seit über 20 Jahren durch Stabilität und Effizienz.', logo: '/img/dental_pro_logo.webp', backgroundImage: './img/dental_1.webp', gradient: 'linear-gradient(to top, #fbc2eb 0%, transparent 100%)' },
    { id: 4, link: '/', screen: './img/Befund_Plus_Render.webp', title: 'Ihre Vital & Gesundheits App', text: 'Die BefundPlus App ist der ideale Wegbegleiter für Patienten die Gesundheit leben.', logo: './img/befund_plus_logo.webp', backgroundImage: './img/befund_background.webp', gradient: 'linear-gradient(to top, #E26D60 0%, transparent 100%)' },
  
  ];
  

  const HomeSoftware = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [scrollTimeout, setScrollTimeout] = useState(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
    const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const sliderRef = useRef(null);


  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - sliderRef.current.offsetLeft);
    sliderRef.current.style.cursor = 'grabbing';
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Scroll-Geschwindigkeit
    sliderRef.current.scrollLeft -= walk;
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    sliderRef.current.style.cursor = 'grab';
  };

  const handleMouseLeave = () => {
    if (isDragging) {
      setIsDragging(false);
      sliderRef.current.style.cursor = 'grab';
    }
  };




    useEffect(() => {
      function handleResize() {
        setIsMobile(window.innerWidth < 1000);
      }
  
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
  
    const handleScroll = (e) => { /* ... Dein bestehender handleScroll ... */ };
  
    useEffect(() => {
      const container = document.querySelector('.softwareSlider');
      const itemWidth = document.querySelector('.softwareComponent').offsetWidth;
      container.scrollLeft = itemWidth * activeIndex;
    }, [activeIndex]);
  
    const handlePrevClick = () => {
      if (activeIndex > 0) {
        setActiveIndex(activeIndex - 1);
      }
    };
  
    const handleNextClick = () => {
      let maxIndex = softwareComponents.length - 1;
      if (!isMobile) maxIndex--; // Passt maxIndex auf Desktop an, wenn nötig
      if (activeIndex < maxIndex) {
        setActiveIndex(activeIndex + 1);
      }
    };

  return (
    <div className="homeSoftware">
      <div className="contentSection">
        <h1>Unsere Software-Bestseller</h1>
      </div>
      <div className="sliderSection" onMouseLeave={handleMouseLeave}> 
        <div className="softwareSlider"          ref={sliderRef}
 onScroll={handleScroll}      className="softwareSlider"
 onMouseDown={handleMouseDown}
 onMouseMove={handleMouseMove}
 onMouseUp={handleMouseUp}>
        {softwareComponents.map(({ id,link, title, screen, text, logo, backgroundImage, gradient }) => (
 <Link to={link}>
 <div className="softwareComponent" key={id}>
          <div className="software-top-pic">
        <img src={screen} alt="screen" /> 
    </div>
    <div className="backgroundImage" style={{ backgroundImage: `url(${backgroundImage})` }}></div> {/* Hintergrundbild-Container */}
    <div className="software-info" style={{ background: gradient }}>
    <img src={logo} alt="Logo" className="logo-img" />
      <div>
      <h2>{title}</h2>
      <p>{text}</p>
      </div>
      <button className="software-button">Mehr erfahren</button>
    </div>
  </div>
  </Link>
))}


        </div>
        <div className="software-button-div">
             <button
        className={`navigateButton prev ${activeIndex === 0 ? 'disabled' : ''}`}
        onClick={handlePrevClick}
      >
        <img src="./img/down.svg" alt="down arrow" style={{rotate: '90deg'}}/>
      </button>
      <button
        className={`navigateButton next ${activeIndex === softwareComponents.length - 1 || (!isMobile && activeIndex >= softwareComponents.length - 2) ? 'disabled' : ''}`}
        onClick={handleNextClick}
        style={{marginLeft: '10px'}}
      >
        <img style={{rotate: '270deg'}} src="./img/down.svg" alt="down arrow"/>
      </button>
   
        </div>
      </div>
    </div>
  );
};

export default HomeSoftware;
