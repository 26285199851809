import React, { useState } from 'react';
import './Kontaktformular1.css';
import { useHistory } from 'react-router-dom';

function Kontaktformular1() {
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [error, setError] = useState('');
  const NextStep = () => {
    if (step === 0) {
      if (
        inputField.name !== '' &&
        inputField.email !== '' &&
        inputField.tel !== ''
      ) {
        var $selector = document.getElementById('kontakt_button');
        $selector.classList.remove('deactive');
        setStep(step + 1);
      }
    } else if (step === 1) {
      if (inputField.produkt !== '' && inputField.aktion !== '') {
        setStep(step + 1);
      }
    }
  };

  const [isButtonActive, setIsButtonActive] = useState(false);

  const [inputField, setInputField] = useState({
    vorname: '',
    nachname: '',
    email: '',
    tel: '',
    produkt: '',
    aktion: '',
    kommentar: '',
  });

  const inputsHandler = (e) => {
    setInputField(prevState => ({ ...prevState, [e.target.name]: e.target.value }));
    e.preventDefault();
    var $selector = document.getElementById('kontakt_button');
    if (step === 0) {
      if (
        inputField.name !== '' &&
        inputField.email !== '' &&
        inputField.tel !== ''
      ) {
        $selector.classList.remove('deactive');
      }
    } else if (step === 1) {
      if (
        (inputField.produkt !== '' ||
          (e.target.name === 'produkt' && e.target.value !== '')) &&
        (inputField.aktion !== '' ||
          (e.target.name === 'aktion' && e.target.value !== ''))
      ) {
        $selector.classList.remove('deactive');
      }
    } else {
      $selector.classList.remove('deactive');
    }
  };

  
  function sendToDatabase(formData) {
    fetch("https://system.mcw.at/service/post.php", {
      method: "POST",
      body: formData,
    })
    .then(response => {
      if (!response.ok) {
        throw new Error(`Server antwortete mit Status: ${response.status}`);
      }
      return response.text(); // Nehmen Sie die Antwort als Text, da wir wissen, dass es sich um eine Zahl handelt
    })
    .then(text => {
      const serverResponse = text.trim(); // Entfernen Sie Leerzeichen, falls vorhanden
      const responseNumber = parseInt(serverResponse, 10); // Versuchen Sie, die Antwort in eine Zahl umzuwandeln
  
      // Überprüfen Sie, ob die Antwort eine Zahl ist und nicht 0
      if (!isNaN(responseNumber) && responseNumber !== 0) {
        console.log("Daten erfolgreich gesendet, Serverantwort:", serverResponse);
        setError('');
        history.push('/bestätigung');
      } else {
        throw new Error(`Serverantwort war nicht erfolgreich: ${serverResponse}`);
      }
    })
    .catch(error => {
      console.error("Fehler:", error);
      setError(error.message);
    });
  }
  
  
  function handleSubmit(e) {
    e.preventDefault();
    const formData = new FormData();
    Object.keys(inputField).forEach(key => formData.append(key, inputField[key]));
    
    formData.append("service", "wwwMcwContactStepByStep");
    formData.append("crypt", ""); // Angenommen, dies bleibt leer
  
    sendToDatabase(formData);
  }
  

  return (
    <div>
      {error && <div className="error-message">{error}</div>}
      {step === 0 ? (
        <>
          <div className="kontaktformular_1">
            <div className="kontaktformular_1_schritte">
              <h4 className="selected">Schritt 1</h4>
              <hr />
              <h4>Schritt 2</h4>
              <hr />
              <h4>Schritt 3</h4>
            </div>
            <div className="kontaktformular_1_title">
              <h1>Kontaktformular</h1>
              <p>Bitte geben Sie Ihre persönlichen Informationen ein.</p>
            </div>
            <div className="kontaktformular_1_formulare">
              <form
                method="POST"
                onSubmit={NextStep}
                id="contact-form"
                name="contact-form"
              >
                    <label className="custom_field one">
              <input
                name="vorname"
                onChange={inputsHandler}
                type="text"
                required
              />
                                              <span className="placeholder">Vorname</span>
                                              <img src="./img/user-2.svg" alt="" />

            </label>
            <label className="custom_field one">
              <input
                name="nachname"
                onChange={inputsHandler}
                type="text"
                required
              />
                                <span className="placeholder">Nachname</span>
                                <img src="./img/user-2.svg" alt="" />

            </label>
                <label className="custom_field one">
                  <input
                    type="text"
                    name="email"
                    onChange={inputsHandler}
                    required
                  />
                  <span className="placeholder">E-Mail Adresse</span>
                  <img src="./img/mail-2.svg" alt="" />

                </label>
                <label className="custom_field one">
                  <input
                    type="text"
                    name="tel"
                    onChange={inputsHandler}
                    required
                  />
                  <span className="placeholder">Telefonnummer</span>
                  <img src="./img/telephone.svg" alt="" />
                </label>
                <input type="hidden" name="service" value="SERVICE_VALUE"/>
                <button
                  className={isButtonActive ? '' : 'deactive'}                  
                  id="kontakt_button"
                  type="button"
                  onClick={NextStep}
                >
                  Weiter
                </button>
              </form>
            </div>
          </div>
        </>
      ) : (
        <div>
          {step === 1 ? (
            <>
              {' '}
              <div className="kontaktformular_1">
                <div className="kontaktformular_1_schritte">
                  <h4>Schritt 1</h4>
                  <hr />
                  <h4 className="selected">Schritt 2</h4>
                  <hr />
                  <h4>Schritt 3</h4>
                </div>
                <div className="kontaktformular_1_title">
                  <h1>Produkt Auswählen</h1>
                  <p>Für welches Produkt interessieren Sie sich?</p>
                </div>
                <div className="kontaktformular_2_formulare">
                  <form
                    method="POST"
                    onSubmit={NextStep}
                    id="contact-form"
                    name="contact-form"
                  >
                    <select name="produkt" onChange={inputsHandler} required>
                      <option value="">Produkt auswählen</option>
                      <option value="Termion">Termion</option>
                      <option value="DentalPro">DentalPro</option>
                      <option value="Eos">Eos</option>
                      <option value="Telemedizin">Telemedizin</option>
                      <option value="Befundkarte">Befundkarte</option>
                      <option value="Notfallkarte">Notfallkarte</option>
                      <option value="Bookingdoc">Bookingdoc</option>
                    </select>

                    <p>Wie sollen wir Ihnen weiterhelfen?</p>

                    <select name="aktion" onChange={inputsHandler} required>
                      <option value="">Aktion auswählen</option>
                      <option value="Beratung">
                        Persönliches Beratungsgespräch
                      </option>
                      <option value="Rückruf">Rückruf</option>
                      <option value="Videoberatung">Videoberatung</option>
                      <option value="E-Mail">Informationen per E-Mail</option>
                    </select>

                    <button
                      className={isButtonActive ? '' : 'deactive'}
                      id="kontakt_button"
                      type="button"
                      onClick={NextStep}
                    >
                      Weiter
                    </button>
                  </form>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="kontaktformular_1">
                <div className="kontaktformular_1_schritte">
                  <h4>Schritt 1</h4>
                  <hr />
                  <h4>Schritt 2</h4>
                  <hr />
                  <h4 className="selected">Schritt 3</h4>
                </div>
                <div className="kontaktformular_1_title">
                  <h1>Letzter Schritt</h1>
                  <p>
                    Wollen Sie uns noch zusätzliche Informationen mitteilen?
                  </p>
                </div>
                <div className="kontaktformular_1_formulare">
                  <form
                    method="POST"
                    onSubmit={handleSubmit} 
                    id="contact-form"
                    name="contact-form"
                  >
                    <label>
                      <textarea
                        name="kommentar"
                        onChange={inputsHandler}
                        className="textbox"
                        type="text"
                        placeholder="Geben Sie hier Ihre Wünsche bekannt..."
                      />
                    </label>

                    <button
                      id="kontakt_button"
                      type="submit"
                      >
                      Absenden
                    </button>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default Kontaktformular1;
