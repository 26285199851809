import React from 'react';
import Funktion1 from '../Funktion1';
import "./EosFunktionen.css";

function EosNeurologie() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2>Eos für Neurologen</h2>
            </div>
            <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Neurologie alle Kassen"
            title2=""
            paket="Standardpaket"
            info="Im Setangebot enthalten:
            Stammdatenverwaltung der Patienten, Dienstgeberverzeichnis, Automatische Ortssuche über die PLZ, Handynummer, E-MAIL, SMS-Datenverwaltung, Zuweiserverwaltung
            Formularsystem: sämtliche Kassenformulare sind im Standard enthalten, Private Formulare sind mit Arztpraxis Logo möglich
            Karteisystem: Interaktiver Karteizeile, Individuelles Karteireitersystem, Karteideckel - die wichtigsten Daten sieht man beim Patientenaufruf sofort! Grafiken mit Einzeichnungsmöglichkeiten direkt in der Patientenkartei.Filterfunktionen usw...
            Wartelistenfunktion: Übersichtliche Darstellung mehreren Wartelisten mit vielen Funktionen wie z.B. Akut Warteliste, Laborwarteliste usw.. Umreihungsmöglichkeit, Anzeige der Wartedauer des Patienten
            Medikamentenverschreibung: Kompletter Medikamentenstamm inklusive Spezialitätenverzeichnis,ÖKO Liste, Suchen nach Inhaltsstoffen, Suche nach Symptomen, Magistrale Medikamente
            Labormodul: Erfassung und Verwaltung von Laborwerten, Basisparameter inkl. Referenzbereiche, Laborbefundausdruck,Automatische Berechnungsmöglichkeiten, Leistungszuordnung, Befundzuordnung zum Patienten z.B. Medical Net (Option), Kostenlose Einbindung der privaten Gesundheitsakte Befundkarte mit Direktaufruf aus der Kartei.
            Textverarbeitung/Arztbrief: Integrierte Textbearbeitung mit Übernahme der Daten aus der medizinischen Kartei - keine Doppeleingaben notwendig! Befundversand per Mailbox Medical Net mit automatischer Bereitsstellung in der Befundversandliste (Option).
            Statistik: Tagesprotokolle, Leistungsstatistik, Diagnosen und Patientenstatistik Kassenabrechnung: Abrechnung aller Kassen (Hauptverbandsgeprüft) ELDA Abrechnung, Honorarnotenabrechnung, Zahlscheindruck,Patientenkonto,Mahnsystem und Bankomateinbindung (Option)"
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Neurologie Wahlarzt"
            title2=""
            paket="Standardpaket"
            info="Im Setangebot enthalten:
            Stammdatenverwaltung der Patienten, Dienstgeberverzeichnis, Automatische Ortssuche über die PLZ, Handynummer, E-MAIL, SMS-Datenverwaltung, Zuweiserverwaltung
            Formularsystem: sämtliche Kassenformulare sind im Standard enthalten, Private Formulare sind mit Arztpraxis Logo möglich
            Karteisystem: Interaktiver Karteizeile, Individuelles Karteireitersystem, Karteideckel - die wichtigsten Daten sieht man beim Patientenaufruf sofort! Grafiken mit Einzeichnungsmöglichkeiten direkt in der Patientenkartei.Filterfunktionen usw...
            Wartelistenfunktion: Übersichtliche Darstellung mehreren Wartelisten mit vielen Funktionen wie z.B. Akut Warteliste, Laborwarteliste usw.. Umreihungsmöglichkeit, Anzeige der Wartedauer des Patienten
            Medikamentenverschreibung: Kompletter Medikamentenstamm inklusive Spezialitätenverzeichnis,ÖKO Liste, Suchen nach Inhaltsstoffen, Suche nach Symptomen, Magistrale Medikamente
            Labormodul: Erfassung und Verwaltung von Laborwerten, Basisparameter inkl. Referenzbereiche, Laborbefundausdruck,Automatische Berechnungsmöglichkeiten, Leistungszuordnung, Befundzuordnung zum Patienten z.B. Medical Net (Option), Kostenlose Einbindung der privaten Gesundheitsakte Befundkarte mit Direktaufruf aus der Kartei.
            Textverarbeitung/Arztbrief: Integrierte Textbearbeitung mit Übernahme der Daten aus der medizinischen Kartei - keine Doppeleingaben notwendig! Befundversand per Mailbox Medical Net mit automatischer Bereitsstellung in der Befundversandliste (Option).
            Statistik: Tagesprotokolle, Leistungsstatistik, Diagnosen und Patientenstatistik
            Wahlarztabrechnung: Honorarnoten, Zahlscheindruck,Patientenkonto,Mahnsystem und Bankomateinbindung (Option)"
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="e-card Modul elektronische Krankmeldung"
            title2=""
            paket="Modul"
            info="Der Arzt kann die elektronische Krankmeldung direkt aus dem Ordinationsprogramm EOSWIN in das e-card-Netz versenden. Per Mausklick bekommt der Patient seine Bestätigung ausgedruckt. Sämtliche Daten werden automatisch in der Patientendatei dokumentiert."
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="e-card Softwaremodul VU Versand"
            title2=""
            paket="Modul"
            info="Mit unserem Ordinationsprogramm EOSWIN können Sie die elektronische Vorsorgeuntersuchung direkt in das e-card-Netz versenden. Selbstverständlich werden sämtliche Daten automatisch in der Patientendatei dokumentiert."
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="e-card Zusatzmodul ABS"
            title2=""
            paket="Modul"
            info="Das Arzneimittel-Bewilligungs-Service (ABS) Softwaremodul dient zur Übermittlung von Bewilligungsanfragen über die eCard-Infrastruktur. Die Vorraussetzung für die direkte Anwendung des ABS Systems aus der Ordinationssoftware ist die Vollintegration der e-card (e-card Connectorsoftware)."
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Elektronische Laborüberweisung"
            title2=""
            paket="Modul"
            info="EOSWIN Softwaremodul Elektronische Laborüberweisung"
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Telefoneinbindung"
            title2=""
            paket="Modul"
            info="Ihre Telefonanlage und Ihre Ordinationssoftware ergänzen einander perfekt: Bei Anruf eines Patienten ersparen Sie sich die Eingabe seines Namens. Sie können sofort die Patientenkartei aufrufen und somit Ihren Ordinationsablauf entscheidend beschleunigen. Die technische Voraussetzungen müssen gegeben sein und werden von unseren Techniker vorab kostenlos abgeklärt."
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Modul Recallsystem"
            title2=""
            paket="Modul"
            info="Kontrolltermin können mittels EOSWIN Recallsystem automatisch verwaltet werden.(Serienbrief,Bildschirmlisten,Erinnerung beim Aufruf des Patienten, usw..)"
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Fotodokumentation - Wireless"
            title2=""
            paket="Modul"
            info="Fotodokumentation auf Knopfdruck Übertragen Sie Fotos direkt in Ihre elektronisch geführte Patientenkartei - ohne Stick und ohne Kabel!
            Jeder Arzt weiß, wie praktisch Patientenfotos sind: Damit können alle Behandlungen genauestens dokumentiert werden, wovon nicht nur der Arzt, sondern auch der Patient profitiert.         
            Doch so sinnvoll Fotos in der Praxis auch sind, so mühsam gestaltete sich bisher die Fotodokumentation: Um die Fotos zu kopieren, musste die Kamera zuerst per Kabel oder Stick mit dem Computer verbunden werden. Danach mussten die Fotos in einem gut durchdachten Ablagesystem integriert werden, damit man sie auch später wiederfindet. Es war also notwendig, Ordner zu schaffen und Bilddateien händisch zu übertragen - ein nicht selten mühsamer und zeitaufwändiger Prozess!      
            Doch damit ist es jetzt vorbei: Ein neuer Chip ermöglicht es ab sofort, die Fotos per Knopfdruck direkt in die Patientenkartei zu überspielen - ohne Stick und ohne Kabel! Sobald man den Auslöseknopf der Kamera drückt, sorgt eine spezielle MCW-Software dafür, dass das Bild direkt dort landet, wo es hin gehört. Das mühsame Anschließen an den Computer entfällt ebenso, wie das zeitaufwändige Suchen und Ordnen der Bilddateien. Für den Zahnarzt eine wesentliche Arbeitserleichterung und Zeitersparnis!
            Dabei ist die Anschaffung einer neuen Kamera in der Regel nicht erforderlich. Es ist nur notwendig, dass Ihre Kamera und der neue Chip kompatibel sind.          
            Modul besteht aus: DOKU-Software; SDHC-Chipcarte; Softwareschnittstelle und WLAN Router"
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Labormodul (Eigenlabor)"
            title2=""
            paket="Modul"
            info="Onlineanschlüsse: Das EOS-WIN System kann an Untersuchungsgeräten online angeschlossen werden wie z.B. Laborgeräte oder EKG etc. Werte,Bilder und Diagramme können dabei direkt in einen z.B WINWORD-Befund übernommen und ausgedruckt werden. Das ermöglicht eine rasche und lückenlose Dokumentation und eine übersichtliche und vergleichende Darstellung von Krankeitsbildern."
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Scannersystem (Hard u. Software)"
            title2=""
            paket="Modul"
            info="Unterschiedlichste Befunde können Patientenbezogen direkt in Ihrer medizinischen Kartei gespeichert und exakt zugeordnet werden. Dieses rasche Ablagesystem spart eine raumfüllende Papierablage. EOSWIN Einbindung + Scannersoftware und Canon Hochleistungsscanner DR 2080C"
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Gruppenpraxismodul"
            title2=""
            paket="Modul"
            info="Die Statisik ermittelt die Anzahl der Krankenscheine(Verordnungsschein) von Zuweisungen anderer Internisten zu bestimmten Untersuchungen zB: Duplexsonographie, Echokardiographie, Doppler-Duplexsonographie,...
            Es erfolgt eine Auflistung der entsprechenden Patienten/Krankenscheine und die zugehörigen Leistungen
            - Fehlerhafte Eintragungen zB: Verechnung zusätzlicher Leistung zu solchen Fällen oder Krankenscheine ohne Leistung werden als Fehler gekennzeichnet           
            Zusammenfassend wird auch die Anzahl der Untersuchung/Leistung in den Entsprechenden Fällen ausgegeben"
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Terminmanager (Terminplan,Onlinebucher...)"
            title2=""
            paket="Modul"
            info="EOSWIN Arztpraxis Terminmanager beinhaltet folgende Funktionen:

            1. EOSWIN Arztpraxis Terminbuch:
            
            * Tagesplaner mit Kalender
            * Termindetails bearbeiten
            * Liste aller Termine eines Patienten
            * Terminsuche nach freien Ärzten und Räumen
            * Wochenpläne
            * Urlaubspläne
            * mehrere Wartezimmer
            * Konfiguration durch den Anwender
            * Anpassung an die Ordination
            
            2. ON-LINE Terminbucher
            
            * Ihre Patienten können jederzeit einen Termin buchen
            * Sparen Sie Zeit bei der Terminvergabe
            * Entlasten Sie Ihre Assistentinen bei der Anmeldung
            
            3. MCW Arztpraxis Homepage
            
            Eine attraktive Praxis-Homepage schafft Vorteile für Sie und Ihre Patienten:
            
            Für den Patienten relevante Informationen werden professionell präsentiert:Ordinationszeiten, Leistungen der Ordination, Anfahrtsplan, Räumlichkeiten, Personal, Ausbildung etc."
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Mehrarztstatistik"
            title2=""
            paket="Modul"
            info=""
            />
                       <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Spracheingabe"
            title2=""
            paket="Modul"
            info="Das Softwaremodul Spracheingabe besteht aus:
            * einem Integrationsmodul für die Spracherkennung
            * einem Schnittstellenprogramm zur EOSWIN Software
            * der Diktiersoftware Dragon Naturally Speaking 10
            * einem hochwertigen, geräuschunterdrückenden Headset
            * einer kostenlosen Einschulung in unserem Schulungscenter"
            />

            <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Registrierkassen Software - Basis"
            title2=""
            paket="Modul"
            info="Software für die Registrier und Belegerteilungspflicht ab 01.01.2016. Die erstellten Daten(Rechnungen / Honorarnoten) sind unveränderbar und die Belegausgabe ist mit einer Nummerierung vorgesehen die eine leichte Kontrolle der Vollständigkeit ermöglicht. Eine technische Manipulationssicherung ist vorgesehen. Die Erlöse werden eingebucht, also registriert, diese Eingabe erfolgt chronologisch.
            Die MCW-Registrierkassensoftware ist modulartig aufgebaut und kann mit Quittungsdrucker (Bon Drucker) Etikettendrucker und Barcodescanner kombiniert werden. Die MCW-Registrierkassen Software ist ein Softwareprodukt von MCW und somit 100% kompatibel zu dem bestehenden Ordinationssoftwareprogramm.
            
            Im Basisprogramm enthalten:
            
            - Eigener Belegkreis für registrierpflichtige Barumsätze .
            - Tagesabschluss der erbrachten Leistungen und Artikel
            - Aufschlüsselung nach Zahlungsart und Steuersätzen
            - Monatsabschluss.
            - Export des Protokolls auf einen externen Datenträger.
            - Quittung laut Belegerteilungsverpflichtung"
            />
             <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOS-WIN Leistungsstatistik"
            title2=""
            paket="Modul"
            info="Der Überblick über die Leistungen Ihrer Ordination ist eine wichtige Grundlage für Planung, Organisation und Effizienzsteigerung. Darum hat MCW eine umfangreiches Statistikmodul entwickelt, das Ihnen jederzeit eine detaillierte Übersicht über den Leistungsumfang Ihrer Ordination garantiert. So haben Sie Ihr Unternehmen Ordination jederzeit im Griff!"
            />
             <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN SMS Erinnerungssystem"
            title2=""
            paket="Modul"
            info="Erinnern Sie doch Ihre Patienten an anstehende Termine per SMS! Dabei erfolgen die Terminerinnerungen direkt aus dem EOSWIN-Terminkalender oder über individuell u erstellenden Abfrage-Listen mit Filterkriterien. Selbstverständlich dabei: Eine Kontroll-Liste für gesendete oder nicht gesendet SMS!
            Wichtig: Nicht jeder Handyhersteller entspricht den technischen Anforderungen des SMS-Versandes - Sprechen Sie vor dem Kauf Ihres Handys mit uns, wir überprüfen kostenlos ob Ihr Handy geeignet ist."
            />
             <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Digitale Unterschrift (Signatur)"
            title2=""
            paket="Modul"
            info="Da die Dokumentations- und Aufklärungspflicht des Arztes eine immer wichtigere Rolle spielt, ist MCW auch in diesem Bereich Vorreiter und hat die digitale Signatur für Aufklärungsblätter und andere Formulare verwirklicht. Die unschlagbaren Pluspunkte:

            - Aufnahme der Schreibcharakteristik (Biometrie) der Unterschrift mittels Signatur-Pad
            - Speicherung der Daten in einem PDF/A-Dokument
            - Authentifizierung des Unterzeichners durch die Biometrie
            - Schutz gegen Manipulation durch ein eigenes Zertifikat
            - sehr hoher Beweiswert
            - nutzbar auch für Verträge"
            />
             <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Labor Anforderung (Fremdlabor)"
            title2=""
            paket="Modul"
            info="Mit diesem Softwaremodul kann der Arzt elektronisch Laboranforderungen an sein Labor senden.
            Voraussetzungen: Provider Voraussetzung Medical Net bzw. DAME; HL7 Format; Softwareaktualisierung ab Programmversion EOS 2.0.0.9 möglich."
            />
             <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Erweiterte Kontrolliste der Abrechnung"
            title2=""
            paket="Modul"
            info=""
            />
             <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Benutzer-Statistik"
            title2=""
            paket="Modul"
            info="Kartei-Auswertung nach Benutzer"
            />
             <Funktion1 
            img="./icon/Neurologie_Icon.png"
            title1="EOSWIN Wahlarztabrechnung Online"
            title2=""
            paket="Modul"
            info="Mit diesem Softwaremodul hat der Arzt die Möglichkeit die bezahlten Honorare elektronisch an die Gebietskrankenkasse online zu übermitteln als Service für den Patienten."
            />
           
        </div>
    )
}

export default EosNeurologie;
