// src/components/OfferList.js
import React from 'react';
import Offer from './Offer';
import './OfferList.css';

const offers = [
  {
    title: 'KOSTENLOSER BERATUNGSTERMIN',
    description: 'Buchen Sie noch heute einen kostenlosen Beratungstermin und lassen Sie sich von unseren Experten umfassend beraten. Gemeinsam finden wir die beste Lösung für Ihre Praxis.',
    image: './img/MCW_Grafik.webp',
    worth: 'Schritt 1',
    showWorth: true,
  },
  {
    title: 'WUNSCHKONFIGURATION AUSWÄHLEN',
    description: 'Wählen Sie Ihre individuelle Wunschkonfiguration und passen Sie unsere Software genau an die Bedürfnisse Ihrer Praxis an. Einfach, flexibel und maßgeschneidert.',
    image: './img/MCW_Konfiguration.webp',
    worth: 'Schritt 2',
    showWorth: true,
  },

  {
    title: 'SETUP & INSTALLATION VOR ORT',
    description: 'Unser Team übernimmt das Setup und die Installation vor Ort. So können Sie sicher sein, dass alles reibungslos funktioniert und sofort einsatzbereit ist.',
    image: './img/MCW_Setup.webp',
    worth: 'Schritt 3',
    showWorth: true,
  },
  {
    title: 'PREMIUM KUNDENSUPPORT',
    description: 'Profitieren Sie von unserem Premium Kundensupport. Unsere Experten stehen Ihnen jederzeit zur Verfügung, um Fragen zu beantworten und Probleme schnell zu lösen.',
    image: './img/MCW_Support.webp',
    worth: 'Schritt 4',
    showWorth: true,
  },
];

const OfferList1 = () => {
  return (
    <div className="offer-list">
        <h2>So legen Sie los:</h2>
      {offers.map((offer, index) => (
        <Offer
          key={index}
          title={offer.title}
          description={offer.description}
          image={offer.image}
          worth={offer.worth}
          showWorth={offer.showWorth}
        />
      ))}
    </div>
  );
};

export default OfferList1;
