import React, { useEffect, useRef } from 'react';
import './Offer.css';

const Offer = ({ title, description, image, worth, showWorth }) => {
  const offerRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add('offer-visible');
            observer.unobserve(entry.target);
          }
        });
      },
      {
        threshold: 0.1,
      }
    );

    if (offerRef.current) {
      observer.observe(offerRef.current);
    }

    return () => {
      if (offerRef.current) {
        observer.unobserve(offerRef.current);
      }
    };
  }, []);

  return (
    <div className="offer-wrapper" ref={offerRef}>
      <div className="offer">
        <div className="offer-left">
          {showWorth && <h5>{worth}</h5>}
          <div className="offer-header">
            <h3 className="offer-title">{title}</h3>
            <img src="./img/verified_2.webp" alt="Icon" className="offer-icon" />
          </div>
          <p className="offer-description">{description}</p>
        </div>
        <img src={image} alt={title} className="offer-image" />
      </div>
    </div>
  );
};

export default Offer;
