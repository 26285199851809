import React from 'react';
import Funktion1 from '../Funktion1';
import "./EosFunktionen.css";

function EosGynäkologie() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2>Eos für Gynäkologen</h2>
            </div>
            <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Gynäkologie (alle Kassen)"
            title2=""
            paket="Standardpaket"
            info="Setangebot bestehend aus:
            EOSWIN Gynäkologie ist speziell für dieses Fachgebiet konzipiert programmiert. Viele Ihrer Routinearbeiten können damit wesentlich schneller und genauer dokumentiert werden. Wie z.B. verschiedenste Gyn.-Statusmasken sind vorbereitet. Die Sonographieaufnahmen können direkt dem Patienten zugeordnet und am Gyn-Befund auf Wunsch mitgedruckt werden. Automatische Berechnung von Geburtsterminen. Leichte Erstellung von PAP-Listen und Wiederbestellsystem machen die tägliche Arbeit einfacher und effizient"
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Gynäkologie Wahlarzt"
            title2=""
            paket="Standardpaket"
            info="Das Setangebot beinhaltet:
            Patientenverwaltung; Wartelistensystem, Medizinische Karteiführung mit Karteireitersystem und interaktive Karteizeile; Befundschreibung (automatische GYN-Befunderstellung aus der Patientenkartei möglich!)Befundarchiv; Medikamentverschreibung; Ökonomie-Tool, Labormodul für Eigenlabor,PAP-Recall System, Leistungsabrechnung inkl.Leistungsstatistik, Abrechnung mit allen Gebietskassen Kassen und kleinen Kassen auf Honorarbasis; Honorarnotenverwaltung und Honorarnotenabrechnung."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Ärztezentrum"
            title2=""
            paket="Modul"
            info="Das Standardpaket für den Wahlarzt:

            Patientenadministration: Stammdatenverwaltung inkl. Zusatzversicherung, Formularprogramm für Kostenrückerstattung, Kostenlose Einbindung Private Gesundheitsakte Befundkarte,
            Diagnoseerfassung: Individuelles Kürzelsystem
            Leistungserfassung: Kassentarife und individuelle Leistungserfassung, Patienten können mehreren Kassen zugeordnet werden z.B. Privat Leistung auf Basis einer kleinen Kasse bzw. eine reine Private Leistung, Statistik, Honorarnotenverwaltung: Individuelle Honorargestaltung mit Logoeinbindung Tagesjournal, offene Postenliste,Mahnung usw...
            Formularverwaltung und Rezeptieren:
            Medikamentendatenbank, Magistralterverwaltung, Grüne,Gelbe,Rote Box Medikamente, Suchtgiftrezepte, Medikamentenplan für Patienten
            Textverarbeitung/ Befundschreibung: Aus der Patientenkartei können Daten direkt in die Befundschreibung übernommen werden, Layoutgestaltung mit Einbindung des Ordinations-Logos usw..
            Umfangreiche Karteiführung: Interaktive Karteizeile!, Filterfunktion, Karteideckel mit den wichtigsten Hinweisen, Impfverwaltung, Datenfilter, Verwalten von Befunden, Wartelistensystem
            Zentrales Anmeldungssystem:
            EOSWIN Modul Ärztezentrum"
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Einbindung Sonografie"
            title2=""
            paket="Modul"
            info=""
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="e-card Modul elektronische Krankmeldung"
            title2=""
            paket="Modul"
            info="Der Arzt kann die elektronische Krankmeldung direkt aus dem Ordinationsprogramm EOSWIN in das e-card-Netz versenden. Per Mausklick bekommt der Patient seine Bestätigung ausgedruckt. Sämtliche Daten werden automatisch in der Patientendatei dokumentiert."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="e-card Softwaremodul ABS"
            title2=""
            paket="Modul"
            info="Das Arzneimittel-Bewilligungs-Service (ABS) Softwaremodul dient zur Übermittlung von Bewilligungsanfragen über die eCard-Infrastruktur. Die Vorraussetzung für die direkte Anwendung des ABS Systems aus der Ordinationssoftware ist die Vollintegration der e-card (e-card Connectorsoftware)."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="e-card Softwaremodul VU Versand"
            title2=""
            paket="Modul"
            info="Mit unserem Ordinationsprogramm EOSWIN können Sie die elektronische Vorsorgeuntersuchung direkt in das e-card-Netz versenden. Selbstverständlich werden sämtliche Daten automatisch in der Patientendatei dokumentiert."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Elektronische Laborüberweisung"
            title2=""
            paket="Modul"
            info="EOSWIN Softwaremodul Elektronische Laborüberweisung"
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Modul für Hausbesuche (Pocket PC-Lösung)"
            title2=""
            paket="Modul"
            info="Wenn Sie auf Hausbesuch sind, müssen Sie bei MCW auf nichts verzichten: Die Hausbesuchsliste wird bereits in der Ordination auf Ihren Pocket-PC übertragen, sämtliche Informationen (Diagnosen, Medikamente, Laborbefunde...) sind problemlos abrufbar. Nach den Hausbesuchen überträgt das Programm alle vor Ort eingegebenen Daten in Ihr Ordinationssystem. Eine ideale Lösung auch für den Besuch von Alten- und Pflegeheimen."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Telefoneinbindung"
            title2=""
            paket="Modul"
            info="Ihre Telefonanlage und Ihre Ordinationssoftware ergänzen einander perfekt: Bei Anruf eines Patienten ersparen Sie sich die Eingabe seines Namens. Sie können sofort die Patientenkartei aufrufen und somit Ihren Ordinationsablauf entscheidend beschleunigen. Die technische Voraussetzungen müssen gegeben sein und werden von unseren Techniker vorab kostenlos abgeklärt."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Fotodokumentation - Wireless"
            title2=""
            paket="Modul"
            info="Fotodokumentation auf Knopfdruck Übertragen Sie Fotos direkt in Ihre elektronisch geführte Patientenkartei - ohne Stick und ohne Kabel!
            Jeder Arzt weiß, wie praktisch Patientenfotos sind: Damit können alle Behandlungen genauestens dokumentiert werden, wovon nicht nur der Arzt, sondern auch der Patient profitiert.            
            Doch so sinnvoll Fotos in der Praxis auch sind, so mühsam gestaltete sich bisher die Fotodokumentation: Um die Fotos zu kopieren, musste die Kamera zuerst per Kabel oder Stick mit dem Computer verbunden werden. Danach mussten die Fotos in einem gut durchdachten Ablagesystem integriert werden, damit man sie auch später wiederfindet. Es war also notwendig, Ordner zu schaffen und Bilddateien händisch zu übertragen - ein nicht selten mühsamer und zeitaufwändiger Prozess!            
            Doch damit ist es jetzt vorbei: Ein neuer Chip ermöglicht es ab sofort, die Fotos per Knopfdruck direkt in die Patientenkartei zu überspielen - ohne Stick und ohne Kabel! Sobald man den Auslöseknopf der Kamera drückt, sorgt eine spezielle MCW-Software dafür, dass das Bild direkt dort landet, wo es hin gehört. Das mühsame Anschließen an den Computer entfällt ebenso, wie das zeitaufwändige Suchen und Ordnen der Bilddateien. Für den Zahnarzt eine wesentliche Arbeitserleichterung und Zeitersparnis!           
            Dabei ist die Anschaffung einer neuen Kamera in der Regel nicht erforderlich. Es ist nur notwendig, dass Ihre Kamera und der neue Chip kompatibel sind.            
            Modul besteht aus: DOKU-Software; SDHC-Chipcarte; Softwareschnittstelle und WLAN Router"
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Zentrale Anmeldung (Ärztezentrum)"
            title2=""
            paket="Modul"
            info="Dieses Softwaremodul wurde speziell für Ärztezentren entwickelt, mit zentraler Anmeldung. Mit diesem Programm kann eine Assistentin einfach und schnell in mehreren Ordinationsprogrammen gleichzeitig arbeiten."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Onlineschnittstelle"
            title2=""
            paket="Modul"
            info="Einige Beispiele für bereits von MCW eingebundene Analyser: Elisa 300/400; Auto Counter;Hellige Blutanalyser; Hitachi 902,911,Sysmex K100;Clinitek;Autolab;A.Menarini Micro Aution;Spotchem;ABX Micros;ABX Mira;ABX Ready;Autohumananalyzer 850; ;Micros; AC920;Cobas Micro;Lab Technologies; Medonic;Vision Abbot; Swelab; SABA;Blutgaanalyzer;Instr.Laboratories;Drott;AVL .... usw..."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Labormodul (Eigenlabor)"
            title2=""
            paket="Modul"
            info="Onlineanschlüsse: Das EOS-WIN System kann an Untersuchungsgeräten online angeschlossen werden wie z.B. Laborgeräte oder EKG etc. Werte,Bilder und Diagramme können dabei direkt in einen z.B WINWORD-Befund übernommen und ausgedruckt werden. Das ermöglicht eine rasche und lückenlose Dokumentation und eine übersichtliche und vergleichende Darstellung von Krankeitsbildern."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Scannersystem (Hard u. Software)"
            title2=""
            paket="Modul"
            info="Unterschiedlichste Befunde können Patientenbezogen direkt in Ihrer medizinischen Kartei gespeichert und exakt zugeordnet werden. Dieses rasche Ablagesystem spart eine raumfüllende Papierablage. EOSWIN Einbindung + Scannersoftware und Canon Hochleistungsscanner DR 2080C"
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Modul Recallsystem"
            title2=""
            paket="Modul"
            info="Kontrolltermin können mittels EOSWIN Recallsystem automatisch verwaltet werden.(Serienbrief,Bildschirmlisten,Erinnerung beim Aufruf des Patienten, usw..)"
            />

             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Terminmanager (Terminplan,Onlinebucher...)"
            title2=""
            paket="Modul"
            info="EOSWIN Arztpraxis Terminmanager beinhaltet folgende Funktionen:

            1. EOSWIN Arztpraxis Terminbuch:
            
            * Tagesplaner mit Kalender
            * Termindetails bearbeiten
            * Liste aller Termine eines Patienten
            * Terminsuche nach freien Ärzten und Räumen
            * Wochenpläne
            * Urlaubspläne
            * mehrere Wartezimmer
            * Konfiguration durch den Anwender
            * Anpassung an die Ordination
            
            2. ON-LINE Terminbucher
            
            * Ihre Patienten können jederzeit einen Termin buchen
            * Sparen Sie Zeit bei der Terminvergabe
            * Entlasten Sie Ihre Assistentinen bei der Anmeldung
            
            3. MCW Arztpraxis Homepage
            
            Eine attraktive Praxis-Homepage schafft Vorteile für Sie und Ihre Patienten:
            
            Für den Patienten relevante Informationen werden professionell präsentiert:Ordinationszeiten, Leistungen der Ordination, Anfahrtsplan, Räumlichkeiten, Personal, Ausbildung etc."
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Gruppenpraxismodul"
            title2=""
            paket="Modul"
            info="
            Die Statisik ermittelt die Anzahl der Krankenscheine(Verordnungsschein) von Zuweisungen anderer Internisten zu bestimmten Untersuchungen zB: Duplexsonographie, Echokardiographie, Doppler-Duplexsonographie,...
            
            Es erfolgt eine Auflistung der entsprechenden Patienten/Krankenscheine und die zugehörigen Leistungen
            - Fehlerhafte Eintragungen zB: Verechnung zusätzlicher Leistung zu solchen Fällen oder Krankenscheine ohne Leistung werden als Fehler gekennzeichnet
            
            Zusammenfassend wird auch die Anzahl der Untersuchung/Leistung in den Entsprechenden Fällen ausgegeben"
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Registrierkassen Software - Basis"
            title2=""
            paket="Modul"
            info="Software für die Registrier und Belegerteilungspflicht ab 01.01.2016. Die erstellten Daten(Rechnungen / Honorarnoten) sind unveränderbar und die Belegausgabe ist mit einer Nummerierung vorgesehen die eine leichte Kontrolle der Vollständigkeit ermöglicht. Eine technische Manipulationssicherung ist vorgesehen. Die Erlöse werden eingebucht, also registriert, diese Eingabe erfolgt chronologisch.
            Die MCW-Registrierkassensoftware ist modulartig aufgebaut und kann mit Quittungsdrucker (Bon Drucker) Etikettendrucker und Barcodescanner kombiniert werden. Die MCW-Registrierkassen Software ist ein Softwareprodukt von MCW und somit 100% kompatibel zu dem bestehenden Ordinationssoftwareprogramm.
            
            Im Basisprogramm enthalten:
            
            - Eigener Belegkreis für registrierpflichtige Barumsätze .
            - Tagesabschluss der erbrachten Leistungen und Artikel
            - Aufschlüsselung nach Zahlungsart und Steuersätzen
            - Monatsabschluss.
            - Export des Protokolls auf einen externen Datenträger.
            - Quittung laut Belegerteilungsverpflichtung"
            />
             <Funktion1 
            img="./icon/Gynäkologie_Icon.png"
            title1="EOSWIN Wahlarztabrechnung Online"
            title2=""
            paket="Modul"
            info="Mit diesem Softwaremodul hat der Arzt die Möglichkeit die bezahlten Honorare elektronisch an die Gebietskrankenkasse online zu übermitteln als Service für den Patienten."
            />

        </div>
    )
}

export default EosGynäkologie;
