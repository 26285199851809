import React, { useState, useEffect } from 'react';
import './HomeSlogan.css';

const HomeSlogan = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [isTitleVisible, setIsTitleVisible] = useState(false);

  useEffect(() => {
    const updateScrollProgress = () => {
      // Unterscheiden Sie die Startpunkte basierend auf der Fensterbreite
      const mobileOffset = 400; // Für Mobilgeräte
      const desktopOffset = 600; // Für Desktop
  
      // Unterscheiden Sie die maxScrollPercentages basierend auf der Fensterbreite
      const mobileMaxScrollPercentage = 20; // Beispielwert für Mobilgeräte
      const desktopMaxScrollPercentage = 30.5; // Beispielwert für Desktop
  
      // Ermitteln Sie, ob es sich um ein mobiles Gerät oder einen Desktop handelt
      const isMobile = window.innerWidth < 1024;
  
      // Wählen Sie den entsprechenden Startpunkt und maxScrollPercentage basierend auf dem Gerät
      const offset = isMobile ? mobileOffset : desktopOffset;
      const maxScrollPercentage = isMobile ? mobileMaxScrollPercentage : desktopMaxScrollPercentage;
  
      // Unterscheiden Sie die titleVisibilityThresholds basierend auf der Fensterbreite
      const mobileTitleVisibilityThreshold = 500; // Angepasster Wert für Mobilgeräte
      const desktopTitleVisibilityThreshold = 100 + 200; // Standardwert für Desktop
  
      // Wählen Sie den entsprechenden titleVisibilityThreshold basierend auf dem Gerät
      const titleVisibilityThreshold = isMobile ? mobileTitleVisibilityThreshold : desktopTitleVisibilityThreshold;
  
      const scrollTop = window.pageYOffset - offset || document.documentElement.scrollTop - offset;
      const scrollHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
      const scrollPercentage = (scrollTop / scrollHeight) * 400;
  
      // Verwenden Sie den entsprechenden maxScrollPercentage basierend auf dem Gerät
      const limitedScrollPercentage = Math.min(scrollPercentage, maxScrollPercentage);
  
      setScrollProgress(limitedScrollPercentage);
  
      // Anpassen der Bedingung für die Sichtbarkeit des Titels basierend auf dem Gerät
      if (scrollTop > titleVisibilityThreshold) {
        setIsTitleVisible(true);
      } else {
        setIsTitleVisible(false);
      }
    };
  
    window.addEventListener('scroll', updateScrollProgress);
  
    return () => window.removeEventListener('scroll', updateScrollProgress);
  }, []);
  

  
  return (
    <div className="home-slogan">
      <div className="progress-bar-background"></div>
      <div className="progress-bar" style={{height: `${scrollProgress}%`}}>
      </div>

      <img src="./img/mcw_round_icon.svg" className="scroll-icon" style={{top: `${scrollProgress}%`}} alt="Scroll Icon" />
      <h2 className={isTitleVisible ? 'title-visible' : ''} id="desktop-quote">Über <span className="supercharged-2">1000 ÄrztInnen in Österreich</span> setzen mit MCW auf Effizienz und erstklassige Patientenbetreuung.</h2>
      <h2 className={isTitleVisible ? 'title-visible' : ''} id="mobile-quote">Über 1000 Ordinationen in Österreich setzen mit MCW auf Effizienz und erstklassige Patientenbetreuung.</h2>
      <p>Viele Ärztinnen und Ärzte stehen vor der Herausforderung, ihren Arbeitsalltag effizient zu gestalten und sowohl die Patientenversorgung zu optimieren als auch das Arbeitsvolumen zu reduzieren. Mit MCW entscheiden Sie sich für eine zukunftsorientierte Praxisführung, anstatt sich mit veralteten Methoden zufriedenzugeben, die Ihren Fortschritt bremsen.</p>
    </div>
  );
};

export default HomeSlogan;
