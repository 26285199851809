import React from 'react'
import {Helmet} from "react-helmet";
import TeamMitglied from './TeamMitglied';
import './Team.css'
import Kontakt from '../Kontakt';
import Standorte from '../Standorte';
import HomeInfo1 from '../HomeInfo1';
import VideoBanner2 from '../VideoBanner2';

function Team() {
  return (
    <div>

<Helmet>
            <title>Unser Team</title>
            <meta
      name="description"
      content="Software für Ärzte aus Österreich seit über 30 Jahren. Wir bieten Ihnen moderne Ordinationslösungen inklusive erstklassigem Kundenservice. Mit MCW bringen Sie Ihre Ordination auf das nächste Level. "
    />
        </Helmet>
        <div>
        <HomeInfo1 
            title="Seit über 35 Jahren an Ihrer Seite."
            textbig="Wir sagen Danke!"
            text="Seit 1988 zählt MCW zu den Marktführern in der medizinischen Softwareentwicklung - ein Gütezeichen, das nicht jeder österreichischen Firma vergönnt ist. Wir freuen uns darauf, viele weitere Jahre an Ihrer Seite zu wachsen!"
            button="Termin vereinbaren"
            img="./img/eos_retro.jpg"
            url="/anfrage"

            />
            <VideoBanner2 />

        <div className="team">
        <div className="team_text">
            <hr></hr>
        <h2>Unser Team</h2>
        <hr></hr>
        </div>
        <div className="team_mitglieds_wrapper">
       
        <TeamMitglied 
        img="./img/team/Klaus.png"
        name="Ing. Klaus Propst"
        position="Gründer"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
       
        <TeamMitglied 
        img="./img/team/Johann.png"
        name="Johann Propst"
        position="Geschäftsführer"
        email="hpropst@mcw.at"
        telefon="+43-(0)1-6675 665-0"
        farbe="rgb(210, 233, 255)"
        />
                <TeamMitglied 
        img="./img/team/Edwin.png"
        name="Edwin Skorepa"
        position="Geschäftsführer"
        email="eskorepa@mcw.at"
        telefon="+43-(0)1-6675 665-0"
        farbe="rgb(210, 233, 255)"
        />
                     <TeamMitglied 
        img="./img/team/Julian.png"
        name="Julian Propst"
        position="Produktentwicklung"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
             <TeamMitglied 
        img="./img/team/Sabine.png"
        name="Mag. Sabine Propst-Völz"
        position="Presse- & Öffentlichkeitsarbeit"
        email="svoelz@mcw.at"
        telefon="+43-(0)664-212 2087"
        farbe="rgb(210, 233, 255)"
        />
            <TeamMitglied 
        img="./img/team/Wagner.png"
        name="Jürgen Wagner"
        position="Verkauf & Schulungen"
        email="jwagner@mcw.at"
        telefon="+43-(0)1-585 1100"
        farbe="rgb(210, 233, 255)"
        />
                 <TeamMitglied 
        img="./img/team/Nikola.png"
        name="Nikola Petkovic"
        position="Techn. Verkauf, Technik & Lieferdisposition"
        email="npetkovic@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
             <TeamMitglied 
        img="./img/team/Kathrin.png"
        name="Kathrin Zillner"
        position="Assistentin der Geschäftsführung"
        email="buchhaltung@mcw.at"
        telefon="+43-(0)1-6675 665-0"
        farbe="rgb(210, 233, 255)"
        />
                      <TeamMitglied 
        img="./img/team/Silvia.png"
        name="Silvia Buchsbaum"
        position="Hotline & Verkauf"
        email="sbuchsbaum@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
                         <TeamMitglied 
        img="./img/team/Erich.png"
        name="Ing. Erich Leuthner"
        position="Hotline & Außendienst"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
    
                 <TeamMitglied 
        img="./img/team/Birgit.png"
        name="Birgit Gaal"
        position="Buchhaltung"
        email="buchhaltung@mcw.at"
        telefon="+43-(0)1-6675 665-15"
        farbe="rgb(210, 233, 255)"
        />
                            <TeamMitglied 
        img="./img/team/Fritz.png"
        name="Friedrich Schubbauer"
        position="Hotline & Lieferdisposition"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
                 <TeamMitglied 
        img="./img/team/Oliver.png"
        name="Oliver Stampf"
        position="Hotline"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
         <TeamMitglied 
        img="./img/team/Edin.png"
        name="Edin Avdagic"
        position="Hotline & Außendienst"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
                 <TeamMitglied 
        img="./img/team/Razvan.png"
        name="Razvan Calin"
        position="Hotline & Außendienst"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
                                   <TeamMitglied 
        img="./img/team/Merisa.png"
        name="Merisa Jadidi Bsc."
        position="Softwareleitung: TermiOn"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
                    <TeamMitglied 
        img="./img/team/Stefan.png"
        name="Ing. Stefan Heindl"
        position="Softwareentwicklung"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
             <TeamMitglied 
        img="./img/team/Peter.png"
        name="Peter Bogner"
        position="Softwareentwicklung"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
      
          <TeamMitglied 
        img="./img/team/Harald.png"
        name="Harald Zoufal"
        position="Softwareentwicklung"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
                          <TeamMitglied 
        img="./img/team/Omid.png"
        name="Omid Jadidi Bsc."
        position="Softwareentwicklung"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
        <TeamMitglied 
        img="./img/team/Nico.png"
        name="Nicolas Zillner"
        position="Softwareentwicklung"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
            <TeamMitglied 
        img="./img/team/Olesea.png"
        name="Olesea Vasilache, BA"
        position="Hotline"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
                      <TeamMitglied 
        img="./img/team/Elke.png"
        name="Elke Keller"
        position="Hotline & Verkauf"
        email="ekeller@mcw.at"
        telefon="+43-(0)676-8466-3922"
        farbe="rgb(210, 233, 255)"
        />
                <TeamMitglied 
        img="./img/team/Enesa.png"
        name="Enesa Spahic"
        position="Office"
        email="service@mcw.at"
        telefon="+43-(0)1-667 5665-0"
        farbe="rgb(210, 233, 255)"
        />
        </div>
        </div>
        <Standorte />
        <Kontakt />
        </div>

    </div>
  )
}

export default Team