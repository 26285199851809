import React from 'react';
import Funktion1 from '../Funktion1';
import "./EosFunktionen.css";

function EosOrthopädie() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2>Eos für Orthopäden</h2>
            </div>
            <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Orthopädie alle Kassen"
            title2=""
            paket="Standardpaket"
            info="Im Setangebot enthalten:
            Stammdatenverwaltung der Patienten, Dienstgeberverzeichnis, Automatische Ortssuche über die PLZ, Handynummer, E-MAIL, SMS-Datenverwaltung, Zuweiserverwaltung
            Formularsystem: sämtliche Kassenformulare sind im Standard enthalten, Private Formulare sind mit Arztpraxis Logo möglich
            Karteisystem: Interaktiver Karteizeile, Individuelles Karteireitersystem, Karteideckel - die wichtigsten Daten sieht man beim Patientenaufruf sofort! Grafiken mit Einzeichnungsmöglichkeiten direkt in der Patientenkartei.Filterfunktionen usw...
            Wartelistenfunktion: Übersichtliche Darstellung mehreren Wartelisten mit vielen Funktionen wie z.B. Akut Warteliste,usw.. Umreihungsmöglichkeit, Anzeige der Wartedauer des Patienten
            Medikamentenverschreibung: Kompletter Medikamentenstamm inklusive Spezialitätenverzeichnis,ÖKO Liste, Allergiefunktion, Suchen nach Inhaltsstoffen, Suche nach Symptomen, Magistrale Medikamente, Kostenlose Einbindung der privaten Gesundheitsakte Befundkarte mit Direktaufruf aus der Kartei.
            Textverarbeitung: Integrierte Textbearbeitung mit Übernahme der Daten aus der medizinischen Kartei - keine Doppeleingaben notwendig! Befundversand per Mailbox Medical Net mit automatischer Bereitsstellung in der Befundversandliste (Option).
            Statistik: Tagesprotokolle, Leistungsstatistik, Diagnosen und Patientenstatistik
            Kassenabrechnung: Abrechnung aller Kassen (Hauptverbandsgeprüft) ELDA Abrechnung, Wahlarztabrechnung, Zahlscheindruck,Patientenkonto,Mahnsystem und Bankomateinbindung (Option).
            Bild von Operationen: Bilder von Operationen können im Computer gespeichert und den Patienten gezeigt werden. So wird der Verlauf von Heilungsprozessen genau dokumentiert. Die Patienten sind bestens informiert, und ihr Vertrauen in den Arzt wird erhöht. Komplette Sammlung von fertigen Drucksorten für Verordnungen wie Schuheinlagen, Stützstrümpfen, Überweisungen, Rezepte, Bestätigungen, Honorarnoten etc. Individuell gestaltbares Wartelistensystem. Befundschreibung direkt aus der Kartei (WinWord®)! Abrechnung mit der Gebietskrankenkasse auf Diskette. Kleine Kassenabrechnung für BVA, KFA, VA, SVA. Leistungsstatistiken sind ganz nach Ihren Wünschen zu erstellen, pro Patient, nach Behandlungsarten, pro Quartal etc."
            />
              <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Wahlarzt Orthopädie"
            title2=""
            paket="Standardpaket"
            info="Im Setangebot enthalten:
            Stammdatenverwaltung der Patienten, Dienstgeberverzeichnis, Automatische Ortssuche über die PLZ, Handynummer, E-MAIL, SMS-Datenverwaltung, Zuweiserverwaltung
            Formularsystem: sämtliche Formulare für den Wahlarzt sind im Standard enthalten, Private Formulare sind mit Arztpraxis Logo möglich
            Karteisystem: Interaktiver Karteizeile, Individuelles Karteireitersystem, Karteideckel - die wichtigsten Daten sieht man beim Patientenaufruf sofort! Grafiken mit Einzeichnungsmöglichkeiten direkt in der Patientenkartei.Filterfunktionen usw...
            Wartelistenfunktion: Übersichtliche Darstellung mehreren Wartelisten mit vielen Funktionen wie z.B. Akut Warteliste,usw.. Umreihungsmöglichkeit, Anzeige der Wartedauer des Patienten
            Medikamentenverschreibung: Kompletter Medikamentenstamm inklusive Spezialitätenverzeichnis,ÖKO Liste, Allergiefunktion, Suchen nach Inhaltsstoffen, Suche nach Symptomen, Magistrale Medikamente, Kostenlose Einbindung der privaten Gesundheitsakte Befundkarte mit Direktaufruf aus der Kartei.
            Textverarbeitung: Integrierte Textbearbeitung mit Übernahme der Daten aus der medizinischen Kartei - keine Doppeleingaben notwendig! Befundversand per Mailbox Medical Net mit automatischer Bereitsstellung in der Befundversandliste (Option).
            Statistik: Tagesprotokolle, Leistungsstatistik, Diagnosen und Patientenstatistik
            Privatabrechnung: Wahlarztabrechnung Zahlscheindruck,Patientenkonto,Mahnsystem und Bankomateinbindung (Option).
            Bilder von Operationen: Bilder von Operationen können im Computer gespeichert und den Patienten gezeigt werden. So wird der Verlauf von Heilungsprozessen genau dokumentiert. Die Patienten sind bestens informiert, und ihr Vertrauen in den Arzt wird erhöht. Komplette Sammlung von fertigen Drucksorten für Verordnungen wie Schuheinlagen, Stützstrümpfen, Überweisungen, Rezepte, Bestätigungen, Honorarnoten etc. Individuell gestaltbares Wartelistensystem. Befundschreibung direkt aus der Kartei (WinWord®)!"
            />
              <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="e-card Modul elektronische Krankmeldung"
            title2=""
            paket="Modul"
            info="Der Arzt kann die elektronische Krankmeldung direkt aus dem Ordinationsprogramm EOSWIN in das e-card-Netz versenden. Per Mausklick bekommt der Patient seine Bestätigung ausgedruckt. Sämtliche Daten werden automatisch in der Patientendatei dokumentiert."
            />
                          <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="e-card Softwaremodul VU Versand"
            title2=""
            paket="Modul"
            info="Mit unserem Ordinationsprogramm EOSWIN können Sie die elektronische Vorsorgeuntersuchung direkt in das e-card-Netz versenden. Selbstverständlich werden sämtliche Daten automatisch in der Patientendatei dokumentiert."
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="e-card Softwaremodul ABS"
            title2=""
            paket="Modul"
            info="Das Arzneimittel-Bewilligungs-Service (ABS) Softwaremodul dient zur Übermittlung von Bewilligungsanfragen über die eCard-Infrastruktur. Die Vorraussetzung für die direkte Anwendung des ABS Systems aus der Ordinationssoftware ist die Vollintegration der e-card (e-card Connectorsoftware)."
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Telefoneinbindung"
            title2=""
            paket="Modul"
            info="Ihre Telefonanlage und Ihre Ordinationssoftware ergänzen einander perfekt: Bei Anruf eines Patienten ersparen Sie sich die Eingabe seines Namens. Sie können sofort die Patientenkartei aufrufen und somit Ihren Ordinationsablauf entscheidend beschleunigen. Die technische Voraussetzungen müssen gegeben sein und werden von unseren Techniker vorab kostenlos abgeklärt."
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Fotodokumentation - Wireless"
            title2=""
            paket="Modul"
            info="Fotodokumentation auf Knopfdruck Übertragen Sie Fotos direkt in Ihre elektronisch geführte Patientenkartei - ohne Stick und ohne Kabel!

            Jeder Arzt weiß, wie praktisch Patientenfotos sind: Damit können alle Behandlungen genauestens dokumentiert werden, wovon nicht nur der Arzt, sondern auch der Patient profitiert.
            
            Doch so sinnvoll Fotos in der Praxis auch sind, so mühsam gestaltete sich bisher die Fotodokumentation: Um die Fotos zu kopieren, musste die Kamera zuerst per Kabel oder Stick mit dem Computer verbunden werden. Danach mussten die Fotos in einem gut durchdachten Ablagesystem integriert werden, damit man sie auch später wiederfindet. Es war also notwendig, Ordner zu schaffen und Bilddateien händisch zu übertragen - ein nicht selten mühsamer und zeitaufwändiger Prozess!
            
            Doch damit ist es jetzt vorbei: Ein neuer Chip ermöglicht es ab sofort, die Fotos per Knopfdruck direkt in die Patientenkartei zu überspielen - ohne Stick und ohne Kabel! Sobald man den Auslöseknopf der Kamera drückt, sorgt eine spezielle MCW-Software dafür, dass das Bild direkt dort landet, wo es hin gehört. Das mühsame Anschließen an den Computer entfällt ebenso, wie das zeitaufwändige Suchen und Ordnen der Bilddateien. Für den Zahnarzt eine wesentliche Arbeitserleichterung und Zeitersparnis!
            
            Dabei ist die Anschaffung einer neuen Kamera in der Regel nicht erforderlich. Es ist nur notwendig, dass Ihre Kamera und der neue Chip kompatibel sind.
            
            Modul besteht aus: DOKU-Software; SDHC-Chipcarte; Softwareschnittstelle und WLAN Router"
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Zentrale Anmeldung (Ärztezentrum)"
            title2=""
            paket="Modul"
            info="Dieses Softwaremodul wurde speziell für Ärztezentren entwickelt, mit zentraler Anmeldung. Mit diesem Programm kann eine Assistentin einfach und schnell in mehreren Ordinationsprogrammen gleichzeitig arbeiten."
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Scannersystem (Hard u. Software)"
            title2=""
            paket="Modul"
            info="Unterschiedlichste Befunde können Patientenbezogen direkt in Ihrer medizinischen Kartei gespeichert und exakt zugeordnet werden. Dieses rasche Ablagesystem spart eine raumfüllende Papierablage. EOSWIN Einbindung + Scannersoftware und Canon Hochleistungsscanner DR 2080C"
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Modul Recallsystem"
            title2=""
            paket="Modul"
            info="Kontrolltermin können mittels EOSWIN Recallsystem automatisch verwaltet werden.(Serienbrief,Bildschirmlisten,Erinnerung beim Aufruf des Patienten, usw..)"
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Gruppenpraxismodul"
            title2=""
            paket="Modul"
            info="Die Statisik ermittelt die Anzahl der Krankenscheine(Verordnungsschein) von Zuweisungen anderer Internisten zu bestimmten Untersuchungen zB: Duplexsonographie, Echokardiographie, Doppler-Duplexsonographie,...

            Es erfolgt eine Auflistung der entsprechenden Patienten/Krankenscheine und die zugehörigen Leistungen
            - Fehlerhafte Eintragungen zB: Verechnung zusätzlicher Leistung zu solchen Fällen oder Krankenscheine ohne Leistung werden als Fehler gekennzeichnet
            
            Zusammenfassend wird auch die Anzahl der Untersuchung/Leistung in den Entsprechenden Fällen ausgegeben"
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Arztpraxis-Terminbuch"
            title2=""
            paket="Modul"
            info="Das Arztpraxis-Terminbuch beinhaltet folgende Funktionen:

            * Tagesplaner mit Kalender
            * Termindetails bearbeiten
            * Liste aller Termine eines Patienten
            * Terminsuche nach freien Ärzten und Räumen
            * Wochenpläne
            * Urlaubspläne
            * mehrere Wartezimmer
            * Konfiguration durch den Anwender
            * Anpassung an die Ordination"
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Mehrarztstatistik"
            title2=""
            paket="Modul"
            info=""
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Registrierkassen Software - Basis"
            title2=""
            paket="Modul"
            info="Software für die Registrier und Belegerteilungspflicht ab 01.01.2016. Die erstellten Daten(Rechnungen / Honorarnoten) sind unveränderbar und die Belegausgabe ist mit einer Nummerierung vorgesehen die eine leichte Kontrolle der Vollständigkeit ermöglicht. Eine technische Manipulationssicherung ist vorgesehen. Die Erlöse werden eingebucht, also registriert, diese Eingabe erfolgt chronologisch.
            Die MCW-Registrierkassensoftware ist modulartig aufgebaut und kann mit Quittungsdrucker (Bon Drucker) Etikettendrucker und Barcodescanner kombiniert werden. Die MCW-Registrierkassen Software ist ein Softwareprodukt von MCW und somit 100% kompatibel zu dem bestehenden Ordinationssoftwareprogramm.
            
            Im Basisprogramm enthalten:
            
            - Eigener Belegkreis für registrierpflichtige Barumsätze .
            - Tagesabschluss der erbrachten Leistungen und Artikel
            - Aufschlüsselung nach Zahlungsart und Steuersätzen
            - Monatsabschluss.
            - Export des Protokolls auf einen externen Datenträger.
            - Quittung laut Belegerteilungsverpflichtung"
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOS-WIN Leistungsstatistik"
            title2=""
            paket="Modul"
            info="Der Überblick über die Leistungen Ihrer Ordination ist eine wichtige Grundlage für Planung, Organisation und Effizienzsteigerung. Darum hat MCW eine umfangreiches Statistikmodul entwickelt, das Ihnen jederzeit eine detaillierte Übersicht über den Leistungsumfang Ihrer Ordination garantiert. So haben Sie Ihr Unternehmen Ordination jederzeit im Griff!"
            />
                 <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN SMS Erinnerungssystem"
            title2=""
            paket="Modul"
            info="Erinnern Sie doch Ihre Patienten an anstehende Termine per SMS! Dabei erfolgen die Terminerinnerungen direkt aus dem EOSWIN-Terminkalender oder über individuell u erstellenden Abfrage-Listen mit Filterkriterien. Selbstverständlich dabei: Eine Kontroll-Liste für gesendete oder nicht gesendet SMS!
            Wichtig: Nicht jeder Handyhersteller entspricht den technischen Anforderungen des SMS-Versandes - Sprechen Sie vor dem Kauf Ihres Handys mit uns, wir überprüfen kostenlos ob Ihr Handy geeignet ist."
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Erweiterte Kontrolliste der Abrechnung"
            title2=""
            paket="Modul"
            info=""
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Benutzer-Statistik"
            title2=""
            paket="Modul"
            info="Kartei-Auswertung nach Benutzer"
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Mailversand"
            title2=""
            paket="Modul"
            info="Das Modul ermöglicht es dem Arzt, direkt aus dem EOSWIN E-Mails zu versenden."
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Wahlarztabrechnung Online"
            title2=""
            paket="Modul"
            info="Mit diesem Softwaremodul hat der Arzt die Möglichkeit die bezahlten Honorare elektronisch an die Gebietskrankenkasse online zu übermitteln als Service für den Patienten."
            />
        
        <Funktion1 
            img="./icon/Orthopädie_Icon.png"
            title1="EOSWIN Video und Bildbearbeitungmodul"
            title2=""
            paket="Modul"
            info="Bilder von Videokameras können im Computer problemlos gespeichert und jederzeit abgerufen werden. Für Arzt und Patient ergibt sich daraus eine exzellente optische Bilddokumentation."
            />
           
            
        </div>
    )
}

export default EosOrthopädie;
