import React from 'react';
import "./KontaktBestätigung.css";

function KontaktBestätigung() {
    return (
        <div className="kontakt_bestätigung">
            <div className="kontakt_text">
            <h2>Geschafft! <br /> Vielen Dank für Ihre Anfrage.</h2>
            <p>Unser Team wird sich umgehend darum kümmern. <br /> Wir melden uns in Kürze!</p>
            </div>
            <div className="kontakt_bestätigung_foto">
            <img src="./img/Team_Foto.png" alt="Team Foto"/>
            </div>
        </div>
    )
}

export default KontaktBestätigung
