import React from 'react';
import {Link} from "react-router-dom";
import "./TelemedizinHeader.css";

function TelemedizinHeader() {
    return (
        <div className="eos_header">
             <img id="desktop_pic"src="./img/Doctor2_White.jpg" alt=""/>
             <img id="mobile_pic" src="./img/MCW_Mobile_Background.jpg" alt=""/>
            <div className="eos_textbar">
                <img src="./img/Telemedizin_Logo.png" alt="MCW Telemedizin" />
                <p>Die Telemedizin-Lösung für ÄrztInnen aus Österreich</p> 
                <h1>
                    Video-Sprechstunden <br/>für Ihre Ordination
                </h1>
                <Link className="button_link_header" to="/kontakt">Beratung vereinbaren</Link>
            </div>
        </div>
    )
}
export default TelemedizinHeader
