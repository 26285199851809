import React from 'react'
import './VideoBanner.css'
import {Link} from "react-router-dom";


function VideoBanner() {
  return (
    <div className="video_banner">
        <div className="video_banner_desktop"> 
        <video loop autoPlay muted playsInline className='video_banner'>
    <source src="./img/mcw_messe_1.mp4" type="video/mp4"/>
        </video>
        </div>
        <div className="video_banner_mobile-2">
        <video loop autoPlay muted playsInline className='video_banner_mobile-2'>
    <source src="./img/mcw_messe_mobile.mp4" type="video/mp4"/>
        </video>
        </div>
     <div className="video_button">
     <Link className="link_button_video" to="/kontakt">Kostenlos beraten lassen</Link> 
     </div>

    </div>
  )
}

export default VideoBanner

