import React, { useState, useEffect } from 'react';
import firebase from './firebaseConfig';
import { useHistory } from 'react-router-dom';
import './SignUpSignIn.css';

const SignUpSignIn = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isSignUp, setIsSignUp] = useState(true);
    const history = useHistory();

    useEffect(() => {

        const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user && user.emailVerified) {
                history.push("/events-dashboard");
            } else {
                console.log("Benutzer ist nicht authentifiziert oder E-Mail nicht verifiziert.");
            }
        });

        return () => {
            unsubscribe();
        };
    }, [history]);

    const handleEmailValidation = (email) => {
        const domain = email.split('@')[1];
        return domain === 'mcw.at' || email === 'juliprop@hotmail.com';
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (isSignUp && !handleEmailValidation(email)) {
            alert("Ungültige E-Mail-Adresse.");
            return;
        }

        try {
            if (isSignUp) {
                const userCredential = await firebase.auth().createUserWithEmailAndPassword(email, password);
                const user = userCredential.user;

                if (user) {
                    await user.sendEmailVerification();
                    alert("Bitte überprüfen Sie Ihre E-Mail, um Ihr Konto zu bestätigen.");
                }
            } else {
                await firebase.auth().signInWithEmailAndPassword(email, password);

                // Direkte Weiterleitung nach erfolgreicher Anmeldung
                history.push("/events-dashboard");
            }
        } catch (error) {
            alert(error.message);
        }
    };

    return (
        <div className="signUpSignIn-container">
            <video className="background-video" autoPlay muted loop>
        <source src="/img/mcw_messe_1.mp4" type="video/mp4" />
        Ihr Browser unterstützt das Video-Tag nicht.
    </video>
            <form className="signUpSignIn-form" onSubmit={handleSubmit}>
            <img src="./img/Events_Logo.png" alt="logo" />
            <h2>{isSignUp ? "Account Erstellen" : "Einloggen"}</h2>
                <input type="email" value={email} onChange={e => setEmail(e.target.value)} placeholder="E-Mail" />
                <input type="password" value={password} onChange={e => setPassword(e.target.value)} placeholder="Passwort" />

                {isSignUp ? (
                    <>
                        <button type="submit">Registrieren</button>
                        <p>Haben Sie bereits ein Konto? <span onClick={() => setIsSignUp(false)}>Anmelden</span></p>
                    </>
                ) : (
                    <>
                        <button type="submit">Anmelden</button>
                        <p>Noch kein Konto? <span onClick={() => setIsSignUp(true)}>Registrieren</span></p>
                    </>
                )}
            </form>
        </div>
    );
};

export default SignUpSignIn;
