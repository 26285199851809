import React from 'react'
import HomeInfo1 from '../HomeInfo1';
import HomeInfo2 from '../HomeInfo2';
import Kontakt from '../Kontakt';
import TelemedizinFunktionen from './TelemedizinFunktionen';
import TelemedizinHeader from './TelemedizinHeader';
import "./TelemedizinHome.css";
import {Helmet} from "react-helmet";
import VideoBanner from '../VideoBanner';


function TelemedizinHome() {
    return (
        <div className="eos_home">
            <Helmet>
            <title>MCW Telemedizin - Die Videosprechstunde für Ihre Ordination </title>
            <meta name="description" content="Verwandeln Sie Ihre Ordination in eine Tele-Ordination! Dafür ist es nicht einmal notwendig, eine eigene Homepage zu besitzen. Wir unterstützen Sie dabei mit Rat und Tat. Kontaktieren Sie unser Team noch heute!" />
        </Helmet>
            <TelemedizinHeader />
            <TelemedizinFunktionen />
            
            <HomeInfo1 
            title="Die MCW Telemedizin Software aus Österreich."
            textbig="Helfen Sie Ihren PatientInnen bequem von zu Hause aus."
            text="Verwandeln Sie Ihre Ordination in eine Tele-Ordination! Dafür ist es nicht einmal notwendig, eine eigene Homepage zu besitzen. Über unsere Plattform Booking-Doc können Ihre Patienten Online-Termine buchen, welche Sie von überall auf der Welt aus abwickeln können. Genial, oder?"
            button="Beratung vereinbaren"
            img="./img/Happy_Doc.jpg"
            url="/anfrage"
            />

            <HomeInfo2 
            title="Online Service für Ihre Patienten"
            textbig="Wir wollen es Ihnen einfach machen."
            text="Unsere Tele-Medizin ist ebenso einfach, wie genial. Alle PatientInnen, die über einen herkömmlichen PC (Smartphone, Tablet…) und eine E-Mail-Adresse verfügen, können Ihre Tele-Ordination besuchen. Gerade für ältere PatientInnen, die sich mit der Handhabung des Computers nicht so leicht tun, ist das System aufgrund seiner einfachen Bedienbarkeit ideal. 
            "
            button="Termin vereinbaren" 
            img="./img/MCW_Videotelefonie.jpg"
            url="/anfrage"
            />
            <VideoBanner />
            <Kontakt />
        </div>
    )
}

export default TelemedizinHome;
