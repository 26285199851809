import React from 'react';
import "./HomeInfo3.css"

function HomeInfo3({title, textbig, text,text2,img, logo}) {
    return (
        <div className="home_info3">
            <div className="home_info3_container">
            <div className="home_info3_right">
            <img src={img} alt="home-info-foto"/>
        </div>
        <div className="home_info3_left">
        <img src={logo} alt="" />
        <h2>Was unsere <span>ÄrztInnen</span> sagen:</h2>
        <p>"{text}"</p>
        <h4>{textbig}</h4>
        <h5>{text2}</h5>
        </div>
            </div>
        </div>
    )
}

export default HomeInfo3;
