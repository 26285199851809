import React from 'react';
import "./Home.css";
import HomeSliderTop from './HomeSliderTop';
import HomeProducts from "./HomeProducts";
import HomeInfo1 from './HomeInfo1';
import HomeInfo2 from './HomeInfo2';
import HomeInfo3 from './HomeInfo3';
import Kontakt from './Kontakt'; 
import Standorte from './Standorte';
import HomeSliderMobile from './HomeSliderMobile';
import HomeSliderMobileXL from './HomeSliderMobileXL';
import {Helmet} from "react-helmet";
import VideoBanner from './VideoBanner';
import TeamBanner from './TeamBanner';
import VideoBanner2 from './VideoBanner2';
import VideoBanner3 from './VideoBanner3';
import HomeSlogan from './HomeSlogan';
import HomeBenefits from './HomeBenefits';
import HomeSoftware from './HomeSoftware';
import HomeToggle from './HomeToggle';
import OfferList1 from './OfferList1';


function Home() {



    return (
        <div className="home">

<Helmet>
            <title>MCW - Medical Computer Ware</title>
            <meta
      name="description"
      content="Software für Ärzte aus Österreich seit über 30 Jahren. Wir bieten Ihnen moderne Ordinationslösungen inklusive erstklassigem Kundenservice. Mit MCW bringen Sie Ihre Ordination auf das nächste Level. "
    />
        </Helmet>

            <HomeSliderMobile />
            <HomeSliderMobileXL />
            <HomeSliderTop />
            <hr class="hrrrrr"/>
            <VideoBanner3 />
            <HomeSlogan />
            <HomeBenefits />
            <HomeSoftware />
<div class="home_info_2_div">
            <HomeInfo2 
            title="TermiOn kann jetzt noch mehr."
            textbig="Termion 4.0 - Unser bis jetzt größtes Update ist da!"
            text="Smart Dashboard, Intelligent Booking und viele weitere Funktionen werden Ihnen und und Ihrer Ordination neue Wege eröffnen."
            button="Mehr Erfahren" 
            img="./img/termion_update.webp"
            url="/termion"
            />
            <HomeToggle />
            <HomeProducts />

</div>
            {/* <HomeInfo1 
            title="Mit MCW werden Sie zum Superdoc."
            textbig="Unser Team ist immer für Sie da."
            text="Kundenservice steht bei uns an erster Stelle. Sollten Sie bei Ihrer Arztsoftware auf Probleme oder Fragen stoßen, weiß unser Kundenservice immer Rat und Tat."
            button="Das MCW Team"
            img="./img/Doppeldoc.png"
            url="/team"

            /> */}

             <OfferList1 />
            <TeamBanner />
            <HomeInfo3
            text="Mit MCW habe ich einen Partner gefunden, der mich bereits seit vielen Jahren mit modernster Praxissoftware unterstützt."
            textbig="Nicole Schönbauer"
            text2="Ärztin aus Traiskirchen"
            img="./img/DrSchoenbauer.jpg"
            logo="./img/logo_schoen.webp"

            />
        
            <VideoBanner />


            <HomeInfo1 
            title="35 Jahre Medical Computer Ware"
            textbig="Wir sagen Danke!"
            text="Dank Ihrer Unterstützung zählen wir seit 1988 immer noch zu den Marktführern in unserem Bereich - ein Gütezeichen, das nicht jeder österreichischen Firma vergönnt ist. Wir freuen uns darauf, viele weitere Jahre an Ihrer Seite zu wachsen!"
            button="Termin vereinbaren"
            img="./img/MCW_Retro.jpg"
            url="/kontakt"

            />

            <HomeInfo2 
            title="Online Coaching & Einschulung"
            textbig="Premium Kunden-Support, wo auch immer Sie sind."
            text="Lehnen Sie sich zurück und lassen Sie sich von unseren ExpertInnen einschulen, beraten oder weiterhelfen."
            button="Zum Service Center" 
            img="./img/MCW_Ben_Termion.jpg"
            url="/service"
            />
            <Kontakt />
            
            <Standorte />
           
        </div>
    )
}

export default Home;
