import React, { useState } from 'react';
import './HomeToggle.css';

function HomeToggle() {
  const [isActive, setIsActive] = useState(true);

  // Funktion zum Umschalten des Status
  const toggleSwitch = () => {
    setIsActive(!isActive);
  };

  return (
    <div className={`home-toggle ${!isActive ? 'deactivo' : ''}`}>
          { !isActive && <div className="light-point"></div> }
      <div className="home-toggle-up">
        <div className="home-toggle-up-left">
        <div className="home-toggle-switch-container" onClick={toggleSwitch}>
              <div className={`toggle-switch ${isActive ? 'active' : ''}`}>
                {/* Toggle Switch */}
              </div>
              <span className="toggle-status">{isActive ? 'ORDINATION IST GESCHÜTZT' : 'OHNE CYBERSHIELD'}</span>
            </div>
          <div className="home-toggle-header">
          <h2>{isActive ? "Cybershield Pro" : "Ungeschützte Ordination"}</h2>
          <h6>{isActive ? "NEU" : ""}</h6>

          </div>
          <p>{isActive ? "Mit dem MCW Cybershield setzen Sie auf eine fortschrittliche Cybersicherheitslösung, die speziell darauf ausgelegt ist, die digitale Integrität von Arztpraxen zu schützen und zu optimieren." : "In einer Arztpraxis ohne angemessene Sicherheitsmaßnahmen schwebt die ständige Bedrohung durch digitale Eindringlinge wie ein Schatten über dem Betrieb."}</p>
        </div>
        <div className="home-toggle-up-right">
        <img src={isActive ? "./img/cyber_shield_logo.webp" : "./img/close.svg"} alt="Logo" />
        </div>
      </div>
      <div className="home-toggle-down">
        <div className="home-toggle-down-container">
          <div className="home-toggle-down-box">
          <img src={isActive ? "./img/cyber.svg" : "./img/hacker.svg"} alt="Logo" />
            <h3>{isActive ? "Erstklassige Datensicherheit" : "Offen für Hacker und Eindringlinge"}</h3>
            <p>{isActive ? "Unser MCW Cybershield nutzt hochverschlüsselte Sicherheitsprotokolle, um sicherzustellen, dass Ihre sensiblen Patientendaten und Praxisinformationen mit der höchsten verfügbaren Sicherheitsstufe geschützt werden." : "Hacker und Eindringlinge suchen aktiv nach Schwachstellen, um sensible Daten zu stehlen oder Systeme zu kompromittieren, was die Privatsphäre Ihrer Patienten und die Integrität Ihrer Praxis gefährdet."}</p>
          </div>
          <hr/>
          <div className="home-toggle-down-box">
          <img src={isActive ? "./img/doc.svg" : "./img/electric.svg"} alt="Logo" />
            <h3>{isActive ? "Flüssiger Praxisbetrieb" : "Ständige Betriebsgefahr"}</h3>
            <p>{isActive ? "Durch die Implementierung von MCW Cybershield in Ihre Praxisinfrastruktur können Sie einen reibungslosen Betriebsablauf ohne Unterbrechungen durch Cyberangriffe genießen." : "Ein ungeschütztes System ist ständig der Gefahr von Betriebsunterbrechungen ausgesetzt, sei es durch Ransomware, die wichtige Dateien verschlüsselt, oder durch andere schädliche Software, die kritische Systemfunktionen stört."}</p>
          </div>
          <hr/>
          <div className="home-toggle-down-box">
          <img src={isActive ? "./img/recovery.svg" : "./img/hammer.svg"} alt="Logo" />
            <h3>{isActive ? "Regelmäßige Daten-Backups" : "Verstoß gegen Datenschutzgesetze"}</h3>
            <p>{isActive ? "MCW Cybershield automatisiert den Prozess der Datensicherung. Im Falle eines Datenverlustes durch Hardwarefehler oder andere unvorhersehbare Ereignisse können Ihre Daten schnell und vollständig wiederhergestellt werden." : "Die Einhaltung von Datenschutzgesetzen ist für medizinische Einrichtungen verpflichtend. Ein Fehlen adäquater Sicherheitsmaßnahmen kann zu unbeabsichtigten Verstößen gegen diese Gesetze führen."}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomeToggle;
