import React from 'react';
import {Link} from "react-router-dom";
import "./Footer.css";

function Footer() {


    return (
        <div className="footer">
            <div className="footer_elements">
            <img src="./img/Mcw_Logo_Mobile.png" alt=""/>
            <p>Copyright @ Medical Computer Ware 2024</p>
             <Link className="link_agb" to="/agb">AGB</Link> 
             <Link className="link_agb" to="/impressum">Impressum</Link> 
             <Link className="link_agb" to="/datenschutz">Datenschutz</Link> 
             <Link className="link_agb" to="/kontakt">Kontakt</Link> 
             </div>
        </div>
    )
}

export default Footer
