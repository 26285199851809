import React from 'react';
import "./Feature.css";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';



function Feature({text}) {
    return (
        <div className="feature">
           <CheckCircleIcon />
           <h4>{text}</h4>
        </div>
    )
}

export default Feature;