import React from 'react';
import Funktion from '../Funktion';
import "./TelemedizinFunktionen.css";

function TelemedizinFunktionen() {
    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2> Funktionen & Möglichkeiten</h2>
            </div>
            <Funktion 
            img="./img/Facharzt_Icon.png"
            title1="PatientInnen"
            title2="kontaktlos und online betreuen"
            paket="Standardpaket"
            info="Mit dem MCW Telemedizin-Tool können sie ihre Patienten kontaktlos und online betreuen. So ermöglichen sie ihren Patienten einen extra service mit dem sie sich von ihrer Konkurrenz abheben können. Probieren sie MCW Telemedizin noch heute"
            feature1="Patienten kinderleicht verwalten"
            feature2="Rechnungen ausdrucken"
            feature3="DSGVO Konform"
            />
               <Funktion 
            img="./icon/Telemedizin1_Icon.png"
            title1="Datenschutzkonforme"
            title2="Videoübertragung in HD Qualität."
            paket="Standardpaket"
            info="Unsere Software bietet ihnen eine kristallklare Videoübertragung in HD Qualität."
            feature1="HD Qualität"
            feature2="Schnelle Ladezeiten"
            feature3="Reibungslose Videoübertragung"
            />
               <Funktion 
            img="./icon/Integrations_Icon.png"
            title1="Kinderleicht integrierbar"
            title2="in alle Ordinationssysteme"
            paket="Standardpaket"
            info="MCW Telemedizin funktioniert unabhängig von ihrer bereits bestehenden Ordinations-Software. Außerdem können sie MCW Telemedizin sowohl auf Apple/Mac- als auch auf Windows-Geräten verwenden. Somit ist sie für a"
            feature1="Software Unabhängig"
            feature2="Apple/Mac Kompatibel"
            feature3="Windows Kompatibel"
            />
            
             <Funktion 
            img="./icon/Terminkalender_Icon.png"
            title1="Termion Online Terminkalender"
            title2="als perfekte Ergänzung"
            paket="Zusatzpaket"
            info="Termion - Unser Online Terminkalender bietet sich als perfekte Ergänzung zur Telemedizin an. So können ihre Patienten online buchen, sie verwalten alle Termine von überall aus und halten Videosprechstunden direkt online ab."
            feature1="Online Terminkalender"
            feature2="Online Buchung für ihre Patienten"
            feature3="Telemedizin"
            />

            
            
        </div>
    )
}

export default TelemedizinFunktionen;
