// HomeBenefits.js
import React from 'react';
import './HomeBenefits.css'; // Stelle sicher, dass du eine entsprechende CSS-Datei erstellst
import SiegelImage from './img/siegel.webp';
import { useInView } from 'react-intersection-observer'; // Importiere den Hook

const BenefitTile = ({ imageSrc, headerText, text }) => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Element soll nur einmal animiert werden
    threshold: 0.5, // Animation startet, wenn 50% des Elements sichtbar sind
  });
  return (
     <div
      className={`benefit-tile ${inView ? 'fade-in' : ''}`}
      ref={ref}
    >
        <div className="benefits-image-box">
      <img src={imageSrc} alt="Benefit" />
      </div>
      <h3>{headerText}</h3>
      <p>{text}</p>
    </div>
  );
};

const HomeBenefits = () => {
  // Beispielinhalt, ersetze dies mit deinen echten Daten
  const benefits = [
    { imageSrc: './img/clock_1.svg', headerText: 'Zeitsparende Praxisverwaltung', text: ' Durch die Automatisierung alltäglicher Aufgaben ermöglichen wir Ihnen, signifikant Zeit zu sparen, die stattdessen in die Patientenversorgung investiert werden kann.' },
    { imageSrc: './img/gold.svg', headerText: 'Modulares System', text: ' Die speziell auf Sie zugeschnittene Software hilft dabei, Ihre Praxis wirtschaftlicher zu betreiben, was zu höheren Einnahmen bei gleichzeitig niedrigeren Betriebskosten führt.' },
    { imageSrc: './img/eye.svg', headerText: 'Digitale Ordination', text: 'MCW ermöglicht es Ihnen, Ihre Praxis effizient von jedem Ort aus zu verwalten, indem wir umfassende Funktionen für Terminplanung und Praxismanagement bieten.' },
    { imageSrc: './img/engage.svg', headerText: 'Premium Support', text: 'Ein zuverlässiger und kompetenter Support garantiert, dass jegliche Herausforderungen schnell und effizient gelöst werden und Ausfallzeiten minimiert werden.' },
    { imageSrc: './img/shake.svg', headerText: 'Benutzerfreundlich²', text: 'Einfachheit ist der Schlüssel zum Erfolg, und unsere Software ist so intuitiv gestaltet, dass ihre Benutzung für jeden Mitarbeiter ein Kinderspiel ist.' },
    { imageSrc: './img/future.svg', headerText: 'Schulungen & Veranstaltungen', text: 'Unser Schulungsangebot umfasst regelmäßige Schulungen in Wien und Kärnten um Ihrem Team die maximale Effizienz und Effektivität unserer Software zu vermitteln.' }
  ];

  return (
    <div className="home-benefits">
      {/* <div className="siegel-section">
        <img src={SiegelImage} alt="Siegel" className="siegel-image" />
        <div className="siegel-text">
          <h2>Unser Erfolgsrezept seit über 30 Jahren</h2>
        </div>
      </div> */}
      <div className="benefits-grid">
        {benefits.map((benefit, index) => (
          <BenefitTile key={index} {...benefit} />
        ))}
      </div>
    </div>
  );
};

export default HomeBenefits;
