import React from 'react';
import { Link } from 'react-router-dom';
import './ThankYou.css'; // Stellen Sie sicher, dass Sie diese CSS-Datei erstellen

const ThankYou = () => {
  return (
    <div className="thankYouContainer">
        <div>

      <img src="./img/check-animation.gif" alt="MCW Logo" style={{ width: '40px' }} />
      <h1>Anfrage versendet</h1>
      </div>
      <p>Vielen Dank für Ihr Interesse. Wir werden Ihre Anfrage so schnell wie möglich bearbeiten und uns bei Ihnen melden.</p>
      <Link className="button_link_header2" to="/">Zurück zur Startseite</Link>
    </div>
  );
};

export default ThankYou;
