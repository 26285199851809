import React from 'react';
import "./Shop.css";
import ShopProduct from './ShopProduct';

function Shop() {

    return (
        <div className="shop">
            <div className="shop_left">
            <div className="shop_left_top">
        <img src="./img/Mcw_Shop_Logo2.png" alt=""/>
        <p>Der IT-Online-Shop für ihre Ordination aus Österreich</p>
            </div>
            <div className="Shop_left_selector">
            <nav>
                <h4 className ="shop_left_selector_selected">Alle Produkte</h4>
                <h4>Software</h4>
                <h4>IT-Systeme & Hardware</h4>
                <h4>Schnittstellen</h4>
                <h4>Dienstleistungen</h4>
            </nav>
            </div>
            </div>
            <div className="shop_right">
                <div className="shop_right_info">
                    <h3>Alle Produkte</h3>
                    <hr />
                </div>
                <div className="shop_right_products">
                <ShopProduct 
                img="./img/Test_Computer.jpg"
                price="799.99€"
                title1="Terra"
                title2="All in one PC"
                textVis="Der Terra all in One PC verfügt über eine Viezahl an Funktionen. Probieren sie ihn heute noch aus und bringen sie ihre Ordination auf das nächste Level."
                textHide="Außerdem können sie mit diesem Computer kabelsalat vermeiden da sich der rechnner direkt im Display befinden. Somit bleibt ihre Ordination ordentlich und sauber. Genießen sie jetzt die Vorteile der spitzen PCS von MCW. Wussten sie? können sie mit diesem Computer kabelsalat vermeiden da sich der rechnner direkt im Display befinden."
                feature1="500 GB SSD2 Festplatte"
                feature2="All-In-One Konstruktion"
                feature3="Display mit 300NIT"
                />
                    <ShopProduct 
                img="./img/Shop_Webcam.jpg"
                price="49.99€"
                title1="MCW"
                title2="Full HD Webcam für Telemedizin"
                textVis="Der Terra all in One PC verfügt über eine Viezahl an Funktionen. Probieren sie ihn heute noch aus und bringen sie ihre Ordination auf das nächste Level."
                textHide="Außerdem können sie mit diesem Computer kabelsalat vermeiden da sich der rechnner direkt im Display befinden. Somit bleibt ihre Ordination ordentlich und sauber. Genießen sie jetzt die Vorteile der spitzen PCS von MCW. Wussten sie? können sie mit diesem Computer kabelsalat vermeiden da sich der rechnner direkt im Display befinden."
                feature1="500 GB SSD2 Festplatte"
                feature2="All-In-One Konstruktion"
                feature3="Display mit 300NIT"
                />
                    <ShopProduct 
                img="./img/Shop_Homeoffice.jpg"
                price="999.99€"
                title1="Terra"
                title2="Notebook 13 Zoll"
                textVis="Der Terra all in One PC verfügt über eine Viezahl an Funktionen. Probieren sie ihn heute noch aus und bringen sie ihre Ordination auf das nächste Level."
                textHide="Außerdem können sie mit diesem Computer kabelsalat vermeiden da sich der rechnner direkt im Display befinden. Somit bleibt ihre Ordination ordentlich und sauber. Genießen sie jetzt die Vorteile der spitzen PCS von MCW. Wussten sie? können sie mit diesem Computer kabelsalat vermeiden da sich der rechnner direkt im Display befinden."
                feature1="500 GB SSD2 Festplatte"
                feature2="All-In-One Konstruktion"
                feature3="Display mit 300NIT"
                />
                    <ShopProduct 
                img="./img/Shop_Keion_Maus.jpg"
                price="69.99€"
                title1="Keion"
                title2="Hygienische Computer Maus"
                textVis="Der Terra all in One PC verfügt über eine Viezahl an Funktionen. Probieren sie ihn heute noch aus und bringen sie ihre Ordination auf das nächste Level."
                textHide="Außerdem können sie mit diesem Computer kabelsalat vermeiden da sich der rechnner direkt im Display befinden. Somit bleibt ihre Ordination ordentlich und sauber. Genießen sie jetzt die Vorteile der spitzen PCS von MCW. Wussten sie? können sie mit diesem Computer kabelsalat vermeiden da sich der rechnner direkt im Display befinden."
                feature1="500 GB SSD2 Festplatte"
                feature2="All-In-One Konstruktion"
                feature3="Display mit 300NIT"
                />
            </div>
        </div>
        </div>
    )
}

export default Shop;
