import React from 'react';
import Funktion from '../Funktion';
import "./BookingDocFunktionen.css";

function BookingDocFunktionen() {
    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2> Funktionen & Möglichkeiten</h2>
            </div>
            <Funktion 
            img="./img/Facharzt_Icon.png"
            title1="Patienten"
            title2="einfach online verwalten"
            paket="Standardpaket"
            info="Mit dem EosWin tool können sie kinderleicht ihre Patienten online verwalten. So ermöglichen sie ihren Patienten einen extra service mit dem sie sich von ihrer Konkurrenz abheben können. Probieren sie Eos noch heute"
            feature1="Patienten kinderleicht verwalten"
            feature2="Rechnungen ausdrucken"
            feature3="DSGVO Konform"
            />
               <Funktion 
            img="./img/Therapeut_Icon.png"
            title1="Rechnungen"
            title2="kinderleicht erstellen und ausdrucken"
            paket="Standardpaket"
            info="Mit dem EosWin tool können sie kinderleicht ihre Patienten online verwalten. So ermöglichen sie ihren Patienten einen extra service mit dem sie sich von ihrer Konkurrenz abheben können. Probieren sie Eos noch heute"
            feature1="Patienten kinderleicht verwalten"
            feature2="Rechnungen ausdrucken"
            feature3="DSGVO Konform"
            />
               <Funktion 
            img="./img/Telemedizin_Icon.png"
            title1="V.I.P."
            title2="support von unseren EOS ExpertInnen"
            paket="Standardpaket"
            info="Mit dem EosWin tool können sie kinderleicht ihre Patienten online verwalten. So ermöglichen sie ihren Patienten einen extra service mit dem sie sich von ihrer Konkurrenz abheben können. Probieren sie Eos noch heute"
            feature1="Patienten kinderleicht verwalten"
            feature2="Rechnungen ausdrucken"
            feature3="DSGVO Konform"
            />
               <Funktion 
            img="./img/Allgemeinmedizin_Icon.png"
            title1="Kalender"
            title2="inkl. Online Terminbuchung"
            paket="Zusatzpaket"
            info="Mit dem EosWin tool können sie kinderleicht ihre Patienten online verwalten. So ermöglichen sie ihren Patienten einen extra service mit dem sie sich von ihrer Konkurrenz abheben können. Probieren sie Eos noch heute"
            feature1="Patienten kinderleicht verwalten"
            feature2="Rechnungen ausdrucken"
            feature3="DSGVO Konform"
            />
            
            
        </div>
    )
}

export default BookingDocFunktionen;
