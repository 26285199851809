import React from 'react'
import HomeInfo1 from '../HomeInfo1';
import HomeInfo2 from '../HomeInfo2';
import Kontakt from '../Kontakt';
import DentalFunktionen from './DentalFunktionen';
import DentalHeader from './DentalHeader';
import "./DentalHome.css";
import {Helmet} from "react-helmet";
import VideoBanner from '../VideoBanner';

function DentalHome() {

    return (
        <div className="eos_home">
            <Helmet>
            <title>DentalPro - Die Software für Zahnärzte aus Österreich</title>
            <meta name="description" content="DentalPro ist eine beliebte Zahnarztsoftware aus Österreich von MCW. Um die Dental-Software auch in Ihrer Ordination ausprobieren zu können kontaktieren Sie unser Team. Testen Sie unverbindlich und kostenlos." />
        </Helmet>
            <DentalHeader />
            <DentalFunktionen />
            
            <HomeInfo1 
            title="Dental Pro - Seit vielen Jahren bestens bewährt"
            textbig="Die Zahnarzt-Software für Ärzte aus Österreich"
            text="DentalPro bietet alle Features, die Sie brauchen, um Ihre Ordination zu einem Selbstläufer zu machen. Seit über 30 Jahren konnte sich DentalPro in zahlreichen Ordinationen in ganz Österreich bewähren - hoffentlich auch bald in Ihrer?
            Kontaktieren Sie uns noch heute für eine kostenlose Beratung!"
            button="Beratung vereinbaren"
            img="./img/MCW_Retro.jpg"
            url="/anfrage"
            />

            <HomeInfo2 
            title="Individuelle Software für Ihre Zahnarzt-Praxis"
            textbig="Wir passen DentalPro an Ihre individuellen Anforderungen an"
            text="Lehnen Sie sich zurück und überlassen Sie die Anpassung von DentalPro unseren ExpertInnen - damit Sie mit einem maßgeschneiderten Programm arbeiten können.
            Machen Sie sich noch heute einen Beratungstermin aus."
            button="Termin vereinbaren" 
            img="./img/Dental_Foto.jpg"
            url="/anfrage"
            />
            <VideoBanner />
            <Kontakt />
        </div>
    )
}

export default DentalHome;
