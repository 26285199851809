import React from 'react'
import './HomeInfo9.css'
import {Link} from 'react-router-dom'

function HomeInfo9() {
  return (
    <div className="home_info_9">
        
        <div className="home_info_9_wrapper">
        <div className="home_info_9_img">
    
        </div>
        <img src="./img/Termion_Logo_Neu.png" alt="" id="logo_img"/>
        <h1>Der Online-Terminkalender für Ihre Ordination</h1>
        <p>Jetzt mit zahlreichen neuen Funktionen und verbesserter Performance.</p>
        <Link className="termion_button" to='/kontakt'>Beratungstermin vereinbaren</Link> 
        </div>
        </div>
  )
}

export default HomeInfo9