import React, {useState} from 'react';
import "./ShopProduct.css";
import CheckIcon from '@material-ui/icons/Check';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

function ShopProduct({img, price, title1,title2, textVis, textHide, feature1, feature2, feature3}) {



    const [toggle, setToggle] = useState(false)
 
    const toggler = () => {
        setToggle(!toggle) 
    }


    return (
        <div className="shop_product">
                      
        <img src={img} alt=""/>
          
            <div className="shop_product_info">
                <div className="shop_price">
                <h2>{price}</h2>
                <p>exkl. MWSt und Versand</p>
                </div>
            <div className="shop_product_info_text">
                <h2>{title1}</h2>
                <h3>{title2}</h3>
                 <p>{textVis}</p>

            <div className={toggle ? "shop_product_info_open" : "shop_product_info_hidden"}>
                <br></br>
                <p>{textHide}</p>
            </div>
            </div>
            <div className="shop_product_info_check_container">
                <div className="shop_product_info_check">
                <CheckIcon />
                {feature1}
                </div>
                <div className="shop_product_info_check">
                <CheckIcon />
                 {feature2}
                </div>
                 <div className="shop_product_info_check">
                <CheckIcon />
                 {feature3}
                </div>
                <div className="shop_product_info_check_hide">

                </div>
            </div>
            <div className="shop_products_info_buttons">
                <div className="shop_products_info_buttons_left">
                <button id="in_den_warenkorb_button">In den Warenkorb</button>
        <button id="beratung_vereinbaren_button">Beratung vereinbaren</button>
                </div>
        <div onClick={toggler} className="shop_products_info_buttons_right">
        <button id="shop_products_info_button_light">Mehr Info</button>
        <ExpandMoreIcon />
        </div>
        
            </div>
            </div>
        </div>
    )
}

export default ShopProduct
