import React from 'react'
import HomeInfo1 from '../HomeInfo1';
import HomeInfo2 from '../HomeInfo2';
import Kontakt from '../Kontakt';
import BookingDocFunktionen from './BookingDocFunktionen';
import BookingDocHeader from './BookingDocHeader';
import "./BookingDocHome.css";

function BookingDocHome() {
    return (
        <div className="eos_home">
            <BookingDocHeader />
            <BookingDocFunktionen />
            
            <HomeInfo1 
            title="Eos - Ein klassiker seit vielen Jahren."
            textbig="Auf diese Software ist verlass."
            text="Medical Computer Ware (MCW) ist ein Österreichisches Software-Unternehmen dass mit über 30 Jahren Erfahrung immer noch zu den Marktführern im Bereich Medizin-Software gehört."
            button="Beratung vereinbaren"
            img="./img/MCW_Retro.jpg"
            url="/kontakt"
            />

            <HomeInfo2 
            title="Wartung für ein Jahr Gratis"
            textbig="Premium Kunden-Support. Egal wo sie sind."
            text="Lehnen Sie sich zurück und lassen sie sich von unseren ExpertInnen einschulen, beraten oder weiterhelfen."
            button="Termin vereinbaren" 
            img="./img/Mcw_Hotline.png"
            url="/kontakt"
            />
            <Kontakt />
        </div>
    )
}

export default BookingDocHome;
