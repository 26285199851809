import React, { useState } from 'react';
import './Kontakt.css';
import emailjs from 'emailjs-com';
import { useHistory } from 'react-router-dom';
function Kontakt() {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [inputField, setInputField] = useState({
    vorname: '', // Anpassung von 'name' zu 'vorname' und 'nachname'
    nachname: '',
    email: '',
    tel: '',
    produkt: '', // Entsprechend der Anforderung
    kontakt: '', // Checkbox für die Zustimmung
  });

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };
  const validate = (inputField) => {
    //Email errors
    if (!inputField.email) {
      errors.email = 'Check Email';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputField.email)
    ) {
      errors.email = 'Invalid email address';
    }
    //kontakt Errors
    if (!inputField.kontakt) {
      errors.kontakt = 'Sie müssen die agbs akzeptieren';
    }
    return errors;
  };


  function sendToDatabase(e) {
    e.preventDefault();
  
    // Fehlerstatus zurücksetzen
    setErrors({});
  
    const validationErrors = validate(inputField);
    const noErrors = Object.keys(validationErrors).length === 0;
  
    setErrors(validationErrors);
  
    if (noErrors) {
      console.log('Daten werden gesendet', inputField);
      let formData = new FormData();
  
      // FormData mit den aktuellen Eingabefeldwerten füllen
      Object.entries(inputField).forEach(([key, value]) => {
        formData.append(key, value);
      });
  
      formData.append('service', 'wwwMcwContactDefault'); // Spezifischer Wert für dieses Formular
      formData.append('crypt', ''); // Angenommen, dies bleibt leer
  
      fetch("https://system.mcw.at/service/post.php", {
        method: "POST",
        body: formData,
      })
      .then(response => {
        if (!response.ok) {
          throw new Error('Netzwerkantwort war nicht ok');
        }
        return response.json();
      })
      .then(data => {
        // Erfolg
        console.log('Erfolg:', data);
        // Umleitung zur Dankesseite
        history.push('/bestätigung');
      })
      .catch(error => {
        console.error("Fehler beim Senden der Daten:", error);
        // Netzwerk- oder Serverfehler behandeln
        setErrors(prevErrors => ({
          ...prevErrors,
          form: 'Netzwerkfehler. Bitte versuchen Sie es später erneut.'
        }));
      });
    } else {
      console.log('Validierungsfehler', validationErrors);
    }
  }
  
  
  return (
    <div className="kontakt">
      <div className="kontakt_links">
        <div className="kontakt_info">
          <h1>Jetzt kostenlos Infomaterialien erhalten!</h1>
          <p>
            Um Ihre Demo-Version anzufordern, füllen Sie bitte folgende
            Informationen aus:
          </p>
        </div>
        <div className="formular">
          <form
            method="POST"
            onSubmit={sendToDatabase}
            id="contact-form"
            name="contact-form"
          >
            {/* Trennung in separate Felder für Vor- und Nachname */}
            <input
              className="input"
              placeholder="Vorname*"
              name="vorname"
              type="text"
              required
              onChange={inputsHandler}
            />
            <input
              className="input"
              placeholder="Nachname*"
              name="nachname"
              type="text"
              required
              onChange={inputsHandler}
            />
            <input
              className="input"
              placeholder="E-Mail-Adresse*"
              type="email"
              name="email"
              onChange={inputsHandler}
              required
            />
            <span className="error">{errors.email}</span>
            <input
              className="input"
              placeholder="Telefonnummer*"
              name="tel"
              type="text"
              required
              onChange={inputsHandler}
            />
            <select
              className="input2"
              name="produkt"
              onChange={inputsHandler}
              required
            >
              <option value="">Produkt auswählen</option>
              <option value="Alle Produkte">Alle Produkte</option>
              <option value="Termion">Termion</option>
              <option value="DentalPro">DentalPro</option>
              <option value="Eos">Eos</option>
              <option value="Telemedizin">Telemedizin</option>
              <option value="Befundkarte">Befund+</option>
              <option value="Notfallkarte">Notfallkarte</option>
              <option value="Bookingdoc">Cybershield</option>
            </select>
  
            <div className="checkbox_container">
              <input
                type="checkbox"
                id="kontakt"
                name="kontakt"
                onChange={inputsHandler}
                required
              />
              <label for="kontakt">
                Ich akzeptiere die AGBs und Datenschutzbestimmungen
              </label>
              <span className="error">{errors.kontakt}</span>
            </div>
            <button>Absenden</button>
          </form>
        </div>
      </div>
      <div className="kontakt_foto">
        <img src="./img/Mcw_Hotline.png" alt="" />
      </div>
    </div>
  );  
}

export default Kontakt;
