import React, { useState, useEffect } from 'react';
import firebase from './firebaseConfig';
import './EventsDashboard.css';

const EventsDashboard = () => {
  const [appointments, setAppointments] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const appointmentsRef = firebase.database().ref("appointments");

    appointmentsRef.on("value", (snapshot) => {
      const appointmentsData = snapshot.val();
      const tempList = [];
      for (let id in appointmentsData) {
        tempList.push({ id, ...appointmentsData[id] });
      }
      // Sortieren Sie die Einträge absteigend nach ihrem Erstellungsdatum (angenommen, es gibt ein "createdAt"-Feld)
      tempList.sort((a, b) => b.createdAt - a.createdAt);
      setAppointments(tempList);
    });

    return () => {
      appointmentsRef.off();
    };
  }, []);

  const filteredAppointments = appointments.filter(appointment => {
    return (
      appointment.ordinationName.toLowerCase().includes(searchTerm.toLowerCase()) ||
      appointment.contactPerson.toLowerCase().includes(searchTerm.toLowerCase())
    );
  });

  const totalParticipantsMorning = appointments.filter(appointment => appointment.timeSlot === "Vormittags").reduce((total, appointment) => {
    return total + parseInt(appointment.participants || "0");
}, 0);

const totalParticipantsAfternoon = appointments.filter(appointment => appointment.timeSlot === "Nachmittags").reduce((total, appointment) => {
    return total + parseInt(appointment.participants || "0");
}, 0);

  const exportToCSV = () => {
    const csvRows = [];
    
    // Header
    const headers = ["Zeitfenster", "Ordinationsname", "Ansprechpartner", "Email", "Telefon", "Teilnehmer"];
    csvRows.push(headers.join(','));
    
    // Daten
    for(const appointment of appointments) {
      csvRows.push([
        appointment.timeSlot,
        appointment.ordinationName,
        appointment.contactPerson,
        appointment.email,
        appointment.phone,
        appointment.participants,
      ].join(','));
    }

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'teilnehmer.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="events-dashboard">
      <h2>Teilnehmer: IT Sicherheitstag</h2>
      <div className="teilnehmer">
      <p className="teilnehmer-vm">Teilnehmer am Vormittag: {totalParticipantsMorning}</p>
      <p className="teilnehmer-nm">Teilnehmer am Nachmittag: {totalParticipantsAfternoon}</p>
      </div>
      <div className="searchbar">
      <input
        className="search"
        type="text"
        placeholder="Suche nach Ordination oder Ansprechperson"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
      />
      <button className="csv-export" onClick={exportToCSV}>Exportieren als CSV</button>
      </div>
      <ul>
        {filteredAppointments.map(appointment => (
          <li key={appointment.id}>
            <p>Zeitfenster: {appointment.timeSlot}</p>
            <p>Ordinationsname: {appointment.ordinationName}</p>
            <p>Ansprechpartner: {appointment.contactPerson}</p>
            <p>Email: {appointment.email}</p>
            <p>Telefon: {appointment.phone}</p>
            <p>Teilnehmer: {appointment.participants}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EventsDashboard;
