import React from 'react'
import './HomeInfo6.css'

function HomeInfo4() {
  return (
    <div className="home_info_6">
        <div className="home_info_6_text">
            <h2><span className="supercharged_6">Online Buchung</span> für Ihre Patienten.</h2>
        </div>
        <div class="home_info_6_img">
            <p> Mit Termion können Patienten direkt über Ihre Website oder BookingDoc.at neue Termine vereinbaren. Unkompliziert, vollautomatsich und rund um die Uhr.
            </p>
        </div>
        
        
        </div>
  )
}

export default HomeInfo4