import React from 'react'
import './HomeInfo4.css'

function HomeInfo4() {
  return (
    <div className="home_info_4">
        <div className="home_info_4_text">
            <p>Version 2.0</p>
            <h2><span className="supercharged">Termion</span> kann jetzt noch mehr.</h2>
            <h4>Smart Dashboard, Intelligent Booking und viele weitere Funktionen werden Sie und Ihre Ordination auf das nächste Level bringen.</h4>
        </div>
        <div class="home_info_4_img">
        </div>
        
        
        </div>
  )
}

export default HomeInfo4