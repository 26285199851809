import React, { useState } from 'react';
import './Kontaktformular1.css';
import emailjs from 'emailjs-com';
import { useHistory } from 'react-router-dom';

function Ecard() {
  const history = useHistory();
  const [errors, setErrors] = useState({});
  const [inputField, setInputField] = useState({
    name: '',
    email: '',
    tel: '',
    nameOrd: '',
  });

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
  };
  const validate = (inputField) => {
    //Email errors
    if (!inputField.email) {
      errors.email = 'Check Email';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(inputField.email)
    ) {
      errors.email = 'Invalid email address';
    }

    return errors;
  };
  function sendEmail(e) {
    e.preventDefault();
    const validationErrors = validate(inputField);
    const noErrors = Object.keys(validationErrors).length === 0;
    setErrors(validate(validationErrors));
    if (noErrors) {
      console.log('Authenticated', inputField);
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      var yyyy = today.getFullYear();
      today = dd + '.' + mm + '.' + yyyy;

      var message =
        '<html><head><link href="https://fonts.googleapis.com/css?family=Bungee+Inline" rel="stylesheet"></head>' +
        '<body style="font-family:verdana;">' +
        '<p>Einreichung:<span style="color:Blue;">Produkt Info</span></p><p>Datum:<b>' +
        today +
        '</b></p>' +
        '<p>Vorname und Nachname:<b>' +
        inputField.name +
        '</b></p><p>Name der Ordination :<b>' +
        inputField.nameOrd +
        '</b></p><p>Email:<b>' +
        inputField.email +
        '</b></p>' +
        '<p>Telefonnummer:<b>' +
        inputField.tel +
        '</b></p></body></html>';

      var templateParams = {
        titel: 'Produktinfo zu Ecard Monitoring inkl. IT Sicherheitscheck',
        subject:
          '<span style="color:Blue;">Produktinfo zu Ecard Monitoring inkl. IT Sicherheitscheck</span>',
        date: today,
        message: message,
        email: inputField.email,
      };

      try {
        emailjs
          .send(
            'service_i3gwto8',
            'template_rmhtdbw',
            templateParams,
            'user_m5XZtQ9ue6eZjf5XdkLvd'
          )
          .then(
            (res) => {
              console.log(res);
              history.push('/bestätigung');
            },
            (error) => {
              console.log(error.text);
            }
          );
      } catch (ex) {
        alert('error' + ex.message());
      }
    } else {
      //console.log('errors try again', validationErrors);
    }
  }
  return (
    <div className="kontakt">
      <img src="./img/MCW_Background.jpg" alt="" />
      <div className="kontakt_links">
        <div className="kontakt_info">
          <h1> Produktinfo zu e-Card Monitoring inkl. IT Sicherheitscheck </h1>
          <p>
            Ich interessiere mich für das e-Card Monitoring inkl. IT
            Sicherheitscheck für meine Ordination und bitte um Kontaktaufnahme.
          </p>
        </div>
        <div className="formular">
          <form
            method="POST"
            onSubmit={sendEmail}
            id="contact-form"
            name="contact-form"
          >
            <input
              className="input"
              placeholder="Vorname und Nachname*"
              name="name"
              type="text"
              onChange={inputsHandler}
              required
            />
            <input
              className="input"
              placeholder="Name der Ordination"
              type="text"
              name="nameOrd"
              onChange={inputsHandler}
              required
            />
            <input
              className="input"
              placeholder="Telefonnummer*"
              name="tel"
              type="text"
              required
              onChange={inputsHandler}
            />
            <input
              className="input"
              placeholder="E-Mail"
              type="text"
              name="email"
              onChange={inputsHandler}
              required
            />
            <span className="error">{errors.email}</span>

            <button>Absenden</button>
          </form>
        </div>
      </div>
      <div className="kontakt_foto">
        <img src="./img/ecard_doctor.jpg" alt="" />
      </div>
    </div>
  );
}

export default Ecard;
