import React, { useState } from 'react';
import './Kontaktformular1.css';
import { useHistory } from 'react-router-dom';

function KontaktformularSpeed() {
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const [step, setStep] = useState(0);
  const NextStep = () => {
    if (step === 0) {
      if (
        inputField.titel !== '' &&
        inputField.vorname !== '' &&
        inputField.nachname !== ''
      ) {
        setStep(step + 1);
      }
    } else if (step === 1) {
      if (
        inputField.tel !== '' &&
        inputField.email !== '' &&
        inputField.aktion !== '' &&
        inputField.dringlichkeit !== ''
      ) {
        setStep(step + 1);
      }
    }
  };
  const [inputField, setInputField] = useState({
    kundennummer: '',
    titel: '',
    vorname: '',
    nachname: '',
    tel: '',
    email: '',
    aktion: '',
    dringlichkeit: '',
    kommentar: '',
  });

  const inputsHandler = (e) => {
    setInputField({ ...inputField, [e.target.name]: e.target.value });
    var $selector = document.getElementById('kontakt_button');
    if (step === 0) {
      if (
        inputField.titel !== '' &&
        inputField.vorname !== '' &&
        inputField.nachname !== ''
      ) {
        $selector.classList.remove('deactive');
      }
    } else if (step === 1) {
      if (
        inputField.tel !== '' &&
        inputField.email !== '' &&
        (inputField.aktion !== '' ||
          (e.target.name === 'aktion' && e.target.value !== '')) &&
        (inputField.dringlichkeit !== '' ||
          (e.target.name === 'dringlichkeit' && e.target.value !== ''))
      ) {
        $selector.classList.remove('deactive');
      }
    } else {
      $selector.classList.remove('deactive');
    }
  };

  function sendToDatabase(e) {
    e.preventDefault();
  
    let formData = new FormData();
    Object.entries(inputField).forEach(([key, value]) => formData.append(key, value));
    formData.append("service", "wwwMcwContactSpeed");
  
    fetch("https://system.mcw.at/service/post.php", {
      method: "POST",
      body: formData,
    })
    .then(response => {
      // Verarbeite die Antwort und überprüfe den Statuscode direkt im ersten 'then'
      if (!response.ok) {
        throw new Error(`Server antwortete mit Status: ${response.status}`);
      }
      // Die Antwort ist ok, also interpretieren wir das als Erfolg
      console.log("Daten erfolgreich gesendet", response);
      history.push('/bestätigung'); // Weiterleitung zur Bestätigungsseite
      return response.json(); // Kann weiterhin verarbeitet werden, falls notwendig
    })
    .catch(error => {
      console.error("Fehler:", error);
      setErrors({ form: error.message || "Netzwerkfehler. Bitte versuchen Sie es später erneut." });
    });
  }
  
  
  
  return (
    <div>
      {errors.form && (
  <div className="error-message">
    {errors.form}
  </div>
)}

      {step === 0 ? (
        <>
          <div className="kontaktformular_1">
          
            <div className="kontaktformular_1_schritte">
              <h4 className="selected">Schritt 1</h4>
              <hr />
              <h4>Schritt 2</h4>
              <hr />
              <h4>Schritt 3</h4>
            </div>
            <div className="kontaktformular_1_title">
              <h1>Speed Ticket</h1>
              <p>Bitte geben Sie Ihre Kunden-Informationen ein.</p>
            </div>
            <div className="kontaktformular_1_formulare">
            <form
  method="POST"
  onSubmit={sendToDatabase} // Ändere dies zu sendToDatabase im letzten Schritt
  id="contact-form"
  name="contact-form"
>
                <label className="custom_field one">
                  <input
                    type="text"
                    name="kundennummer"
                    onChange={inputsHandler}
                    required
                  />
                  <span className="placeholder">Kundennummer</span>
                  <img src="./img/mcw_round_icon.svg" alt="" />
                </label>
                <label className="custom_field one">
                  <input
                    type="text"
                    name="titel"
                    onChange={inputsHandler}
                    required
                  />
                  <span className="placeholder">Titel</span>
                  <img src="./img/user-2.svg" alt="" />
                </label>
                <label className="custom_field one">
                  <input
                    type="text"
                    name="vorname"
                    onChange={inputsHandler}
                    required
                  />
                  <span className="placeholder">Vorname</span>
                  <img src="./img/user-2.svg" alt="" />
                </label>
                <label className="custom_field one">
                  <input
                    type="text"
                    name="nachname"
                    onChange={inputsHandler}
                    required
                  />
                  <span className="placeholder">Nachname</span>
                  <img src="./img/user-2.svg" alt="" />
                </label>

                <button
                  className="deactive"
                  id="kontakt_button"
                  type="button"
                  onClick={NextStep}
                >
                  Weiter
                </button>
              </form>
            </div>
          </div>
        </>
      ) : (
        <div>
          {step === 1 ? (
            <>
              {' '}
              <div className="kontaktformular_1">
                <div className="kontaktformular_1_schritte">
                  <h4>Schritt 1</h4>
                  <hr />
                  <h4 className="selected">Schritt 2</h4>
                  <hr />
                  <h4>Schritt 3</h4>
                </div>
                <div className="kontaktformular_1_title">
                  <h1>Kontaktinformationen</h1>
                  <p>Geben sie Ihre E-Mail Adresse und Telefonnummer ein</p>
                </div>
                <div className="kontaktformular_2_formulare">
                  <form
                    method="POST"
                    onSubmit={NextStep}
                    id="contact-form"
                    name="contact-form"
                  >
                    <label className="custom_field one">
                      <input
                        type="text"
                        name="tel"
                        onChange={inputsHandler}
                        required
                      />
                      <span className="placeholder">Telefonnummer</span>
                    </label>
                    <label className="custom_field one">
                      <input
                        type="text"
                        name="email"
                        onChange={inputsHandler}
                        required
                      />
                      <span className="placeholder">E-Mail Adresse</span>
                    </label>

                    <p>Wie können wir sie erreichen?</p>

                    <select name="aktion" onChange={inputsHandler} required>
                      <option value="">Aktion auswählen</option>
                      <option value="Rückruf">Rückruf</option>
                      <option value="Fernwartung">Fernwartung</option>
                      <option value="Kontakt per E-Mail">
                        Kontakt per E-Mail
                      </option>
                    </select>

                    <p>Wie dringend ist ihr Anliegen?</p>

                    <select
                      name="dringlichkeit"
                      onChange={inputsHandler}
                      required
                    >
                      <option value="">Dringlichkeit auswählen</option>
                      <option value="Bei Gelegenheit">Bei Gelegenheit</option>
                      <option value="Ehest Möglich">Ehest Möglich</option>
                      <option value="Sehr Dringend">Sehr Dringend</option>
                    </select>

                    <button
                      className="deactive"
                      id="kontakt_button"
                      type="button"
                      onClick={NextStep}
                    >
                      Weiter
                    </button>
                  </form>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="kontaktformular_1">
                <div className="kontaktformular_1_schritte">
                  <h4>Schritt 1</h4>
                  <hr />
                  <h4>Schritt 2</h4>
                  <hr />
                  <h4 className="selected">Schritt 3</h4>
                </div>
                <div className="kontaktformular_1_title">
                  <h1>Letzter Schritt</h1>
                  <p>
                    Wollen Sie uns noch zusätzliche Informationen mitteilen?
                  </p>
                </div>
                <div className="kontaktformular_1_formulare">
                  <form
                    method="POST"
                    onSubmit={sendToDatabase}
                    id="contact-form"
                    name="contact-form"
                  >
                    <label>
                      <textarea
                        className="textbox"
                        type="text"
                        name="kommentar"
                        onChange={inputsHandler}
                        placeholder="Wie können wir Ihnen weiterhelfen..."
                      />
                    </label>

                    <button
                      id="kontakt_button"
                      type="submit"
                    >
                      Absenden
                    </button>
                  </form>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default KontaktformularSpeed;
