import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth'; 


const firebaseConfig = {
    apiKey: "AIzaSyDSPhNCPsolifUlC_tKheHmzfH5UanF3BQ",
    authDomain: "mcw-neu.firebaseapp.com",
    databaseURL: "https://mcw-neu-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mcw-neu",
    storageBucket: "mcw-neu.appspot.com",
    messagingSenderId: "538252927487",
    appId: "1:538252927487:web:e546911bc634218bd187ca"
  };
firebase.initializeApp(firebaseConfig);

export default firebase;
