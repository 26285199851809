// import React, { useState, useEffect } from 'react';
// import './VideoBanner.css';

// function VideoBanner3() {
//   const [scrollPosition, setScrollPosition] = useState(0);

//   const handleScroll = () => {
//     const position = window.pageYOffset;
//     setScrollPosition(position);
//   };

//   useEffect(() => {
//     window.addEventListener('scroll', handleScroll, { passive: true });

//     return () => {
//       window.removeEventListener('scroll', handleScroll);
//     };
//   }, []);

//   return (
//     <div className="video_banner_3">
//         <div 
//           className="video_banner_desktop-5" 
//           style={{
//             width: scrollPosition > 400 && scrollPosition < 900 ? '100vw' : '92vw'
//           }}
//         > 
//         <video style={{
//             borderRadius: scrollPosition > 400 && scrollPosition < 900 ? '0px' : '15px',
//             }} loop autoPlay muted playsInline className='video_banner'>
//           <source src="./img/mcw_team_website.mp4" type="video/mp4"/>
//         </video>
//         </div>
//         <div className="video_banner_mobile-3">
//           <video loop autoPlay muted playsInline className='video_banner_mobile'>
//           <source src="./img/mcw_team_website.mp4"  type="video/mp4"/>
//           </video>
//         </div>
//     </div>
//   )
// }

// export default VideoBanner3;


import React from 'react';
import './VideoBanner.css';

function VideoBanner3() {
  return (
    <div className="video_banner_3">
      <div className="video_banner_desktop-5"> 
        <video loop autoPlay muted playsInline className='video_banner'>
          <source src="./img/mcw_team_video.mp4" type="video/mp4"/>
        </video>
      </div>
      <div className="video_banner_mobile-3">
        <video loop autoPlay muted playsInline className='video_banner_mobile'>
          <source src="./img/mcw_team_video.mp4"  type="video/mp4"/>
        </video>
      </div>
    </div>
  )
}

export default VideoBanner3;
