import React from 'react';
import {Link} from "react-router-dom"; 
import "./DentalHeader.css";

function DentalHeader() {
    return (
        <div className="eos_header">
             <img src="./img/Eos_Background1.jpg" alt=""/>
            <div className="eos_textbar">
                <img src="./img/DentalPro_Logo.png" alt="Dental Pro Logo" />
                <p>Die Zahnarztsoftware aus Österreich</p> 
                <h1>
                Das All-in-One Tool <br/>für Zahnärzte
                </h1>
                <Link className="button_link_header" to="/kontakt">Beratung vereinbaren</Link>
            </div>
        </div>
    )
}
export default DentalHeader

