import React from 'react'
import './TeamMitglied.css'

function TeamMitglied({img, position, name, email, telefon, farbe}) {
  return (
    <div className="team_mitglied_box">
        <div className="team_mitglied_top">
    <img src={img} alt="" /> 
    <p style={{backgroundColor: farbe}}>{position}</p>
    <h4>{name}</h4>
        </div>
        <div className="team_mitglied_bottom">
        <div className="team_mitglied_infobox_1">
            <img src="./img/email.svg" alt="" />
            <p>{email}</p>
        </div>
        <div className="team_mitglied_infobox">
            <img src="./img/call.svg" alt="" />
            <p>{telefon}</p>
        </div>
        </div>
    </div>
  )
}

export default TeamMitglied