import React from 'react';
import "./Standorte.css";

function Standorte() {
    return (
        <div className="standorte">
            <div className="standorte_header">
                <h2>Unsere Standorte</h2>
                <p>Dreimal in Österreich vertreten. Ihr direkter Kontakt zu MCW.</p>
            </div>
            <div className="standorte_row">
                <div className="standort_container">
                    <img src="./img/MCW_Schulungszentrum.jpg" alt="Medical Computer Ware Schulungszentrum Wien"/>
                    <h5>MCW Schulungs-Zentrum</h5>
                    <div className="standort_container_adresse">
                        <p>Esterhazygasse 23</p>
                        <p>1060 Wien</p>
                    </div>
                    <div className="standort_container_footer">
                        <h6><a href="tel:015851100">Tel: 01 5851 100</a></h6>
                        <h6><a href="mailto:verkauf@mcw.at">verkauf@mcw.at</a></h6>
                    </div>
                </div>
                <div className="standort_container">
                    <img src="./img/Bgasse_Bild.jpg" alt="MCW-Breuninggasse-Bild"/>
                    <h5>MCW Service-Zentrum</h5>
                    <div className="standort_container_adresse">
                        <p>Breuninggasse 6</p>
                        <p>1230 Wien</p>
                    </div>
                    <div className="standort_container_footer">
                        <h6><a href="tel:016675665">Tel: 01 6675 665</a></h6>
                        <h6><a href="mailto:verkauf@mcw.at">verkauf@mcw.at</a></h6>
                    </div>
                </div> 
                <div className="standort_container">
                    <img src="./img/MCW_Kaernten.jpg" alt="MCW-Standort-Kärnten"/>
                    <h5>MCW Süd & Kärnten</h5>
                    <div className="standort_container_adresse">
                        <p>Am Hauptplatz 35</p>
                        <p>9462 Bad St. Leonhard</p>
                    </div>
                    <div className="standort_container_footer">
                        <h6><a href="tel:+43435020187">Tel: +43 4350 20187</a></h6>
                        <h6><a href="mailto:verkauf@mcw.at">verkauf@mcw.at</a></h6>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Standorte;
