import React from 'react'
import HomeInfo1 from '../HomeInfo1';
import HomeInfo2 from '../HomeInfo2';
import Kontakt from '../Kontakt';
import TermionFunktionen from './TermionFunktionen';
import TermionHeader from './TermionHeader';
import "./TermionHome.css";
import {Helmet} from "react-helmet";
import HomeInfo4 from '../HomeInfo4';
import HomeInfo5 from '../HomeInfo5'
import HomeInfo6 from '../HomeInfo6';
import HomeInfo7 from '../HomeInfo7';
import HomeInfo8 from '../HomeInfo8';
import HomeInfo9 from '../HomeInfo9';
import HomeInfo10 from '../HomeInfo10';
import VideoBanner from '../VideoBanner';




function TermionHome() {
    return (
        <div className="eos_home">
         <Helmet>
            <title>Termion - Der smarte Terminkalender für Ihre Arztpraxis und Ordination </title>
            <meta name="description" content="Unser moderne Terminplaner für Ärzte und Ärztinnnen aus Österreich. Automatische Erinnerungs-SMS an Patienten senden, Patientenverwaltung und vieles mehr. Jetzt 14 Tage kostenlos testen." />
        </Helmet>

            <HomeInfo9 />
            <TermionFunktionen />
            <HomeInfo4 />
            <HomeInfo5 
            titel1="100% Anpassungsfähig"
            text1="Termion passt sich Ihren Wünschen an. Sowohl spezielle Funktionen als auch das Design können individuell konfiguriert werden."
            img1="./img/computer.png"
            titel2="Leistungsstark & Zuverlässig"
            text2="Eine bis zu 40% schnellere Performance, verbesserte Stabilität und höchste Datensicherheit im Ordinationsbetrieb."
            img2="./img/grafik.png"
            />
            <HomeInfo6 />
            <HomeInfo8 />
            <HomeInfo5 
            titel1="Patientenverwaltung mit Schnittstelle"
            text1="Speichern Sie alle relevanten Informationen unkompliziert auf Termion oder übermitteln Sie Daten an Ihre Praxissoftware in Echtzeit."
            img1="./img/termion_grafik_2.png"
            titel2="Gemeinsam mit ÄrztInnen entwickelt"
            text2="Mit dem Feedback von duzenden ÄrztInnen haben wir Termion stetig weiterentwickelt und optimiert."
            backgroundImage='./img/Eos_Lady.jpg'
            />
            <HomeInfo7 />
            {/* <HomeInfo2 
            title="Online & Intern Termine Buchen"
            textbig="Testen Sie Termion noch heute."
            text="Gerne erstellen wir Ihnen einen Demo-Kalender den Sie unverbindlich testen können. Lassen Sie sich von unseren Experten einschulen, beraten und weiterhelfen."
            button="Demo Anfordern" 
            img="./img/network_2.jpg"
            url="/kontakt"
            /> */}
              <HomeInfo10 
            title="14 Tage kostenlos & unverbindlich testen"
            textbig="Testen Sie Termion noch heute."
            text="Gerne erstellen wir Ihnen einen Demo-Kalender den Sie unverbindlich testen können. Lassen Sie sich von unseren Experten einschulen, beraten und weiterhelfen."
            button="Demo Anfordern" 
            img="./img/imac_1.jpg"
            url="/kontakt"
            />

                     {/* <HomeInfo1 
            title="Der Terminplaner für Ärzte und Therapeuten"
            textbig="Terminverwaltung und Online-Buchung für Ihre Patienten."
            text="Unser elektronischer Terminplaner Termion hilft Ihnen dabei, den Alltag in Ihrer Ordination zu meistern. Egal ob Sie Fach- oder AllgemeinmedizinerIn sind - Termion passt sich automatisch an Ihre Wünsche an."
            button="Beratung vereinbaren"
            img="./img/termion_ipad.jpg"
            url="/kontakt"
            /> */}
            <VideoBanner />
            <Kontakt />
        </div>
    )
}

export default TermionHome;
