import React from 'react';
import {Link} from "react-router-dom";  
import "./BookingDocHeader.css";

function BookingDocHeader() {
    return (
        <div className="eos_header">
             <img src="./img/Eos_Win_Pic.jpg" alt=""/>
            <div className="eos_textbar">
                <img src="./img/BookingDoc_Logo_Neu.png" alt="Booking Doc Logo" />
                <p>N1. Software für Fachärzte aus Österreich</p> 
                <h1>
                    Bereit für den perfekten <br/>online Auftritt?
                </h1>
                <Link className="button_link_header" to="/kontakt">Beratung vereinbaren</Link>
            </div>
        </div>
    )
}
export default BookingDocHeader
