import "./HomeSliderTop.css";
import React from 'react';
import {Link} from "react-router-dom";

function HomeSliderTop() {



    return (
        <div className="home_slider_top">
            <div className="home_slider_top_links">
        <h2>
            Premium Praxissoftware aus Österreich
        </h2>
        <hr></hr>
        <h1 id="h1">
            Software für Ärzte seit 1986.
        </h1>
        <p>
        Medical Computer Ware (MCW) ist ein österreichisches IT-Unternehmen, welches mit über 30 Jahren Erfahrung zu den Marktführern im Bereich Medizin-Software gehört.
        </p>
        <Link className="button_link_header2" to="/kontakt">Beratung vereinbaren</Link>
            </div>
            <div className="home_slider_top_rechts">
        <img class="image fade-in-out" src="./img/studio_display.webp" alt="Ipad-Termion-Software"/>
        <img class="image fade-in-out delay" src="./img/studio_display_2.webp" alt="Ipad-Termion-Software-2"/>
        <img class="image fade-in-out delay2" src="./img/studio_display_3.webp" alt="Ipad-Termion-Software-3"/>
        </div>
        </div>
    )
}

export default HomeSliderTop;
