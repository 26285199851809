import React from 'react';
import Funktion1 from '../Funktion1';
import "./EosFunktionen.css";

function EosPädiatrie() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2>Eos für Pädiatristen</h2>
            </div>

          <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Pädiatrie (alle Kassen)"
            title2=""
            paket="Standardpaket"
            info="Im Setangebot enthalten:
            Umfangreiche medizinische Karteiführung * Leistungserfassung * Medikamentenverschreibung * Laborkartei * Abrechnung mit allen Kassen * Honororabrechnung * Ausdruck für den Steuerberater * Wartelisten * Statistik * Tagesjournale * Logo Einbindung bei sämtlichen Drucksorten * Tagesjournale
            Anpassungen für den Ablauf in der Kinderarztpraxis:
            Diätpläne * Impfplan * Percentiellengrafik * Kalorientabelle * Antrag auf Kostenübernahme * Ärztliches Zeugnis * OP Freigabe * Arztbrief Hüftsonografie * Spezifische Immuntherapie * Infektionsanzeige * Kalorientabelle * MKP Fragebogen * MKP Termine * Schädel Hirn Trauma Informationsblatt * PRICK Test * SIDS Informationsblatt * Therapieplan zur Behandlung von Skabies * Verhaltensmaßregeln bei Tonsilleektomie * BMI Index * Merkblatt bei Fieberkrampf * Histamin Diät * Infoblatt zur Vorbeugung von Lebensmittelallergie im Säuglingsalter * Pflegefreistellung * Schulbestätigung * automatische Altersberechnung * Erinnerungssystem (nächste fällige Impfung usw...) * Einbindung der Patienten Gesundheitsakte Befundkarte in der Kartei * Einbindung EOS-Servicecenter mit verschiedenen Online Hilfen (Fernwartung, Online Update usw...)"
            />

          <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Pädiatrie Wahlarzt"
            title2=""
            paket="Standardpaket"
            info="Im Setangebot enthalten:
            Umfangreiche medizinische Karteiführung * Leistungserfassung * Medikamentenverschreibung * Laborkartei * Leistungskataloge aller Kassen * Honororabrechnung * Ausdruck für den Steuerberater * Wartelisten * Statistik * Tagesjournale * Logo Einbindung bei sämtlichen Drucksorten * Tagesjournale
            Anpassungen für den Ablauf in der Kinderarztpraxis:
            Diätpläne * Impfplan * Percentiellengrafik * Kalorientabelle * Antrag auf Kostenübernahme * Ärztliches Zeugnis * OP Freigabe * Arztbrief Hüftsonografie * Spezifische Immuntherapie * Infektionsanzeige * Kalorientabelle * MKP Fragebogen * MKP Termine * Schädel Hirn Trauma Informationsblatt * PRICK Test * SIDS Informationsblatt * Therapieplan zur Behandlung von Skabies * Verhaltensmaßregeln bei Tonsilleektomie * BMI Index * Merkblatt bei Fieberkrampf * Histamin Diät * Infoblatt zur Vorbeugung von Lebensmittelallergie im Säuglingsalter * Pflegefreistellung * Schulbestätigung * automatische Altersberechnung * Erinnerungssystem (nächste fällige Impfung usw...) * Einbindung der Patienten Gesundheitsakte Befundkarte in der Kartei * Einbindung EOS-Servicecenter mit verschiedenen Online Hilfen (Fernwartung, Online Update usw...)"
            />

          <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="e-card Softwaremodul ABS"
            title2=""
            paket="Modul"
            info="Das Arzneimittel-Bewilligungs-Service (ABS) Softwaremodul dient zur Übermittlung von Bewilligungsanfragen über die eCard-Infrastruktur. Die Vorraussetzung für die direkte Anwendung des ABS Systems aus der Ordinationssoftware ist die Vollintegration der e-card (e-card Connectorsoftware)."
            />
        
        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Elektronische Laborüberweisung"
            title2=""
            paket="Modul"
            info=""
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Modul für Hausbesuche (Pocket PC-Lösung)"
            title2=""
            paket="Modul"
            info="Wenn Sie auf Hausbesuch sind, müssen Sie bei MCW auf nichts verzichten: Die Hausbesuchsliste wird bereits in der Ordination auf Ihren Pocket-PC übertragen, sämtliche Informationen (Diagnosen, Medikamente, Laborbefunde...) sind problemlos abrufbar. Nach den Hausbesuchen überträgt das Programm alle vor Ort eingegebenen Daten in Ihr Ordinationssystem. Eine ideale Lösung auch für den Besuch von Alten- und Pflegeheimen."
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Telefoneinbindung"
            title2=""
            paket="Modul"
            info="Ihre Telefonanlage und Ihre Ordinationssoftware ergänzen einander perfekt: Bei Anruf eines Patienten ersparen Sie sich die Eingabe seines Namens. Sie können sofort die Patientenkartei aufrufen und somit Ihren Ordinationsablauf entscheidend beschleunigen. Die technische Voraussetzungen müssen gegeben sein und werden von unseren Techniker vorab kostenlos abgeklärt."
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Spirometrie Einbindung"
            title2=""
            paket="Modul"
            info="Bei MCW können alle gängigen Lungenfunktionsgeräte, die mit entsprechenden EDV-Schnittstellen ausgestattet sind, ins Ordinationssystem voll integriert werden. Sie haben die Möglichkeit, alle Lungenfunktionsdaten schnell und unkompliziert einzusehen und zentral zu dokumentieren"
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Modul Recallsystem"
            title2=""
            paket="Modul"
            info="Kontrolltermin können mittels EOSWIN Recallsystem automatisch verwaltet werden.(Serienbrief,Bildschirmlisten,Erinnerung beim Aufruf des Patienten, usw..)"
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Zentrale Anmeldung (Ärztezentrum)"
            title2=""
            paket="Modul"
            info="Dieses Softwaremodul wurde speziell für Ärztezentren entwickelt, mit zentraler Anmeldung. Mit diesem Programm kann eine Assistentin einfach und schnell in mehreren Ordinationsprogrammen gleichzeitig arbeiten."
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Fotodokumentation - Wireless"
            title2=""
            paket="Modul"
            info="Fotodokumentation auf Knopfdruck Übertragen Sie Fotos direkt in Ihre elektronisch geführte Patientenkartei - ohne Stick und ohne Kabel!

            Jeder Arzt weiß, wie praktisch Patientenfotos sind: Damit können alle Behandlungen genauestens dokumentiert werden, wovon nicht nur der Arzt, sondern auch der Patient profitiert.
            
            Doch so sinnvoll Fotos in der Praxis auch sind, so mühsam gestaltete sich bisher die Fotodokumentation: Um die Fotos zu kopieren, musste die Kamera zuerst per Kabel oder Stick mit dem Computer verbunden werden. Danach mussten die Fotos in einem gut durchdachten Ablagesystem integriert werden, damit man sie auch später wiederfindet. Es war also notwendig, Ordner zu schaffen und Bilddateien händisch zu übertragen - ein nicht selten mühsamer und zeitaufwändiger Prozess!
            
            Doch damit ist es jetzt vorbei: Ein neuer Chip ermöglicht es ab sofort, die Fotos per Knopfdruck direkt in die Patientenkartei zu überspielen - ohne Stick und ohne Kabel! Sobald man den Auslöseknopf der Kamera drückt, sorgt eine spezielle MCW-Software dafür, dass das Bild direkt dort landet, wo es hin gehört. Das mühsame Anschließen an den Computer entfällt ebenso, wie das zeitaufwändige Suchen und Ordnen der Bilddateien. Für den Zahnarzt eine wesentliche Arbeitserleichterung und Zeitersparnis!
            
            Dabei ist die Anschaffung einer neuen Kamera in der Regel nicht erforderlich. Es ist nur notwendig, dass Ihre Kamera und der neue Chip kompatibel sind.
            
            Modul besteht aus: DOKU-Software; SDHC-Chipcarte; Softwareschnittstelle und WLAN Router"
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Einbindung Fremdlabor"
            title2=""
            paket="Modul"
            info="Automatische Zuordnung von Laborbefunden zum Patienten, Kennzeichnung der pathologischen Werte, automatische Verrechnung Möglichkeit bei Laborgemeinschaften, Empfangslisten. Voraussetzung: Befundempfang durch einen entsprechenden Provider"
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Labormodul (Eigenlabor)"
            title2=""
            paket="Modul"
            info="Onlineanschlüsse: Das EOS-WIN System kann an Untersuchungsgeräten online angeschlossen werden wie z.B. Laborgeräte oder EKG etc. Werte,Bilder und Diagramme können dabei direkt in einen z.B WINWORD-Befund übernommen und ausgedruckt werden. Das ermöglicht eine rasche und lückenlose Dokumentation und eine übersichtliche und vergleichende Darstellung von Krankeitsbildern."
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Onlineschnittstelle"
            title2=""
            paket="Modul"
            info="Einige Beispiele für bereits von MCW eingebundene Analyser: Elisa 300/400; Auto Counter;Hellige Blutanalyser; Hitachi 902,911,Sysmex K100;Clinitek;Autolab;A.Menarini Micro Aution;Spotchem;ABX Micros;ABX Mira;ABX Ready;Autohumananalyzer 850; ;Micros; AC920;Cobas Micro;Lab Technologies; Medonic;Vision Abbot; Swelab; SABA;Blutgaanalyzer;Instr.Laboratories;Drott;AVL .... usw..."
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN EKG Einbindung"
            title2=""
            paket="Modul"
            info="Bei MCW können alle gängigen EKG-Geräte, die mit entsprechenden EDV-Schnittstellen ausgestattet sind, ins Ordinationssystem voll integriert werden. Sie haben die Möglichkeit, alle EKG-Auswertungen schnell und unkompliziert einzusehen und zentral zu dokumentieren."
            />
        
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Scannersystem (Hard u. Software)"
            title2=""
            paket="Modul"
            info="Unterschiedlichste Befunde können Patientenbezogen direkt in Ihrer medizinischen Kartei gespeichert und exakt zugeordnet werden. Dieses rasche Ablagesystem spart eine raumfüllende Papierablage. EOSWIN Einbindung + Scannersoftware und Canon Hochleistungsscanner DR 2080C"
            />
        

        
        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Impfabrechnungsmodul für NÖ"
            title2=""
            paket="Modul"
            info=""
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Mehrarztstatistik"
            title2=""
            paket="Modul"
            info=""
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Spracheingabe"
            title2=""
            paket="Modul"
            info="Das Softwaremodul Spracheingabe besteht aus:

            * einem Integrationsmodul für die Spracherkennung
            * einem Schnittstellenprogramm zur EOSWIN Software
            * der Diktiersoftware Dragon
            * einem hochwertigen, geräuschunterdrückenden Headset
            * einer kostenlosen Einschulung in unserem Schulungscenter"
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Softwareschnittstelle zu MedNanny"
            title2=""
            paket="Modul"
            info="
            In periodischen Abständen (empfohlen 1 x pro Tag) werden alle Patienten aus der Ordinationssoftware EOSWIN in das Programm MedNanny exportiert. Die Terminverwaltung findet ausschließlich im MedNanny Programm statt."
            />
        

        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Registrierkassen Software - Basis"
            title2=""
            paket="Modul"
            info="Software für die Registrier und Belegerteilungspflicht ab 01.01.2016. Die erstellten Daten(Rechnungen / Honorarnoten) sind unveränderbar und die Belegausgabe ist mit einer Nummerierung vorgesehen die eine leichte Kontrolle der Vollständigkeit ermöglicht. Eine technische Manipulationssicherung ist vorgesehen. Die Erlöse werden eingebucht, also registriert, diese Eingabe erfolgt chronologisch.
            Die MCW-Registrierkassensoftware ist modulartig aufgebaut und kann mit Quittungsdrucker (Bon Drucker) Etikettendrucker und Barcodescanner kombiniert werden. Die MCW-Registrierkassen Software ist ein Softwareprodukt von MCW und somit 100% kompatibel zu dem bestehenden Ordinationssoftwareprogramm.
            
            Im Basisprogramm enthalten:
            
            - Eigener Belegkreis für registrierpflichtige Barumsätze .
            - Tagesabschluss der erbrachten Leistungen und Artikel
            - Aufschlüsselung nach Zahlungsart und Steuersätzen
            - Monatsabschluss.
            - Export des Protokolls auf einen externen Datenträger.
            - Quittung laut Belegerteilungsverpflichtung"
            />
        
        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN SMS Erinnerungssystem"
            title2=""
            paket="Modul"
            info="Erinnern Sie doch Ihre Patienten an anstehende Termine per SMS! Dabei erfolgen die Terminerinnerungen direkt aus dem EOSWIN-Terminkalender oder über individuell u erstellenden Abfrage-Listen mit Filterkriterien. Selbstverständlich dabei: Eine Kontroll-Liste für gesendete oder nicht gesendet SMS!
            Wichtig: Nicht jeder Handyhersteller entspricht den technischen Anforderungen des SMS-Versandes - Sprechen Sie vor dem Kauf Ihres Handys mit uns, wir überprüfen kostenlos ob Ihr Handy geeignet ist."
            />
        


        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Digitale Unterschrift (Signatur)"
            title2=""
            paket="Modul"
            info="Da die Dokumentations- und Aufklärungspflicht des Arztes eine immer wichtigere Rolle spielt, ist MCW auch in diesem Bereich Vorreiter und hat die digitale Signatur für Aufklärungsblätter und andere Formulare verwirklicht. Die unschlagbaren Pluspunkte:

            - Aufnahme der Schreibcharakteristik (Biometrie) der Unterschrift mittels Signatur-Pad
            - Speicherung der Daten in einem PDF/A-Dokument
            - Authentifizierung des Unterzeichners durch die Biometrie
            - Schutz gegen Manipulation durch ein eigenes Zertifikat
            - sehr hoher Beweiswert
            - nutzbar auch für Verträge"
            />
        


        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Schnittstelle ELDA"
            title2=""
            paket="Modul"
            info="elektronische Versand der Abrechnungsdaten
            Die Installation erfolgt über Fernwartung.
            Eine halbe Arbeitsstunde ist inkludiert.
            Jede benötigte weitere Arbeitsstunde wird zum günstigen Wartungspaketstundensatz von netto 87,-- Euro verrechnet."
            />
        


        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Erweiterte Kontrolliste der Abrechnung"
            title2=""
            paket="Modul"
            info=""
            />
        


        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Benutzer-Statistik"
            title2=""
            paket="Modul"
            info="Kartei-Auswertung nach Benutzer"
            />
        


        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Mailversand"
            title2=""
            paket="Modul"
            info="Das Modul ermöglicht es dem Arzt, direkt aus dem EOSWIN E-Mails zu versenden."
            />
        


        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Wahlarztabrechnung Online"
            title2=""
            paket="Modul"
            info="Mit diesem Softwaremodul hat der Arzt die Möglichkeit die bezahlten Honorare elektronisch an die Gebietskrankenkasse online zu übermitteln als Service für den Patienten.
            "
            />
        


        <Funktion1 
            img="./icon/Pädiatrie_Icon.png"
            title1="EOSWIN Video und Bildbearbeitungmodul"
            title2=""
            paket="Modul"
            info="Bilder von Videokameras können im Computer problemlos gespeichert und jederzeit abgerufen werden. Für Arzt und Patient ergibt sich daraus eine exzellente optische Bilddokumentation."
            />


        </div>
    )
}

export default EosPädiatrie;
