import "./HomeProducts.css";
import React from 'react'
import HomeProduct from "./HomeProduct";

function HomeProducts() {

    return (
        <div className="home_products">
            <div className="home_product_info">
            <h2>
             Software-Lösungen für Ihre Praxis
            </h2>
            <hr/>
            </div>
            
            <div className="home_product_row">
         <HomeProduct 
        img = "./img/Allgemeinmedizin_Icon.png"
        title = "Allgemeinmedizin"
        logo ="./img/Eos_Logo.png"
        check_eins ="Patienten-Administration"
        check_zwei ="Honorarnotenverwaltung"
        check_drei ="Über 50 Zusatzmodule"
        button ="Mehr erfahren" 
        url="/eos"

        />
        <HomeProduct 
        img = "./img/Zahnarzt_Icon.png"
        title = "Zahnarzt"
        logo ="./img/DentalPro_Logo.png"
        check_eins ="Honorarnoten erstellen"
        check_zwei ="Patientenakartei"
        check_drei ="E-Card Integration"
        button="Mehr erfahren" 
        url="/dental"

        />
            <HomeProduct 
        img = "./img/Facharzt_Icon.png"
        title = "Facharzt"
        logo ="./img/Eos_Logo.png"
        check_eins ="Rechnungen erstellen"
        check_zwei ="Patienten verwalten"
        check_drei ="Online Buchungen"
        button="Mehr erfahren"
        url="/eos"

        />
          <HomeProduct 
        img = "./img/Therapeut_Icon.png"
        title = "Therapeut"
        logo ="./img/Termion_Logo_Neu.png"
        check_eins ="Termine verwalten"
        check_zwei ="Online-Buchungen"
        check_drei ="Patientenkartei"
        button="Mehr erfahren"
        url="/termion"

        />
 
        </div>
        
        {/* <div className="home_product_info">
            <h2>
                Arzt-Patienten Software & Mehr
            </h2>
            </div>
            
            <div className="home_product_row">
            <HomeProduct 
        img = "./img/Buchungs_Icon.png"
        title = "Online Buchung"
        logo ="./img/BookingDoc_Logo_Neu.png"
        check_eins ="Online-Buchung"
        check_zwei ="Terminmanagement"
        check_drei ="Online Auftritt"
        button="Zur Homepage"
        isUrlExternal
        url="https://www.bookingdoc.at/"
        
        />
            <HomeProduct 
        img = "./img/Kalender_Icon.png"
        title = "Online-Terminkalender"
        logo ="./img/Termion_Logo_Neu.png"
        check_eins ="Terminverwaltung"
        check_zwei ="Patienten-Administration"
        check_drei ="Online-Buchungen"
        button="Mehr erfahren"
        url="/termion"

        />
                <HomeProduct 
        img = "./img/Telemedizin_Icon.png"
        title = "Telemedizin"
        logo ="./img/Telemedizin_Logo.png"
        check_eins ="Video-Sprechstunden"
        check_zwei ="HD Qualität"
        check_drei ="Software unabhängig"
        button="Mehr erfahren"
        url="/telemedizin"

        />
                <HomeProduct 
        img = "./img/Zusatzmodule_Icon.png"
        title = "Zusatzpakete"
        logo ="./img/Mcw_Shop_Logo.png"
        check_eins ="Hardware Lösungen"
        check_zwei ="Direkt Online bestellen"
        check_drei ="Beratung & Support"
        isUrlExternal
        button="Zum Shop"
        url="https://shop.mcw.at"
        />
         

        </div>
       */}
            </div>

    )
}

export default HomeProducts;
