import React from 'react';
import Funktion1 from '../Funktion1';
import "./EosFunktionen.css";

function EosOpthalmologie() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2>Eos für Opthalmologen</h2>
            </div>
            <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Ophthalmologie (alle Kassen)"
            title2=""
            paket="Standardpaket"
            info="Im Setangebot sind enthalten:
            Umfangreiche Stammdatenverwaltung , verschiedene Augenmasken, Übersichtliche Anordnung der Visusdaten, Umfangreiches Formularprogramm, z.B. Brillenrezepte,Überweisungen usw.. Diagnoseerfassung, Individuelles Kürzelsystem Leistungserfassung, Kassentarife und individuelle Leistungserfassung, Patienten können mehreren Kassen zugeordnet werden z.B. Privat Leistung auf Basis einer kleinen Kasse bzw. eine reine Private Leistung, Statistik, Honorarnotenverwaltung, Individuelle Honorargestaltung mit Logoeinbindung, Tagesjournal, offene Posten Liste, Mahnsystem usw... Medikamentendatenbank, Magistralterverwaltung, Grüne,Gelbe,Rote Box Medikamente,Medikamentenplan für Patienten, Textverarbeitung Spezielle Befundschreibung für den Augenarzt: aus der Patientenkartei können Daten direkt in die Befundschreibung übernommen werden, Layoutgestaltung mit Einbindung des Ordinations-Logos usw.. Umfangreiche Karteiführung: Interaktive Karteizeile!, Filterfunktion, Karteiübersicht mit den wichtigsten Hinweisen, Datenfilter, Verwalten von Befunden, Wartelistensystem. Abrechnung alle Kassen."
            />
            <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Ophthalmologie Wahlarzt"
            title2=""
            paket="Standardpaket"
            info="Im Setangebot enthalten:
            Umfangreiche Stammdatenverwaltung , verschiedene Augenmasken, Übersichtliche Anordnung der Visusdaten, Umfangreiches Formularprogramm, z.B. Brillenrezepte,Überweisungen usw.. Diagnoseerfassung, Individuelles Kürzelsystem Leistungserfassung, Kassentarife und individuelle Leistungserfassung, Patienten können mehreren Kassen zugeordnet werden z.B. Privat Leistung auf Basis einer kleinen Kasse bzw. eine reine Private Leistung, Statistik, Honorarnotenverwaltung, Individuelle Honorargestaltung mit Logoeinbindung, Tagesjournal, offene Posten Liste, Mahnsystem usw... Medikamentendatenbank, Magistralterverwaltung, Grüne,Gelbe,Rote Box Medikamente,Medikamentenplan für Patienten, Textverarbeitung Spezielle Befundschreibung für den Augenarzt: aus der Patientenkartei können Daten direkt in die Befundschreibung übernommen werden, Layoutgestaltung mit Einbindung des Ordinations-Logos usw.. Umfangreiche Karteiführung: Interaktive Karteizeile!, Filterfunktion, Karteiübersicht mit den wichtigsten Hinweisen, Datenfilter, Verwalten von Befunden, Wartelistensystem Honorarnotenabrechnung"
            />
             <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Ärztezentrum"
            title2=""
            paket="Standardpaket"
            info="Das Standardpaket für den Wahlarzt:

            Patientenadministration: Stammdatenverwaltung inkl. Zusatzversicherung, Formularprogramm für Kostenrückerstattung, Kostenlose Einbindung Private Gesundheitsakte Befundkarte,
            Diagnoseerfassung: Individuelles Kürzelsystem
            Leistungserfassung: Kassentarife und individuelle Leistungserfassung, Patienten können mehreren Kassen zugeordnet werden z.B. Privat Leistung auf Basis einer kleinen Kasse bzw. eine reine Private Leistung, Statistik, Honorarnotenverwaltung: Individuelle Honorargestaltung mit Logoeinbindung Tagesjournal, offene Postenliste,Mahnung usw...
            Formularverwaltung und Rezeptieren:
            Medikamentendatenbank, Magistralterverwaltung, Grüne,Gelbe,Rote Box Medikamente, Suchtgiftrezepte, Medikamentenplan für Patienten
            Textverarbeitung/ Befundschreibung: Aus der Patientenkartei können Daten direkt in die Befundschreibung übernommen werden, Layoutgestaltung mit Einbindung des Ordinations-Logos usw..
            Umfangreiche Karteiführung: Interaktive Karteizeile!, Filterfunktion, Karteideckel mit den wichtigsten Hinweisen, Impfverwaltung, Datenfilter, Verwalten von Befunden, Wartelistensystem
            Zentrales Anmeldungssystem:
            EOSWIN Modul Ärztezentrum"
            />
            <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Kerato/Refraktometer"
            title2=""
            paket="Modul"
            info=""
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Tonometer (Non Contact)"
            title2=""
            paket="Modul"
            info=""
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="e-card Modul elektronische Krankmeldung"
            title2=""
            paket="Modul"
            info="Der Arzt kann die elektronische Krankmeldung direkt aus dem Ordinationsprogramm EOSWIN in das e-card-Netz versenden. Per Mausklick bekommt der Patient seine Bestätigung ausgedruckt. Sämtliche Daten werden automatisch in der Patientendatei dokumentiert."
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="e-card Softwaremodul ABS"
            title2=""
            paket="Modul"
            info="Das Arzneimittel-Bewilligungs-Service (ABS) Softwaremodul dient zur Übermittlung von Bewilligungsanfragen über die eCard-Infrastruktur. Die Vorraussetzung für die direkte Anwendung des ABS Systems aus der Ordinationssoftware ist die Vollintegration der e-card (e-card Connectorsoftware)."
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Gesichtsfeld-Perimeter"
            title2=""
            paket="Modul"
            info=""
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Autorefraktometer"
            title2=""
            paket="Modul"
            info=""
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Auto-Phoropter"
            title2=""
            paket="Modul"
            info=""
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Telefoneinbindung"
            title2=""
            paket="Modul"
            info="Ihre Telefonanlage und Ihre Ordinationssoftware ergänzen einander perfekt: Bei Anruf eines Patienten ersparen Sie sich die Eingabe seines Namens. Sie können sofort die Patientenkartei aufrufen und somit Ihren Ordinationsablauf entscheidend beschleunigen. Die technische Voraussetzungen müssen gegeben sein und werden von unseren Techniker vorab kostenlos abgeklärt."
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Lensmeter"
            title2=""
            paket="Modul"
            info=""
            />
                   <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Zentrale Anmeldung (Ärztezentrum)"
            title2=""
            paket="Modul"
            info="Dieses Softwaremodul wurde speziell für Ärztezentren entwickelt, mit zentraler Anmeldung. Mit diesem Programm kann eine Assistentin einfach und schnell in mehreren Ordinationsprogrammen gleichzeitig arbeiten."
            />
            <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Video Spaltlampe"
            title2=""
            paket="Modul"
            info=""
            />
            <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Ultraschall Diagnose Systeme"
            title2=""
            paket="Modul"
            info=""
            />
            <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Sehzeichenprojektor"
            title2=""
            paket="Modul"
            info=""
            />
            <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Multiordinationsmodul"
            title2=""
            paket="Modul"
            info=""
            />
            <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Gruppenpraxismodul"
            title2=""
            paket="Modul"
            info="Die Statisik ermittelt die Anzahl der Krankenscheine(Verordnungsschein) von Zuweisungen anderer Internisten zu bestimmten Untersuchungen zB: Duplexsonographie, Echokardiographie, Doppler-Duplexsonographie,...

            Es erfolgt eine Auflistung der entsprechenden Patienten/Krankenscheine und die zugehörigen Leistungen
            - Fehlerhafte Eintragungen zB: Verechnung zusätzlicher Leistung zu solchen Fällen oder Krankenscheine ohne Leistung werden als Fehler gekennzeichnet
            
            Zusammenfassend wird auch die Anzahl der Untersuchung/Leistung in den Entsprechenden Fällen ausgegeben"
            />
               <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Scannersystem (Hard u. Software)"
            title2=""
            paket="Modul"
            info="Unterschiedlichste Befunde können Patientenbezogen direkt in Ihrer medizinischen Kartei gespeichert und exakt zugeordnet werden. Dieses rasche Ablagesystem spart eine raumfüllende Papierablage. EOSWIN Einbindung + Scannersoftware und Canon Hochleistungsscanner DR 2080C"
            />
               <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle GDX"
            title2=""
            paket="Modul"
            info=""
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Arztpraxis-Terminbuch"
            title2=""
            paket="Modul"
            info="Das Arztpraxis-Terminbuch beinhaltet folgende Funktionen:

            * Tagesplaner mit Kalender
            * Termindetails bearbeiten
            * Liste aller Termine eines Patienten
            * Terminsuche nach freien Ärzten und Räumen
            * Wochenpläne
            * Urlaubspläne
            * mehrere Wartezimmer
            * Konfiguration durch den Anwender
            * Anpassung an die Ordination"
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle ENDOTHEL Microskop"
            title2=""
            paket="Modul"
            info=""
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle FUNDUS Kamera"
            title2=""
            paket="Modul"
            info=""
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle OCT"
            title2=""
            paket="Modul"
            info=""
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle Orbis"
            title2=""
            paket="Modul"
            info=""
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Kontaktlinsenverwaltung"
            title2=""
            paket="Modul"
            info=""
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Spracheingabe"
            title2=""
            paket="Modul"
            info="Das Softwaremodul Spracheingabe besteht aus:

            * einem Integrationsmodul für die Spracherkennung
            * einem Schnittstellenprogramm zur EOSWIN Software
            * der Diktiersoftware Dragon
            * einem hochwertigen, geräuschunterdrückenden Headset
            * einer kostenlosen Einschulung in unserem Schulungscenter"
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Softwareschnittstelle zu MedNanny"
            title2=""
            paket="Modul"
            info="In periodischen Abständen (empfohlen 1 x pro Tag) werden alle Patienten aus der Ordinationssoftware EOSWIN in das Programm MedNanny exportiert. Die Terminverwaltung findet ausschließlich im MedNanny Programm statt."
            />
                           <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Registrierkassen Software - Basis"
            title2=""
            paket="Modul"
            info="Software für die Registrier und Belegerteilungspflicht ab 01.01.2016. Die erstellten Daten(Rechnungen / Honorarnoten) sind unveränderbar und die Belegausgabe ist mit einer Nummerierung vorgesehen die eine leichte Kontrolle der Vollständigkeit ermöglicht. Eine technische Manipulationssicherung ist vorgesehen. Die Erlöse werden eingebucht, also registriert, diese Eingabe erfolgt chronologisch.
            Die MCW-Registrierkassensoftware ist modulartig aufgebaut und kann mit Quittungsdrucker (Bon Drucker) Etikettendrucker und Barcodescanner kombiniert werden. Die MCW-Registrierkassen Software ist ein Softwareprodukt von MCW und somit 100% kompatibel zu dem bestehenden Ordinationssoftwareprogramm.
            
            Im Basisprogramm enthalten:
            
            - Eigener Belegkreis für registrierpflichtige Barumsätze .
            - Tagesabschluss der erbrachten Leistungen und Artikel
            - Aufschlüsselung nach Zahlungsart und Steuersätzen
            - Monatsabschluss.
            - Export des Protokolls auf einen externen Datenträger.
            - Quittung laut Belegerteilungsverpflichtung"
            />
                         <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Digitale Unterschrift (Signatur)"
            title2=""
            paket="Modul"
            info="Da die Dokumentations- und Aufklärungspflicht des Arztes eine immer wichtigere Rolle spielt, ist MCW auch in diesem Bereich Vorreiter und hat die digitale Signatur für Aufklärungsblätter und andere Formulare verwirklicht. Die unschlagbaren Pluspunkte:

            - Aufnahme der Schreibcharakteristik (Biometrie) der Unterschrift mittels Signatur-Pad
            - Speicherung der Daten in einem PDF/A-Dokument
            - Authentifizierung des Unterzeichners durch die Biometrie
            - Schutz gegen Manipulation durch ein eigenes Zertifikat
            - sehr hoher Beweiswert
            - nutzbar auch für Verträge"
            />
                                  <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Schnittstelle ELDA"
            title2=""
            paket="Modul"
            info="elektronische Versand der Abrechnungsdaten
            Die Installation erfolgt über Fernwartung.
            Eine halbe Arbeitsstunde ist inkludiert.
            Jede benötigte weitere Arbeitsstunde wird zum günstigen Wartungspaketstundensatz von netto 87,-- Euro verrechnet."
            />
                                  <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Erweiterte Kontrolliste der Abrechnung"
            title2=""
            paket="Modul"
            info=""
            />
                                  <Funktion1 
            img="./icon/Opthalmologie_Icon.png"
            title1="EOSWIN Wahlarztabrechnung Online"
            title2=""
            paket="Modul"
            info="Mit diesem Softwaremodul hat der Arzt die Möglichkeit die bezahlten Honorare elektronisch an die Gebietskrankenkasse online zu übermitteln als Service für den Patienten."
            />

            
        </div>
    )
}

export default EosOpthalmologie;
