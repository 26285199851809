import React from 'react';
import { useHistory } from 'react-router-dom';
import "./HomeSliderMobileXL.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';

function HomeSliderMobileXL() {

    const history = useHistory();

    return (
        <div className="home_slider_mobile_xl">
            <img src="./img/Background_Mobile.jpg" alt="mediziner-software"/>
            <div className="home_slider_text">
            <h1>
                Software für Ärzte seit 1987.
            </h1>
            <p>
                Premium Ordinationssoftware 
                made in Austria.
            </p>
            </div>
            <div className="home_slider_slider_component">
        <h4>Unsere Produkte:</h4>
        <div className="home_slider_slider">
        <React.Fragment>
            <Swiper id="main" spaceBetween={80} slidesPerView={3}>
                 <SwiperSlide key={`slider-1`}>
                     <div className="slider_component1" onClick={() => history.push("/eos")}>
                     <img src ="./img/Allgemeinmedizin_Icon.png" alt="Allgemeinmedizin-Softwarelösung" />
                     <p>Komplettlösungen <br/> <span>  Algemeinmedizin</span></p>
                     </div>
               </SwiperSlide>
               <SwiperSlide key={`slider-1`}>
                     <div className="slider_component1" onClick={() => history.push("/dental")}>
                     <img src ="./img/Zahnarzt_Icon.png" alt="Zahnarztlösung"/>
                     <p>Komplettlösungen <br/> <span> Dental</span></p>
                     </div>
               </SwiperSlide>
               <SwiperSlide key={`slider-1`}>
                     <div className="slider_component1" onClick={() => history.push("/eos")}>
                     <img src ="./img/Facharzt_Icon.png" alt="Facharzt-Lösungen-Ordination"/>
                     <p>Komplettlösungen <br/> <span> Facharzt</span></p>
                     </div>
               </SwiperSlide>
               <SwiperSlide key={`slider-1`}>
                     <div className="slider_component1" onClick={() => history.push("/termion")}>
                     <img src ="./img/Therapeut_Icon.png" alt="Therapeut-Computersystem"/>
                     <p>Ordinationssoftware <br/> <span> Therapie & Alternativ</span></p>
                     </div>
               </SwiperSlide>
               <SwiperSlide key={`slider-1`}>
                     <div className="slider_component1" onClick={() => history.push("/termion")}>
                     <img src ="./img/Buchungs_Icon.png" alt="Patientenbuchung"/>
                     <p>Online Buchung <br/> <span> für Ihre Patienten</span></p>
                     </div>
               </SwiperSlide>
               <SwiperSlide key={`slider-1`}>
                     <div className="slider_component1" onClick={() => history.push("/eos")}>
                     <img src ="./img/Kalender_Icon.png" alt="Terminkalender-Ordination"/>
                     <p>Online-Terminkalender <br/> <span> für Ihre Ordination</span></p>
                     </div>
               </SwiperSlide>
               <SwiperSlide key={`slider-1`}>
                     <div className="slider_component1" onClick={() => history.push("/eos")}>
                     <img src ="./img/Telemedizin_Icon.png" alt="Videosprechstunde-Ärzte"/>
                     <p>Telemedizin<br/> <span> Ihre Videosprechstunde</span></p>
                     </div>
               </SwiperSlide>
               <SwiperSlide key={`slider-1`}>
                     <div className="slider_component1" onClick={() => history.push("/shop")}>
                     <img src ="./img/Zusatzmodule_Icon.png" alt="Erweiterungs-Software"/>
                     <p>Zusatzpakete<br/> <span> und Hardware</span></p>
                     </div>
               </SwiperSlide>
               <SwiperSlide key={`slider-1`}>
                     <div className="slider_end" >
                     </div>
               </SwiperSlide>
            </Swiper>
        </React.Fragment>
        </div>
            </div>
           
        </div>
    )
}

export default HomeSliderMobileXL
