import './App.css';
import Header from './Header';
import Home from './Home';
import ThankYou from './ThankYou';
import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import EosHome from './Eos/EosHome';
import KontaktBestätigung from './Kontaktformulare/KontaktBestätigung';
import DentalHome from './Dental/DentalHome';
import BookingDocHome from './BookingDoc/BookingDocHome';
import TelemedizinHome from './Telemedizin/TelemedizinHome';
import TermionHome from './Termion/TermionHome';
import ServiceCenter from './ServiceCenter/ServiceCenter';
import Team from './ServiceCenter/Team';
import Shop from './Shop/Shop';
import Kontaktformular1 from './Kontaktformulare/Kontaktformular1';
import Warenkorb from './Shop/Warenkorb';
import Impressum from './FooterElements/Impressum';
import Agbs from './FooterElements/Agbs';
import Datenschutz from './FooterElements/Datenschutz';
import Footer from './FooterElements/Footer';
import KontaktformularSpeed from './Kontaktformulare/KontaktformularSpeed';
import KontaktformularVideo from './Kontaktformulare/KontaktformularVideo';
import ScrollToTop from './ScrollToTop';
import Ecard from './Kontaktformulare/Ecard';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import TerminBuchen from './TerminBuchen';
import Anfrage from './Anfrage';
import Events from './Events';
import EventsDashboard from './EventsDashboard';
import SignUpSignIn from './SignUpSignIn'; // Ersetzen Sie diesen Pfad durch den tatsächlichen Pfad zur SignUpSignIn-Komponente
import { AuthProvider } from './authContext';
import { useAuth } from './authContext'; // Importieren Sie useAuth von authContext




function AppContent() {

  const { currentUser } = useAuth(); // Verwenden Sie den useAuth Hook, um den aktuellen Benutzer abzurufen

  return (
    <AuthProvider>
    <div className="app">
      <Router>
        <ScrollToTop />
        <Header />
        <Route render={({location}) => (
          <TransitionGroup>
            <CSSTransition
              key={location.pathname}
              timeout={300}
              classNames="fade"
            >
        <Switch location={location}>
          <Route path="/eos">
            <div>
            <EosHome />
            <Footer />
            </div>
          </Route>
          <Route path="/ecard">
            <div>
            <Ecard />
            <Footer />
            </div>
          </Route>
          <Route path="/termin-buchen">
            <div>
              <TerminBuchen />
            <Footer />
            </div>
          </Route>
          <Route path="/events">
            <div>
              <Events />
            <Footer />
            </div>
          </Route>
          <Route path="/events-dashboard">
                    {
                      currentUser ? (
                        <div>
                          <EventsDashboard />
                          <Footer />
                        </div>
                      ) : (
                        <Redirect to="/signup" />
                      )
                    }
                  </Route>

                  <Route path="/signup">
                    <SignUpSignIn />
                  </Route>
          <Route path="/dental">
            <div>
            <DentalHome />
            <Footer />
            </div>
          </Route>
          <Route path="/anfrage">
            <div>
            <Anfrage />
            <Footer />
            </div>
          </Route>
          <Route path="/team">
            <div>
            <Team />
            <Footer />
            </div>
          </Route>
          <Route path="/danke">
            <div>
            <ThankYou />
            <Footer />
            </div>
          </Route>
          <Route path="/termion">
            <div>
            <TermionHome />
            <Footer />
            </div>
          </Route>
          <Route path="/ticket">
            <div>
            <KontaktformularSpeed />
            <Footer />
            </div>
          </Route>
          <Route path="/bestätigung">
            <div>
            <KontaktBestätigung />
            <Footer />
            </div>
          </Route>
          <Route path="/telemedizin">
            <div>
            <TelemedizinHome />
            <Footer />
            </div>
          </Route>
         
          <Route path="/bookingdoc">
            <div>
            <BookingDocHome />
            <Footer />
            </div>
          </Route>
          <Route path="/shop">
            <div>
            <Shop />
            <Footer />
            </div>
          </Route>
      
          <Route path="/service">
            <div>
            <ServiceCenter />
            <Footer />
            </div>
          </Route>
          <Route path="/kontakt">
            <div>
            <Kontaktformular1 />
            <Footer />
            </div>
          </Route>
          <Route path="/impressum">
            <div>
            <Impressum />
            <Footer />
            </div>
          </Route>
          <Route path="/agb">
            <div>
            <Agbs />
            <Footer />
            </div>
          </Route>
          <Route path="/datenschutz">
            <div>
            <Datenschutz />
            <Footer />
            </div>
          </Route>
          <Route path="/">
            <div>
            <Home />
            <Footer />
            </div>
          </Route>
        </Switch>
        </CSSTransition>
          </TransitionGroup>
        )}/>
      </Router>
    </div>
    </AuthProvider>

  );
}

function App() {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
}


export default App;
