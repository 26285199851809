import React from 'react';
import Funktion from '../Funktion';
import "./EosFunktionen.css";

function EosFunktionen() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2> Allgemeine Funktionen & Möglichkeiten</h2>
            </div>
           
            <Funktion 
            img="./icon/Befund_Icon.png"
            title1="Formularsystem"
            title2=""
            paket="Standardpaket"
            info="Mit EOSWin können Sie sämtliche Kassenformulare erstellen und verwalten."
            feature1="Formulare erstellen und verwalten"
            feature2="Das Logo der Arztpraxis kann privaten Formularen hinzugefügt werden"
            feature3="Sämtliche Kassenformulare sind im Standard-Paket enthalten"
            />
             <Funktion 
            img="./icon/Karteisystem1_Icon.png"
            title1="Karteisystem"
            title2=""
            paket="Standardpaket"
            info="Interaktive Karteizeile"
            feature1="Individuelles Karteireitersystem"
            feature2="Grafiken mit Einzeichnungsmöglichkeiten direkt in der Patientenkartei"
            feature3="Zahlreiche Filterfunktionen"
            />
             <Funktion 
            img="./icon/Wartelisten_Icon.png"
            title1="Wartelistenfunktion"
            title2=""
            paket="Standardpaket"
            info="Mit der EOS-Wartelistenfunktion bieten wir Ihnen eine übersichtliche Darstellung mehrerer Wartelisten mit vielen Funktionen, wie z.B. Akut-Wartelisten."
            feature1="Übersichtliche Darstellung"
            feature2="Laborwarteliste"
            feature3="Umreihungsmöglichkeit"
            />
             <Funktion 
            img="./icon/Statistik_Icon.png"
            title1="Statistik"
            title2=""
            paket="Standardpaket"
            info="Mit der EOS Statistik-Funktion können Sie Tagesprotokolle, Leistungsstatistiken und Diagnosen hervorragend anzeigen und auswerten lassen."
            feature1="Tagesprotokolle"
            feature2="Leistungsstatistik"
            feature3="Patientenstatistik"
            />
             <Funktion 
            img="./icon/Medikamente_Icon.png"
            title1="Medikamentenverschreibung"
            title2=""
            paket="Standardpaket"
            info="Das smarte Medikamentenverschreibungs-Tool von EOS ermöglicht Ihnen die Suche nach Inhaltsstoffen, Symptomen Allergien und vielem mehr."
            feature1="Kompletter Medikamentenstamm, inklusive Spezialitätenverzeichnis"
            feature2="ÖKO-Liste"
            feature3="Suchen nach Inhaltsstoffen & Symptomen"
            />

            <Funktion 
            img="./icon/Labor_Icon.png"
            title1="Labormodul"
            title2=""
            paket="Standardpaket"
            info="Mit dem EOS-Labormodul können Sie Laborwerte verwalten, Leistungen zuordnen und vieles mehr."
            feature1="Erfassung und Verwaltung von Laborwerten"
            feature2="Laborbefundausdruck"
            feature3="Leistungs- und Befundszuordnung"
            />
              <Funktion 
            img="./icon/Schreiben_Icon.png"
            title1="Textverarbeitung"
            title2=""
            paket="Standardpaket"
            info="Mit deer EOS-Textverarbeitung können Sie neue Befunde kinderleicht erstellen und bearbeiten."
            feature1="Integrierte Textbearbeitung mit Übernahme der Daten aus der medizinischen Kartei"
            feature2="Keine Doppeleingaben nötig"
            feature3="Befundversand per Mailbox Medical Net mit automatischer Bereitstellung in der Befundversandliste"
            />
             <Funktion 
            img="./icon/Honorarnote_Icon.png"
            title1="Abrechnungsdurchführung"
            title2=""
            paket="Standardpaket"
            info="Führen Sie Ihre Abrechnungen direkt mit EOSWin durch"
            feature1="ELDA- und Wahlarztabrechnung"
            feature2="Wahlarztabrechnung"
            feature3="Zahlscheindruck"
            />
             <Funktion 
            img="./icon/Bankomat_Icon.png"
            title1="Bankomateinbindung"
            title2=""
            paket="Zusatzpaket"
            info=" Die MCW Bankomat-Einbindung macht es möglich, dass Zahlungen und Honorarnoten sofort in Ihrer internen Honorarverrechnung aufscheinen - Sie haben jederzeit den aktuellen Überblick!"
            feature1="ELDA- und Wahlarztabrechnung"
            feature2="Wahlarztabrechnung"
            feature3="Zahlscheindruck"
            />

        </div>
    )
}

export default EosFunktionen;
