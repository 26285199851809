import React from 'react';
import { useHistory } from 'react-router-dom';
import './ServiceCenter.css';
import file from '../ServiceCenter/FWartung.exe';
import {Helmet} from "react-helmet";

function ServiceCenter() {
  const history = useHistory();

  return (
    <div className="service_center">
              <Helmet>
            <title>Service Center</title>
            <meta name="description" content="Willkommen im Service Center von MCW. Hier können Sie mit Ihrem Service-Mitarbeiter in Kontakt treten falls Sie Hilfe benötigen oder Fragen zu unseren Produkten haben." />
        </Helmet>
      <div className="service_header">
        <h1>
          Willkommen im <br />
          Service Center von MCW.
        </h1>
        <p>Wie können wir Ihnen weiterhelfen?</p>
      </div>
      <div className="service_formulare">
        <div className="formular_service_container">
          <div
            className="formular_box1"
            onClick={() => history.push('/kontakt')}
          >
            <img src="./img/invitation.png" alt="invitation-icon" />
          </div>
          <div className="formular_text">
            <h4>Kontaktformular</h4>
            <p>
              Bei Fragen zu unseren Produkten, Bestellungen oder anderen
              Anregungen.
            </p>
          </div>
        </div>
        <div className="formular_service_container">
          <div
            className="formular_box1"
            onClick={() => history.push('/ticket')}
            id="speed_ticket"
          >
            <img src="./img/tickets.svg" alt="tickets-icon" />
          </div>
          <div className="formular_text">
            <h4>24h Speed Ticket</h4>
            <p>
              Schnellstmögliche interne Bearbeitung Mo-Fr 08-18h. Für
              Abrechnungen, Notfälle und Service
            </p>
          </div>
        </div>
        <div className="formular_service_container">
          <div
            className="formular_box1"
            id="fernwartung"
            onClick={() => history.push('/anfrage')}
          >
            <img src="./img/customer_care.png" alt="customer-care-icon" />
          </div>
          <div className="formular_text">
            <h4>Beratungstermin</h4>
            <p>
              Nutzen Sie unseren Online-Terminkalender um einen Beratungstermin zu buchen.
            </p>
          </div>
        </div>
      </div>

      <div className="service_header">
        <h1>Unsere Telefonhotline:</h1>
        <p>So erreichen sie uns telefonisch während der Öffnungszeiten.</p>
      </div>
      <div className="service_formulare">
        <div className="formular_service_container1">
          <div className="formular_box2" id="hotline_box">
            <img src="./img/man.svg" alt="man-icon" />
          </div>
          <div className="formular_text">
            <h4>Technischer Support</h4>
            <p>
              Unsere Technik-Hotline erreichen sie werktags von 8:00h - 16:30h
            </p>
            <h3>+43 1 6675 665-0</h3>
          </div>
        </div>
        <div className="formular_service_container1">
          <div className="formular_box2" id="hotline_box">
            <img src="./img/shopping-cart.svg" alt="cart-icon" />
          </div>
          <div className="formular_text">
            <h4>Verkauf und Beratung</h4>
            <p>
              Bei Fragen zu unseren Produkten, Bestellungen oder anderen
              Anregungen.
            </p>
            <h3>+43 1 585 1100</h3>
          </div>
        </div>
        <div className="formular_service_container1">
          <div className="formular_box2" id="hotline_box">
            <img src="./img/modern-house.svg" alt="house-icon" />
          </div>
          <div className="formular_text">
            <h4>MCW Zentrale Kärnten</h4>
            <p>Der Ansprechpartner für MCW Kunden aus dem Süden Österreichs.</p>
            <h3>+43 4350 20187-0</h3>
          </div>
        </div>
      </div>

      <div className="service_header">
        <h1>Fernwartung</h1>
        <p>
          Mit Hilfe der Fernwartungssoftware kann Ihnen unsere technische
          Supportabteilung <br />
          den bestmöglichen Support aus der Ferne gewähren.
        </p>
      </div>
      <div className="service_formulare">
        <div className="formular_service_container">
          <div className="formular_box1" id="service_downloads">
            <a href={file} download="FWartung_1.exe">
              <img
                id="imgFWartung"
                src="./img/windows_logo.png"
                alt="windows logo"
              />
            </a>
          </div>
          <div className="formular_text">
            <h4>Download für Windows/PC</h4>
            <p>
              Hier können sie die Fernwartungs-Software für Windows/PC
              Computersysteme herunterladen.
            </p>
          </div>
          <div className="platzhalter"></div>
        </div>
        {/* <div className="formular_service_container">
            <div className="formular_box1" id="service_downloads" >
            <img src="./img/apple_logo.png" alt="apple logo"/>
            </div>
            <div className="formular_text">
            <h4>Download für Mac/Apple</h4>
            <p>Hier können sie die Fernwartungs-Software für Mac/Apple Computersysteme herunterladen.</p>
            </div>
  
        </div>   */}
      </div>
    </div>
  );
}

export default ServiceCenter;
