import React from 'react'
import './HomeInfo7.css'

function HomeInfo7() {
  return (
    <div className="home_info_7">
{/*       <h3>Was unsere Kunden sagen:</h3>
 */}        <div className="home_info_7_container">
        <div className="home_info_7_box" id="box_1">
          <div className="home_info_7_mobile">
        <p>Dr. Sylvia Stein-Krumholz <br/>
      <span>Kinderärztin aus Wien</span> </p>
      </div>
          <h4>Was unsere KundInnen sagen:</h4>
        <h3>„Mit TermiOn kann ich meine PatientInnen kinderleicht
          verwalten, und direkt online Buchungen annehmen.
           Das erspart meinen AssistentInnen viel Zeit für wichtigere Arbeit.“</h3>
           <div className="home_info_7_desktop">
           <p>Dr. Sylvia Stein-Krumholz <br/>
      <span>Kinderärztin aus Wien</span> 
           </p>
           </div>

        </div>
        <div className="home_info_7_font">
        </div>
        </div>
    </div>
  )
}

export default HomeInfo7