import React, {useState} from 'react';
import HomeInfo1 from '../HomeInfo1';
import HomeInfo2 from '../HomeInfo2';
import Kontakt from '../Kontakt';
import EosFunktionen from './EosFunktionen';
import EosWahlarzt from "./EosWahlarzt";
import EosHeader from './EosHeader';
import EosRadiologie from './EosRadiologie';
import EosChirurgie from './EosChirurgie';
import EosOpthalmologie from "./EosOpthalmoligie";
import EosNeurologie from "./EosNeurologie";
import EosInterne from "./EosInterne";
import EosUrologie from "./EosUrologie";
import EosGynäkologie from "./EosGynäkologie";
import EosDermatologie from "./EosDermatologie";
import "./EosHome.css";
import EosHNO from './EosHNO';
import EosPulmologie from './EosPulmologie';
import EosOrthopädie from './EosOrthopädie';
import EosPädiatrie from './EosPädiatrie';
import {Helmet} from "react-helmet";
import VideoBanner from '../VideoBanner';
import OfferList1 from '../OfferList1';

function EosHome() {

    const [active, setActive] = useState("Wahlarzt");

    return (
        <div className="eos_home">

        <Helmet>
            <title>EosWin - Software für Allgemeinmediziner & Fachärzte</title>
            <meta name="description" content="EOS bietet alle Features, die Sie brauchen, um Ihre Ordination zu einem Selbstläufer zu machen. Seit 1988 konnte sich EOS in zahlreichen Ordinationen in ganz Österreich bewähren - hoffentlich auch bald in Ihrer? Kontaktieren Sie uns noch heute für eine kostenlose Beratung!" />
        </Helmet>

            <EosHeader />
          
            <div className="eos_top">
           <nav>
           <button onClick ={() => setActive("Allgemein")}>Allgemein</button>
               <button onClick ={() => setActive("Wahlarzt")}>Wahlarzt</button>
               <button onClick ={() => setActive("Radiologie")}>Radiologie</button>
               <button onClick ={() => setActive("Chirurgie")}>Chirurgie</button>
               <button onClick ={() => setActive("Opthalmologie")}>Opthalmologie</button>
               <button onClick ={() => setActive("Interne")}>Interne</button>
               <button onClick ={() => setActive("Neurologie")}>Neurologie</button>
               <button onClick ={() => setActive("Urologie")}>Urologie</button>
               <button onClick ={() => setActive("Gynäkologie")}>Gynäkologie</button>
               <button onClick ={() => setActive("HNO")}>HNO</button> 
               <button onClick ={() => setActive("Pulmologie")}>Pulmologie</button>
               <button onClick ={() => setActive("Orthopädie")}>Orthopädie</button>
               <button onClick ={() => setActive("Pädiatrie")}>Pädiatrie</button>
               <button onClick ={() => setActive("Dermatologie")}>Dermatologie</button>
           </nav>
        </div>
            
            <div>
            {active === "Allgemein" && <EosFunktionen />}
            {active === "Wahlarzt" && <EosWahlarzt />}
            {active === "Radiologie" && <EosRadiologie />}
            {active === "Chirurgie" && <EosChirurgie />}
            {active === "Opthalmologie" && <EosOpthalmologie />}
            {active === "Interne" && <EosInterne />}
            {active === "Neurologie" && <EosNeurologie />}
            {active === "Urologie" && <EosUrologie />}
            {active === "Gynäkologie" && <EosGynäkologie />}
            {active === "HNO" && <EosHNO />}
            {active === "Pulmologie" && <EosPulmologie />}
            {active === "Orthopädie" && <EosOrthopädie />}
            {active === "Pädiatrie" && <EosPädiatrie />}
            {active === "Dermatologie" && <EosDermatologie />}
            </div>
            
            <HomeInfo1 
            title="EOS - Bestens bewährt seit über 30 Jahren."
            textbig="Auf unsere Software ist einfach Verlass."
            text="EOS bietet alle Features, die Sie brauchen, um Ihre Ordination zu einem Selbstläufer zu machen. Seit 1988 konnte sich EOS in zahlreichen Ordinationen in ganz Österreich bewähren - hoffentlich auch bald in Ihrer? Kontaktieren Sie uns noch heute für eine kostenlose Beratung!
            "
            button="Beratung vereinbaren"
            img="./img/Eos_Win_Vintage.jpg"
            url="/anfrage"
            />

            <HomeInfo2 
            title="Die neue EOS Schnittstelle zu TermiOn."
            textbig="Der smarte Online-Terminkalender funktioniert nun mit Eos."
            text="Jetzt ist TermiOn, der smarte Online-Terminkalender für Ordinationen, auch für EOS erhältlich. 
            Durch den Einsatz unserer bidirektionalen Schnittstelle wird die Kommunikation mit dem neuesten Terminkalender am Markt problemlos ermöglicht."
            button="Termin vereinbaren" 
            img="./img/MCW_Ben_Termion.jpg"
            url="/anfrage"
            />
            <VideoBanner />
            <Kontakt />
        </div>
    )
}

export default EosHome;
