import React from 'react';
import {Link} from "react-router-dom";
import "./EosHeader.css";

function EosHeader() {
    return (
        <div className="eos_header">
             <img id ="desktop_pic" src="./img/Eos_Win_Pic.jpg" alt=""/>
             <img id="mobile_pic" src="./img/MCW_Mobile_Background.jpg" alt=""/>

            <div className="eos_textbar">
                <img src="./img/Eos_Logo.png" alt="Eos Logo" />
                <p>Die Software für Fachärzte & Allgemeinmediziner</p> 
                <h1>
                   Zuverlässig <br/> seit vielen Jahren
                </h1>
                <Link className="button_link_header" to="/kontakt">Beratung vereinbaren</Link>
            </div>
        </div>
    )
}
export default EosHeader
