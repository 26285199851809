import React from 'react'
import './VideoBanner.css'
import {Link} from "react-router-dom";


function VideoBanner2() {
  return (
    <div className="video_banner_2">
        <div className="video_banner_desktop"> 
        <video loop autoPlay muted playsInline className='video_banner'>
    <source src="./img/mcw_team_video.mp4" type="video/mp4"/>
        </video>
        </div>
        <div className="video_banner_mobile">
        <video loop autoPlay muted playsInline className='video_banner_mobile'>
    <source src="./img/mcw_messe_mobile.mp4" type="video/mp4"/>
        </video>
        </div>
    </div>
  )
}

export default VideoBanner2


