import React from 'react';
import Funktion from '../Funktion';
import "./TermionFunktionen.css";

function TermionFunktionen() {
    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2> Funktionen & Möglichkeiten</h2>
            </div>
            <Funktion 
            img="./img/Facharzt_Icon.png"
            title1="Termine und Patienten"
            title2="einfach online verwalten"
            paket="Standardpaket"
            info="Unser elektronischer Terminplaner Termion hilft Ihnen dabei, den Alltag in Ihrer Ordination zu meistern. Egal ob Sie Fach- oder AllgemeinmedizinerIn sind - Termion passt sich automatisch an Ihre Wünschen an. "
            feature1="Patienten und Termine kinderleicht verwalten"
            feature2="Termine Intern & Online Buchen"
            feature3="Einfach zu bedienen"
            />
               <Funktion 
            img="./img/Telemedizin_Icon.png"
            title1="Intelligente Online-Buchung"
            title2="für Ihre Patienten"
            paket="Standardpaket"
            info="Ihre Patienten buchen unkompliziert online, Termion regelt den Rest. Termions intelligente Algorithmen automatisieren den Buchungsprozess und entlasten Sie und ihre Ordination."
            feature1="Interne Terminvergabe"
            feature2="Online-Buchung für Ihre Patienten"
            feature3="Terminverwaltung für Sie und Ihre AssistentInnen"
            />
               <Funktion 
            img="./img/Zusatzmodule_Icon.png"
            title1="Modernes Design"
            title2="und einfache Bedienung"
            paket="Standardpaket"
            info="Termion verbindet höchste Funktionalität mit benutzerfreundlichem Design. Ihr Kalender kann auf jedem führenden Betriebssystem aufgerufen werden und passt sich automatisch an, sobald die Software mobil oder mit einem Tablet verwendet wird. "
            feature1="Windows/PC und Apple/Mac Kompatibel"
            feature2="Bedienung über Tablets und iPad möglich"
            feature3="Homeoffice tauglich"
            />
               <Funktion 
            img="./icon/Integrations_Icon.png"
            title1="Erweiterungen"
            title2="und Zusatzfeatures"
            paket="Zusatzpaket"
            info=" Für Termion stehen Ihnen zahlreiche Zusatzfeatures zur Verfügung. Die Software wurde gemeinsam mit Ärzten und Therapeuten entwickelt und stetig verbessert. Für mehr Informationen kontaktieren Sie unser Team."
            feature1="Stetige Weiterentwicklung"
            feature2="Zusatzfunktionen optional"
            feature3="14 Tage kostenlos testen"
            />
            
            
        </div>
    )
}

export default TermionFunktionen;
