import React from 'react'
import './TerminBuchen.css'

function TerminBuchen() {
  return (
    <div className="iFrame_Box">
        <div className="anfrage_left">
        <div className="anfrage_top">
            <img className="anfrage_image" src="./img/messe1.jpg" alt="logo_top"/>
        </div>
      </div>
      <div className="iframe_right">
        <h2>Termin buchen</h2>
        <iframe src='https://mcw.termion.at/Api/Booking/22599?mitarbeitercode=2259999ec039c-efb7-4ea2-8772-184e6bb71c84&color=314b81&colorhover=232A57&fontcolor=ffffff&fontcolorhover=ffffff' style={{ minHeight: '650px', border: '0', minWidth: '500px', width: '100%' }}
></iframe>
      </div>

</div>
  )
}

export default TerminBuchen