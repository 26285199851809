import React, { useState, useEffect, useRef } from "react";
import firebase from './firebaseConfig';
import './Events.css';
import './Anfrage.css';
import lottie from 'lottie-web';
import emailjs from 'emailjs-com';


const Events = () => {
  const [step, setStep] = useState(1);
  const [data, setData] = useState({
    timeSlot: "",
    ordinationName: "",
    contactPerson: "",
    email: "",
    phone: "",
    participants: 1
  });
  const [errorMessage, setErrorMessage] = useState("");

  const topRef = useRef(null); // Referenz für das Scrollen

  const handleDataChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  };

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  const isValidForm = () => {
    if(!data.timeSlot || !data.ordinationName || !data.contactPerson || !data.email || !data.phone || data.participants <= 0) {
      return false;
    }
    return true;
  }

  const handleSubmit = () => {
    if (!isValidForm()) {
      setErrorMessage("Bitte füllen Sie alle Felder aus, bevor Sie fortfahren.");
      return;
    }

    if (data.participants <= 0) {
      setErrorMessage("Bitte geben Sie eine gültige Anzahl von Teilnehmern an.");
      return;
    }

    const currentDataWithTimestamp = {
      ...data,
      createdAt: firebase.database.ServerValue.TIMESTAMP // Hinzufügen des createdAt Timestamps
    };

    setErrorMessage("");
    
    // Datenbank schreiben
    firebase.database().ref("appointments").push(currentDataWithTimestamp)
      .then(() => {
        setStep(4);
        scrollToTop();

        // Daten für die Email.js Vorlage definieren
        const templateParams = {
            email: data.email,
            name: data.contactPerson,
            participants: data.participants,
            timeslot: data.timeSlot,
        };

        emailjs.send('service_elmo65l', 'template_paekyxp', templateParams, 'fEOSRiLOjVkQPpaLp')
          .then((response) => {
            console.log('E-Mail erfolgreich gesendet!', response.status, response.text);
          }, (error) => {
            console.error('E-Mail Senden fehlgeschlagen...', error);
          });
      })
      .catch(error => {
          // Hier können Sie Fehler behandeln, wenn es ein Problem beim Schreiben in die Datenbank gibt.
          console.error('Firebase-Datenbankfehler: ', error);
      });
};



  useEffect(() => {
    if (step === 4) {
      const animation = lottie.loadAnimation({
          container: document.getElementById('animation'),
          renderer: 'svg',
          loop: true,
          autoplay: true,
          path: 'img/animation.json'
      });
      return () => animation.destroy();
    }
  }, [step]);

  return (
    <div className="events" ref={topRef}>
      {step === 1 && (
        <div className="introduction">
            <div className="anfrage">
           <div className="anfrage_left">
        <div className="anfrage_top">
            <img className="anfrage_image" src="./img/Cyber_MCW.jpg" alt="logo_top"/>
        </div>
        </div>
        <div className="anfrage_right">
            <p className="event-date-head">Exklusive Einladung</p>
        <h2>IT-Sicherheitstag in Zusammenarbeit mit Securepoint</h2>
        <h5>Am 11. Oktober laden wir Sie herzlich zu unserem MCW IT-Sicherheitstag gemeinsam mit Securepoint ein. Nutzen Sie diese Gelegenheit, Ihr Fachwissen zu vertiefen, wertvolle Kontakte zu knüpfen und bei Getränken und Snacks auszutauschen.</h5>
        <div className="anfrage_features">
            <div className="anfrage_feature">
                <img className="anfrage_check_1" src="./img/kalender.svg" alt="" />
                <p>Mittwoch, 11. Oktober - Esterhazygasse 23, 1060 Wien</p>
            </div>
            <div className="anfrage_feature">
                <img className="anfrage_check_1" src="./img/medal.svg" alt="" />
                <p>Kostenloses Teilnahmezertifikat</p>
            </div>
            <div className="anfrage_feature">
                <img className="anfrage_check_1" src="./img/presentation.svg" alt="" />
                <p>Vortrag um 10:00 oder 17:00 Uhr</p>
            </div>
            <div className="anfrage_feature">
                <img className="anfrage_check_1" src="./img/clock.svg" alt="" />
                <p>Dauer des Vortrags: 45 Minuten</p>
            </div>
        </div>
        <div className="anfrage_bottom">
        <button className="anfrage_button_alt" onClick={() => { setStep(2); scrollToTop(); }}>Weiter</button>
        </div>
        <img className="anfrage_logos" src="./img/securepoint.jpg" alt="logos"/>
        </div>
        </div>
        </div>
      )}

      {step === 2 && (
        <div className="time-selection">
            <h2>Wann dürfen wir Sie willkommen heißen?</h2>
            <p>Wählen Sie bitte einen der beiden angebotenen Zeitfenster aus.</p>
          <div
            className={`time-box ${data.timeSlot === "Vormittags" ? "selected" : ""}`}
            onClick={() => setData({ ...data, timeSlot: "Vormittags" })}
          >
            <div className="termin-box">
                <div>
                <p>Vormittag</p>
            <h4>10:00-12:00h</h4>
                </div>
                <div>
                <h5 className="time-date">11.10.2023</h5>
                </div>
            </div>
          </div>
          <div
            className={`time-box ${data.timeSlot === "Nachmittags" ? "selected" : ""}`}
            onClick={() => setData({ ...data, timeSlot: "Nachmittags" })}
          >
  <div className="termin-box">
                <div>
                <p>Nachmittag</p>
            <h4>17:00-19:00h</h4>
                </div>
                <div>
                <h5 className="time-date">11.10.2023</h5>
                </div>
            </div>
          </div>
          <div className="button_box">
          {data.timeSlot && <button className="anfrage_button_alt" onClick={() => { setStep(3); scrollToTop(); }}>Weiter</button>}
          </div>
        </div>
      )}

      {step === 3 && (
      <div className="time-selection">
          <h2>Noch ein paar finale Details</h2>
          <p>Nur noch wenige Informationen und Ihre Buchung ist perfekt!</p>

      <div className="input-group">
      <input id="ordinationName" required name="ordinationName" placeholder="Name der Ordination" onChange={handleDataChange} />
      </div>
  
      <div className="input-group">
          <input id="contactPerson" required name="contactPerson" placeholder="Ansprechpartner" onChange={handleDataChange} />
      </div>
  
      <div className="input-group">
          <input id="email" name="email" type="email" required placeholder="Email" onChange={handleDataChange} />
      </div>
  
      <div className="input-group">
          <input id="phone" name="phone" placeholder="Telefon" required onChange={handleDataChange} />
      </div>
      <div className="input-group">
  <label htmlFor="participants">Anzahl der Teilnehmer</label>
  <input 
    id="participants" 
    name="participants" 
    type="number" 
    min="1"
    value={data.participants}
    onChange={handleDataChange} 
  />
</div>
<div className="button-container">
{errorMessage && <p className="error-message">{errorMessage}</p>}
<button className="anfrage_button_alt" onClick={handleSubmit}>Abschließen</button>
    </div>
  </div>
  
      )}

      {step === 4 && 
      <div className="time-selection">
          <div id="animation" className="lottie" style={{width: 300, height: 300}}></div> 
          <h2>Buchung erfolgreich</h2>
          <p>Sie erhalten in Kürze eine Bestätigung per Email.</p>
      
      </div>}
    </div>
  );
};

export default Events;
