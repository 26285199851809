import React from 'react';
import Funktion from '../Funktion';
import "./EosFunktionen.css";

function EosWahlarzt() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2>Eos für Wahlärzte</h2>
            </div>
            <Funktion 
            img="./img/Facharzt_Icon.png"
            title1="Patienten administration"
            title2=""
            paket="Standardpaket"
            info="Mit EOSWin können Sie kinderleicht Ihre PatientInnen online verwalten. Das vereinfacht Ihren Ordinationsalltag - behalten Sie mit einem Klick den Überblick."
            feature1="Stammdatenverwaltung inkl. Zusatzversicherung"
            feature2="Formularprogramm für Kostenrückerstattung"
            feature3="Kostenlose Einbindung der Befundkarte"
            />
               <Funktion 
            img="./icon/Honorarnote_Icon.png"
            title1="Honorarnotenverwaltung"
            title2=""
            paket="Standardpaket"
            info="Mit EOSWin können Sie Honorarnoten mit Ihrem eigenen Logo kreieren, offene Posten verwalten und Mahnungen erstellen"
            feature1="Individuelle Honorargestaltung mit Logoeinbindung"
            feature2="Offene Postenliste"
            feature3="Mahnungen und mehr"
            />
               <Funktion 
            img="./icon/Leistungserfassung_Icon.png"
            title1="Leistungserfassung"
            title2=""
            paket="Standardpaket"
            info="Mit EOSWin können Sie Ihre Leistungen und Diagnosen erfassen. EOS erstellt außerdem Statistiken anhand der im System eingetragenen Daten."
            feature1="Kassentarife und individuelle Leistungen"
            feature2="Individuelles Kürzelsystem"
            feature3="Statistiken"
            />
               <Funktion 
            img="./icon/Befund_Icon.png"
            title1="Formularverwaltung & Rezeptieren"
            title2=""
            paket="Standardpaket"
            info="Erstellen Sie kinderleicht Rezepte und Medikamentenpläne für Ihre Patienten."
            feature1="Medikamentendatenbank"
            feature2="Medikamentenplan für Patienten erstellen"
            feature3="Magistralterverwaltung"
            />
               <Funktion 
            img="./icon/Schreiben_Icon.png"
            title1="Befunde schreiben"
            title2=""
            paket="Standardpaket"
            info="Schreiben Sie Ihre Befunde direkt in EOS und veredeln Sie diese mit Ihrem eigenen Logo."
            feature1="Befunde schreiben und Texte verarbeiten"
            feature2="Layoutgestaltung mit Einbindung des Ordinationslogos"
            feature3="Daten können aus der Patientenkartei direkt in die Befundschreibung übernommen werden"
            />
             <Funktion 
            img="./icon/Karteiführung_Icon.png"
            title1="Karteiführung"
            title2=""
            paket="Standardpaket"
            info="Genießen Sie die Effizienz einer umfangreichen Karteiführung in Ihrer Ordination. Von Filterfunktionen bis Wartlistensysteme, EOS bietet Ihnen zahlreiche Möglichkeiten Ihre Ordination auf das nächste Level zu bringen."
            feature1="Interaktive Karteizeile"
            feature2="Datenfilter"
            feature3="Impfverwaltung & mehr"
            />
            
        </div>
    )
}

export default EosWahlarzt;
