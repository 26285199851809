import React from 'react';
import {Link} from "react-router-dom"
import "./HomeProduct.css";
import CheckIcon from '@material-ui/icons/Check';


function HomeProduct({img, title, logo, check_eins, check_zwei, check_drei, button, url, isUrlExternal}) {


    return (
        <div className="home_product">
                  <div className="home_product_front">
                  <img src={img} alt="home-product-front"/>
            <h4>{title}</h4>
                  </div>
                    <div className="home_product_back">
                        <img src={logo} alt="home-product-logo"/>
                    <div className="checkbox">
                    <CheckIcon />
                    <p>{check_eins}</p>
                </div>
                <div className="checkbox">
                    <CheckIcon />
                    <p>{check_zwei}</p>
                </div>
                <div className="checkbox">
                    <CheckIcon />
                    <p>{check_drei}</p>
                </div>
                {
                !isUrlExternal 
                ? <Link className="link_button" to={url}>{button} </Link> 
                : <a className="link_button" href={url} target="_blank" rel="noreferrer">{button}</a>
            
            } 
                    </div>
  
        
        </div>


    )
}


    
export default HomeProduct;

