import React from 'react';
import {Link} from "react-router-dom";
import "./HomeInfo1.css"

function HomeInfo1({title, textbig, text, button,img, url}) {
    return (
        <div className="home_info">
            <div className="home_info_container">
        <div className="home_info_left">
        <h2>{title}</h2>
        <h4>{textbig}</h4>
        <p>{text}</p>
        <Link className="link_button" to={url}>{button}</Link> 
        </div>
        <div className="home_info_right">
            <img src={img} alt="home-info-foto-1"/>
        </div>
            </div>
        </div>
    )
}

export default HomeInfo1;
