import React from 'react'
import './HomeInfo8.css'

function HomeInfo8() {
  return (
    <div className="home_info_8">
        <h4>Weitere Funktionen:</h4>
        <div className="home_info_8_wrapper">
        <div className="home_info_8_box">
        <img src="./img/contract-2.svg" alt="" />
        <h3>Erinnerungs SMS und e-mails</h3>
        </div>
        <div className="home_info_8_box">
        <img src="./img/design.svg" alt="" />
        <h3>Individuelles Formular-Design</h3>
        </div>
        <div className="home_info_8_box">
        <img src="./img/computer-3.svg" alt="" />
        <h3>Kompatibel für Mac und Windows</h3>
        </div>
        <div className="home_info_8_box">
        <img src="./img/insurance.svg" alt="" />
        <h3>Sicher und DSGVO-konform</h3>
        </div>
        </div>
    </div>
  )
}

export default HomeInfo8