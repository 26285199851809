import React, { useEffect }  from 'react';
import "./TeamBanner.css";
import { Link } from 'react-router-dom';



function TeamBanner() {

  

    useEffect(() => {
      const meineKomponenten = Array.from(document.querySelectorAll('.team_banner_mitarbeiter'));
  
      function checkScroll() {
        const windowHeight = window.innerHeight;
        const windowMiddle = windowHeight / 2;
        const minOpacity = 0.3;
  
        meineKomponenten.forEach(komponente => {
          if (komponente.classList.contains('focused')) {
            return;
          }
  
          const rect = komponente.getBoundingClientRect();
          const elementMiddle = rect.top + rect.height / 2;
  
          const distanceFromMiddle = Math.abs(windowMiddle - elementMiddle);
  
          const maxDistance = windowHeight / 2;
          const factor = Math.max(minOpacity, 1 - Math.min(distanceFromMiddle / maxDistance, 1));
  
          komponente.style.opacity = factor;
  
          // Anstatt zu prüfen, ob factor genau 1 ist, prüfen wir, ob es nahe genug an 1 ist
          if (Math.abs(1 - factor) < 0.01) {
            komponente.classList.add('focused');
          }
        });
      }
  
      checkScroll(); // einmal aufrufen um die Opazität beim Laden der Seite zu setzen
      window.addEventListener('scroll', checkScroll);
  
      // Funktion zur Bereinigung der Komponente, wenn sie unmountet
      return () => {
        window.removeEventListener('scroll', checkScroll);
      };
  
    }, []); // Leeres Array bedeutet, dass dieser Hook nur einmal nach dem ersten Rendern ausgeführt wird.
  

  return (
    <div class="team_banner_home">

        <div class="team_banner_home_top">
        <h2>Unser Verkaufsteam</h2>
        <p>Das Verkaufsteam erstellt Lösungen, die auf Ihre individuellen Bedürfnisse zugeschnitten sind, während unser Supportteam rund um die Uhr bereitsteht, um technische Fragen zu beantworten und sicherzustellen, dass Ihre Software stets effizient arbeitet. </p>
        </div>

        <div class="team_banner_home_bottom">

             <div class="team_banner_home_bottom_box">


                    <h3>Verkaufsteam Wien & Umgebung</h3>
                    <h4>Wien | Niederösterreich | Burgenland | Oberösterreich</h4>
                    <div className="team_banner_mitarbeiter">
                        <div class="team_banner_mitarbeiter_left">
                            <img src="./img/team/Wagner.png" alt="" />
                            <p>
                                Jürgen Wagner <br/> 
                                <span>Verkauf & Schulungen</span>
                            </p>
                        </div>
                        <div class="team_banner_mitarbeiter_right">
                        <a href="tel:+43 667 5665-0" className="team_banner_button1" to="/kontakt" id="verkaufs_button">  
        <img class="button_image"src="./img/telephone.svg" alt="" />
 <p>0676 846639 27</p></a>                        <a href="mailto:jwagner@mcw.at">   <img class="button_image_2"src="./img/mail.svg" alt="" /> jwagner@mcw.at </a>
                        </div>
                    </div>


                    <div className="team_banner_mitarbeiter">
                        <div class="team_banner_mitarbeiter_left">
                            <img src="./img/team/Nikola.png" alt="" />
                            <p>
                                Nikola Petkovic <br/> 
                                <span>Techn. Verkauf & Technik</span>
                            </p>
                        </div>
                        <div class="team_banner_mitarbeiter_right">
                        <a href="tel:+43 667 5665-0" className="team_banner_button1" to="/kontakt" id="verkaufs_button">  
        <img class="button_image"src="./img/telephone.svg" alt="" />
 <p>01-667 5665-0</p></a>                        <a href="mailto:npetkovic@mcw.at">   <img class="button_image_2"src="./img/mail.svg" alt="" /> npetkovic@mcw.at </a>
                        </div>
                    </div>
                
              </div>

              <div class="team_banner_home_bottom_box">


<h3>Verkaufsteam Österreich Mitte</h3>
<h4>Kärnten | Steiermark | Salzburg | Vorarlberg | Tirol</h4>

<div className="team_banner_mitarbeiter">
                        <div class="team_banner_mitarbeiter_left">
                            <img src="./img/team/Silvia.png" alt="" />
                            <p>
                                Silvia Buchsbaum <br/> 
                                <span>Hotline & Verkauf</span>
                            </p>
                        </div>
                        <div class="team_banner_mitarbeiter_right">
                        <a href="tel:+43 667 5665-0" className="team_banner_button1" to="/kontakt" id="verkaufs_button">  
        <img class="button_image"src="./img/telephone.svg" alt="" />
 <p>01-667 5665-0</p></a>                        <a href="mailto:sbuchsbaum@mcw.at">   <img class="button_image_2"src="./img/mail.svg" alt="" /> sbuchsbaum@mcw.at </a>
                        </div>
                    </div>

                    <div className="team_banner_mitarbeiter">
                        <div class="team_banner_mitarbeiter_left">
                            <img src="./img/team/Elke.png" alt="" />
                            <p>
                                Elke Keller <br/> 
                                <span>Hotline & Verkauf</span>
                            </p>
                        </div>
                        <div class="team_banner_mitarbeiter_right">
                        <a href="tel:+43 667 5665-0" className="team_banner_button1" to="/kontakt" id="verkaufs_button">  
        <img class="button_image"src="./img/telephone.svg" alt="" />
 <p>0676-8466-3922</p></a>                        <a href="mailto:ekeller@mcw.at">   <img class="button_image_2"src="./img/mail.svg" alt="" /> ekeller@mcw.at </a>
                        </div>
                    </div>



</div>


        </div>
        <div class="team_banner_bottom_bottom">
        <h3>Mehr von MCW? <br /></h3>
        <p>Hier gehts zum gesamten Team</p>
        <Link className="home_team_button" to="/team">  
 <p>Das MCW Team</p></Link>  
        </div>



    </div>
  )
}

export default TeamBanner