import React from 'react'
import './Anfrage.css';
import { Link } from 'react-router-dom';

function Anfrage() {
  return (
    <div className="anfrage">
        <div className="anfrage_left">
        <div className="anfrage_top">
            <img className="anfrage_image" src="./img/messe1.jpg" alt="logo_top"/>
        </div>
        </div>
        <div className="anfrage_right">
            <p>Jetzt neu</p>
        <h2>Buchen Sie ihren Beratungstermin</h2>
        <h5>Wir stehen Ihnen jederzeit beratend zur Seite – ob direkt in Ihrer Ordination oder bei uns in unseren Headquarters in Wien und Kärnten. Gerne bieten wir auch telefonische Beratungen an.</h5>
        <div className="anfrage_features">
            <div className="anfrage_feature">
                <img className="anfrage_check" src="./img/check.png" alt="" />
                <p>Telefon, Video oder vor Ort</p>
            </div>
            <div className="anfrage_feature">
                <img className="anfrage_check" src="./img/check.png" alt="" />
                <p>Terminverfügbarkeit prüfen</p>
            </div>
            <div className="anfrage_feature">
                <img className="anfrage_check" src="./img/check.png" alt="" />
                <p>Unverbindlich & kostenlos</p>
            </div>
        </div>
        <div className="anfrage_bottom">
            <Link className="anfrage_button" to="/termin-buchen">
                <img src="./img/calendar.svg" alt="kalender"/>
                Jetzt Termin Buchen
                </Link>
            <Link className="anfrage_kontakt_link" to="/kontakt">Über das Kontaktformular anfragen</Link>
        </div>
        <img className="anfrage_logos" src="./img/MCW_Logos.png" alt="logos"/>
        </div>
    </div>
  )
}

export default Anfrage