import React from 'react';
import Funktion1 from '../Funktion1';
import Funktion from '../Funktion';
import "./DentalFunktionen.css";

function DentalFunktionen() {
    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2> Funktionen & Möglichkeiten</h2>
            </div>
            <Funktion 
            img="./img/Facharzt_Icon.png"
            title1="Verwaltung"
            title2="von Patientenstammdaten und Terminen"
            paket="Standardpaket"
            info="Mit DentalPro können Sie kinderleicht Ihre PatientInnen & Termine verwalten"
            feature1="Patienten kinderleicht verwalten"
            feature2="Rechnungen ausdrucken"
            feature3="DSGVO-konform"
            />
               <Funktion 
            img="./icon/Zahnschema_Icon.png"
            title1="Zahnschema"
            title2="in zwei verschiedenen Varianten anzeigen"
            paket="Standardpaket"
            info="DentalPro erlaubt es Ihnen das Zahnschema in zwei Varianten anzuzeigen. Sie können zwischen Naturalansicht oder oder einer schematischen Darstellung auswählen."
            feature1="Naturalansicht"
            feature2="Schematische Darstellung"
            feature3="Leicht zu bedienen"
            />
               <Funktion1 
            img="./icon/Ecard_Icon.png"
            title1="Vollintegration der e-Card"
            title2="und deren Verwaltung"
            paket="Standardpaket"
            info="In DentalPro ist die e-Card bereits komplett Integriert."
              />
               <Funktion1
            img="./img/Allgemeinmedizin_Icon.png"
            title1="Medizinische Patientenkartei"
            title2="mit Behandlungsübersicht & Statistikwesen"
            paket="Zusatzpaket"
            info="Mit DentalPro können Sie von Ihren Patienten eine komplette Patientenkartei erstellen inkl. Behandlungsübersicht"
            />

            <Funktion1
            img="./icon/Dentalbrief_Icon.png"
            title1="Honorarnoten und andere Formulare"
            title2="kinderleicht erstellen"
            paket="Zusatzpaket"
            info="Mit DentalPro können Sie kinderleicht Honorarnoten und andere Formulare erstellen"
            />


            
        </div>
    )
}

export default DentalFunktionen;
