import React from 'react';
import "./Impressum.css";

function Impressum() {
    return (
        <div className="impressum">
            <div className="impressum_text">
                <h1>
                    Impressum:
                </h1>
            <h3>
            MCW Handelsgesellschaft m.b.H.
            </h3>
            <p>
Sitz: Wien <br />
Geschäftsanschrift: 1230 Wien, Breuninggasse 6 <br />
Zweigniederlassung: 1060 Wien, Esterhazygasse 23 <br />
<br />
Tel: +43-(0)1-6675 665-0 <br />
Fax: +43-(0)1-6675 665-22 <br />
E-Mail: service@mcw.at <br />
<br />
Firmenbuch: FN 55115b <br />
Firmenbuchgericht: Handelsgericht Wien <br />
Behörde gem. ECG: Magistratisches Bezirksamt des XXIII. Bezirkes <br />
DVR: 0578304 <br />
UID: ATU15130604 <br />
Geschäftsführer: <br />
Johann Propst <br />
Edwin Skorepa <br />
<br />
Zugehörigkeit gem. §5 Abs.1 Z6 ECG 
Wirtschaftskammer Österreich <br />
Fachgruppe: Landesgremium Wien für den Handel mit Computern und Bürosystemen
Fachgruppe: Fachgruppe Unternehmensberatung und Informationstechnologie Wien
Zusätzliche Informationen zur Fachgruppe finden sie online Wirtschaftskammer Österreich
Wir unterliegen den gesetzlichen Vorschriften der Gewerbeordnung. Diese und andere österreichische Gesetze können unter www.ris.bka.gv.at kostenlos abgerufen werden.
            </p>

            <div className="platzhalter">
 
            </div>
            </div>
        </div>
    )
}

export default Impressum
