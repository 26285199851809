import React, { createContext, useContext, useState, useEffect } from 'react';
import firebase from './firebaseConfig'; // or wherever your firebase config is

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(user => {
        setCurrentUser(user);
    });

    return () => unsubscribe();  // Cleanup subscription on unmount
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, setCurrentUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
