import React from 'react';
import Funktion1 from '../Funktion1';
import "./EosFunktionen.css";

function EosInterne() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2>Eos für Internisten</h2>
            </div>

          <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Innere Medizin (alle Kassen)"
            title2=""
            paket="Standardpaket"
            info="Bei Internisten sammeln sich Unmengen von Befunden aus Laborgeräten an. Steigen Sie um von der umständlichen Handkartei auf das elektronische Ordinationssystem. Es gibt keine einfachere und bessere Lösung als die unterschiedlichen Befunde in diesem modernen Computersystem zu bearbeiten.
            Onlineanschlüsse: Das System kann an Untersuchungsgeräte angeschlossen werden wie Labor, Ultraschall, EKG, Endoskopie, Kolposkopie etc. Werte, Bilder und Diagramme werden dabei direkt in den WINWORD-Befund übernommen und ausgedruckt. Das ermöglicht eine übersichtliche und vergleichende Darstellung von Krankheitsbildern. Scanner: Für die einzelnen Patienten können unterschiedlichste Befunde gespeichert und exakt zugeordnet werden. Sie ersparen sich die komplette, raumfüllende Fremdbefundablage. Mailbox: Befunde von Krankenhäusern oder Labors können via Telefonleitung direkt in Ihre EDV-Kartei übertragen werden. Umfangreiches Angebot an fertigen Textdokumenten speziell für den Internisten wie Befundvorschläge, Operationsfreigaben etc. Abrechnung aller Kassen."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Wahlarzt Innere Medizin"
            title2=""
            paket="Standardpaket"
            info="Bei Internisten sammeln sich Unmengen von Befunden aus Laborgeräten an. Steigen Sie um von der umständlichen Handkartei auf das elektronische Ordinationssystem. Es gibt keine einfachere und bessere Lösung als die unterschiedlichen Befunde in diesem modernen Computersystem zu bearbeiten.
            Onlineanschlüsse: Das System kann an Untersuchungsgeräte angeschlossen werden wie Labor, Ultraschall, EKG, Endoskopie, Kolposkopie etc. Werte, Bilder und Diagramme werden dabei direkt in den WINWORD-Befund übernommen und ausgedruckt. Das ermöglicht eine übersichtliche und vergleichende Darstellung von Krankheitsbildern. Scanner: Für die einzelnen Patienten können unterschiedlichste Befunde gespeichert und exakt zugeordnet werden. Sie ersparen sich die komplette, raumfüllende Fremdbefundablage. Mailbox: Befunde von Krankenhäusern oder Labors können via Telefonleitung direkt in Ihre EDV-Kartei übertragen werden. Umfangreiches Angebot an fertigen Textdokumenten speziell für den Internisten wie Befundvorschläge, Operationsfreigaben. Abrechnungssystem über Honorarnoten."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Ärztezentrum"
            title2=""
            paket="Standardpaket"
            info="Das Standardpaket für den Wahlarzt:
            Patientenadministration: Stammdatenverwaltung inkl. Zusatzversicherung, Formularprogramm für Kostenrückerstattung, Kostenlose Einbindung Private Gesundheitsakte Befundkarte,
            Diagnoseerfassung: Individuelles Kürzelsystem
            Leistungserfassung: Kassentarife und individuelle Leistungserfassung, Patienten können mehreren Kassen zugeordnet werden z.B. Privat Leistung auf Basis einer kleinen Kasse bzw. eine reine Private Leistung, Statistik, Honorarnotenverwaltung: Individuelle Honorargestaltung mit Logoeinbindung Tagesjournal, offene Postenliste,Mahnung usw...
            Formularverwaltung und Rezeptieren:
            Medikamentendatenbank, Magistralterverwaltung, Grüne,Gelbe,Rote Box Medikamente, Suchtgiftrezepte, Medikamentenplan für Patienten
            Textverarbeitung/ Befundschreibung: Aus der Patientenkartei können Daten direkt in die Befundschreibung übernommen werden, Layoutgestaltung mit Einbindung des Ordinations-Logos usw..
            Umfangreiche Karteiführung: Interaktive Karteizeile!, Filterfunktion, Karteideckel mit den wichtigsten Hinweisen, Impfverwaltung, Datenfilter, Verwalten von Befunden, Wartelistensystem
            Zentrales Anmeldungssystem:
            EOSWIN Modul Ärztezentrum"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Wahlarzt -Wartungsvariante"
            title2=""
            paket="Modul"
            info="
            Patientenadministration, Karteiführung, Diagnosen und Honorarverwaltung, Interaktionsprüfung, Medikamentenverschreibung, Ökonomie-Tool, Befundschreibung, Formularsystem, Dokumentationssystem, Honorarnotenverwaltung"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="e-card Softwaremodul VU Versand"
            title2=""
            paket="Modul"
            info="Mit unserem Ordinationsprogramm EOSWIN können Sie die elektronische Vorsorgeuntersuchung direkt in das e-card-Netz versenden. Selbstverständlich werden sämtliche Daten automatisch in der Patientendatei dokumentiert."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="e-card Softwaremodul VU Versand (Koloskopie)"
            title2=""
            paket="Modul"
            info="e-card Softwaremodul VU Versand (Koloskopie) für EOSWIN Ordinationssoftware"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="e-card Softwaremodul ABS"
            title2=""
            paket="Modul"
            info="Das Arzneimittel-Bewilligungs-Service (ABS) Softwaremodul dient zur Übermittlung von Bewilligungsanfragen über die eCard-Infrastruktur. Die Vorraussetzung für die direkte Anwendung des ABS Systems aus der Ordinationssoftware ist die Vollintegration der e-card (e-card Connectorsoftware)."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="e-card Modul elektronische Krankmeldung"
            title2=""
            paket="Modul"
            info="Der Arzt kann die elektronische Krankmeldung direkt aus dem Ordinationsprogramm EOSWIN in das e-card-Netz versenden. Per Mausklick bekommt der Patient seine Bestätigung ausgedruckt. Sämtliche Daten werden automatisch in der Patientendatei dokumentiert."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Elektronische Laborüberweisung"
            title2=""
            paket="Modul"
            info="EOSWIN Softwaremodul Elektronische Laborüberweisung"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Telefoneinbindung"
            title2=""
            paket="Modul"
            info="Ihre Telefonanlage und Ihre Ordinationssoftware ergänzen einander perfekt: Bei Anruf eines Patienten ersparen Sie sich die Eingabe seines Namens. Sie können sofort die Patientenkartei aufrufen und somit Ihren Ordinationsablauf entscheidend beschleunigen. Die technische Voraussetzungen müssen gegeben sein und werden von unseren Techniker vorab kostenlos abgeklärt."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Zentrale Anmeldung (Ärztezentrum)"
            title2=""
            paket="Modul"
            info="Dieses Softwaremodul wurde speziell für Ärztezentren entwickelt, mit zentraler Anmeldung. Mit diesem Programm kann eine Assistentin einfach und schnell in mehreren Ordinationsprogrammen gleichzeitig arbeiten."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Fotodokumentation - Wireless"
            title2=""
            paket="Modul"
            info="Fotodokumentation auf Knopfdruck Übertragen Sie Fotos direkt in Ihre elektronisch geführte Patientenkartei - ohne Stick und ohne Kabel!
            Jeder Arzt weiß, wie praktisch Patientenfotos sind: Damit können alle Behandlungen genauestens dokumentiert werden, wovon nicht nur der Arzt, sondern auch der Patient profitiert.
            Doch so sinnvoll Fotos in der Praxis auch sind, so mühsam gestaltete sich bisher die Fotodokumentation: Um die Fotos zu kopieren, musste die Kamera zuerst per Kabel oder Stick mit dem Computer verbunden werden. Danach mussten die Fotos in einem gut durchdachten Ablagesystem integriert werden, damit man sie auch später wiederfindet. Es war also notwendig, Ordner zu schaffen und Bilddateien händisch zu übertragen - ein nicht selten mühsamer und zeitaufwändiger Prozess!
            Doch damit ist es jetzt vorbei: Ein neuer Chip ermöglicht es ab sofort, die Fotos per Knopfdruck direkt in die Patientenkartei zu überspielen - ohne Stick und ohne Kabel! Sobald man den Auslöseknopf der Kamera drückt, sorgt eine spezielle MCW-Software dafür, dass das Bild direkt dort landet, wo es hin gehört. Das mühsame Anschließen an den Computer entfällt ebenso, wie das zeitaufwändige Suchen und Ordnen der Bilddateien. Für den Zahnarzt eine wesentliche Arbeitserleichterung und Zeitersparnis!
            Dabei ist die Anschaffung einer neuen Kamera in der Regel nicht erforderlich. Es ist nur notwendig, dass Ihre Kamera und der neue Chip kompatibel sind.
            Modul besteht aus: DOKU-Software; SDHC-Chipcarte; Softwareschnittstelle und WLAN Router"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="DICOM Worklist- Export"
            title2=""
            paket="Modul"
            info="Beinhaltet Dicom Worklist und Dicom Arichv (PACS))"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Labormodul (Eigenlabor)"
            title2=""
            paket="Modul"
            info="Onlineanschlüsse: Das EOS-WIN System kann an Untersuchungsgeräten online angeschlossen werden wie z.B. Laborgeräte oder EKG etc. Werte,Bilder und Diagramme können dabei direkt in einen z.B WINWORD-Befund übernommen und ausgedruckt werden. Das ermöglicht eine rasche und lückenlose Dokumentation und eine übersichtliche und vergleichende Darstellung von Krankeitsbildern."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Einbindung Fremdlabor"
            title2=""
            paket="Modul"
            info="Automatische Zuordnung von Laborbefunden zum Patienten, Kennzeichnung der pathologischen Werte, automatische Verrechnung Möglichkeit bei Laborgemeinschaften, Empfangslisten. Voraussetzung: Befundempfang durch einen entsprechenden Provider"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Modul VU Abrechnung für den Wahlarzt"
            title2=""
            paket="Modul"
            info="Elektronische Abrechnung Vorsorgeuntersuchung für den Wahlarzt"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN EKG Einbindung"
            title2=""
            paket="Modul"
            info="Bei MCW können alle gängigen EKG-Geräte, die mit entsprechenden EDV-Schnittstellen ausgestattet sind, ins Ordinationssystem voll integriert werden. Sie haben die Möglichkeit, alle EKG-Auswertungen schnell und unkompliziert einzusehen und zentral zu dokumentieren."
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Anweisung zur histologischen Untersuchung"
            title2=""
            paket="Modul"
            info="Formularprogramm:
            Anweisung zur histologischen Untersuchung (z.B. LKH Klagenfurt, LKH Graz..) inklusive Integration des Formulars in das Ordinationssystem EOSWIN. Installation erfolgt über Fernwartung"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Scannersystem (Hard u. Software)"
            title2=""
            paket="Modul"
            info="Unterschiedlichste Befunde können Patientenbezogen direkt in Ihrer medizinischen Kartei gespeichert und exakt zugeordnet werden. Dieses rasche Ablagesystem spart eine raumfüllende Papierablage. EOSWIN Einbindung + Scannersoftware und Canon Hochleistungsscanner DR 2080C"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Terminkalender für den Internisten"
            title2=""
            paket="Modul"
            info="Beschreibung:
            * Tagesplaner mit Kalender
            * Terminsuche nach freien Ärzten und Räumen
            * Liste aller Termine eines Patienten
            * Wochenpläne
            * Urlaubspläne
            * mehrere Untersuchungsräume
            * Konfiguration durch den Anwender oder durch MCW"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Befundschreibung - Innere Medizin"
            title2=""
            paket="Modul"
            info=""
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Modul Recallsystem"
            title2=""
            paket="Modul"
            info="Kontrolltermin können mittels EOSWIN Recallsystem automatisch verwaltet werden.(Serienbrief,Bildschirmlisten,Erinnerung beim Aufruf des Patienten, usw..)"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Gruppenpraxismodul"
            title2=""
            paket="Modul"
            info="Die Statisik ermittelt die Anzahl der Krankenscheine(Verordnungsschein) von Zuweisungen anderer Internisten zu bestimmten Untersuchungen zB: Duplexsonographie, Echokardiographie, Doppler-Duplexsonographie,...
            Es erfolgt eine Auflistung der entsprechenden Patienten/Krankenscheine und die zugehörigen Leistungen
            - Fehlerhafte Eintragungen zB: Verechnung zusätzlicher Leistung zu solchen Fällen oder Krankenscheine ohne Leistung werden als Fehler gekennzeichnet
            Zusammenfassend wird auch die Anzahl der Untersuchung/Leistung in den Entsprechenden Fällen ausgegeben"
            />
                      <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="Radiant Dicom Viewer Maintenance"
            title2=""
            paket="Modul"
            info="Wartung seitens Radiant Dicom Viewer gilt für 1 Jahr"
            />
            <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Modul - Radiant Dicom-Viewer"
            title2=""
            paket="Modul"
            info=""
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Scannermodul (Software)"
            title2=""
            paket="Modul"
            info="Die Scannersoftware ermöglicht eine komfortable direkte Zuordnung der eingescannten Befunde und Dokumente in der Patientenkartei."
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Mehrarztstatistik"
            title2=""
            paket="Modul"
            info=""
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Spracheingabe"
            title2=""
            paket="Modul"
            info=""
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Spracheingabe - Wartungsgebühr"
            title2=""
            paket="Modul"
            info=""
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Softwareschnittstelle zu MedNanny"
            title2=""
            paket="Modul"
            info=""
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Registrierkassen Software - Basis"
            title2=""
            paket="Modul"
            info="Software für die Registrier und Belegerteilungspflicht ab 01.01.2016. Die erstellten Daten(Rechnungen / Honorarnoten) sind unveränderbar und die Belegausgabe ist mit einer Nummerierung vorgesehen die eine leichte Kontrolle der Vollständigkeit ermöglicht. Eine technische Manipulationssicherung ist vorgesehen. Die Erlöse werden eingebucht, also registriert, diese Eingabe erfolgt chronologisch.
            Die MCW-Registrierkassensoftware ist modulartig aufgebaut und kann mit Quittungsdrucker (Bon Drucker) Etikettendrucker und Barcodescanner kombiniert werden. Die MCW-Registrierkassen Software ist ein Softwareprodukt von MCW und somit 100% kompatibel zu dem bestehenden Ordinationssoftwareprogramm.
            
            Im Basisprogramm enthalten:
            
            - Eigener Belegkreis für registrierpflichtige Barumsätze .
            - Tagesabschluss der erbrachten Leistungen und Artikel
            - Aufschlüsselung nach Zahlungsart und Steuersätzen
            - Monatsabschluss.
            - Export des Protokolls auf einen externen Datenträger.
            - Quittung laut Belegerteilungsverpflichtung"
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOS-WIN Leistungsstatistik"
            title2=""
            paket="Modul"
            info="Der Überblick über die Leistungen Ihrer Ordination ist eine wichtige Grundlage für Planung, Organisation und Effizienzsteigerung. Darum hat MCW eine umfangreiches Statistikmodul entwickelt, das Ihnen jederzeit eine detaillierte Übersicht über den Leistungsumfang Ihrer Ordination garantiert. So haben Sie Ihr Unternehmen Ordination jederzeit im Griff!"
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN SMS Erinnerungssystem"
            title2=""
            paket="Modul"
            info="Erinnern Sie doch Ihre Patienten an anstehende Termine per SMS! Dabei erfolgen die Terminerinnerungen direkt aus dem EOSWIN-Terminkalender oder über individuell u erstellenden Abfrage-Listen mit Filterkriterien. Selbstverständlich dabei: Eine Kontroll-Liste für gesendete oder nicht gesendet SMS!
            Wichtig: Nicht jeder Handyhersteller entspricht den technischen Anforderungen des SMS-Versandes - Sprechen Sie vor dem Kauf Ihres Handys mit uns, wir überprüfen kostenlos ob Ihr Handy geeignet ist."
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Digitale Unterschrift (Signatur)"
            title2=""
            paket="Modul"
            info="Da die Dokumentations- und Aufklärungspflicht des Arztes eine immer wichtigere Rolle spielt, ist MCW auch in diesem Bereich Vorreiter und hat die digitale Signatur für Aufklärungsblätter und andere Formulare verwirklicht. Die unschlagbaren Pluspunkte:

            - Aufnahme der Schreibcharakteristik (Biometrie) der Unterschrift mittels Signatur-Pad
            - Speicherung der Daten in einem PDF/A-Dokument
            - Authentifizierung des Unterzeichners durch die Biometrie
            - Schutz gegen Manipulation durch ein eigenes Zertifikat
            - sehr hoher Beweiswert
            - nutzbar auch für Verträge"
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Labor Anforderung (Fremdlabor)"
            title2=""
            paket="Modul"
            info="Mit diesem Softwaremodul kann der Arzt elektronisch Laboranforderungen an sein Labor senden.
            Voraussetzungen: Provider Voraussetzung Medical Net bzw. DAME; HL7 Format; Softwareaktualisierung ab Programmversion EOS 2.0.0.9 möglich."
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Schnittstelle ELDA"
            title2=""
            paket="Modul"
            info="elektronische Versand der Abrechnungsdaten
            Die Installation erfolgt über Fernwartung.
            Eine halbe Arbeitsstunde ist inkludiert.
            Jede benötigte weitere Arbeitsstunde wird zum günstigen Wartungspaketstundensatz von netto 87,-- Euro verrechnet."
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Erweiterte Kontrolliste der Abrechnung"
            title2=""
            paket="Modul"
            info=""
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Benutzer-Statistik"
            title2=""
            paket="Modul"
            info="Kartei-Auswertung nach Benutzer"
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Mailversand"
            title2=""
            paket="Modul"
            info="Das Modul ermöglicht es dem Arzt, direkt aus dem EOSWIN E-Mails zu versenden."
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Wahlarztabrechnung Online"
            title2=""
            paket="Modul"
            info="Mit diesem Softwaremodul hat der Arzt die Möglichkeit die bezahlten Honorare elektronisch an die Gebietskrankenkasse online zu übermitteln als Service für den Patienten."
            />
                        <Funktion1 
            img="./icon/Interne_Icon.png"
            title1="EOSWIN Video und Bildbearbeitungmodul"
            title2=""
            paket="Modul"
            info="Bilder von Videokameras können im Computer problemlos gespeichert und jederzeit abgerufen werden. Für Arzt und Patient ergibt sich daraus eine exzellente optische Bilddokumentation."
            />

        </div>
    )
}

export default EosInterne;
