import React from 'react';
import {Link} from "react-router-dom";
import "./HomeInfo10.css";

function HomeInfo2({toptitle, title, text, button,url, img}) {
    return (
        <div className="home_info_10">
            <div className="home_info_container_10">
            <div className="home_info_left_10">
            <img src={img} alt="home-info-foto_10"/>
        </div>
        <div className="home_info_right_10">
            <div className="home_info_right_neu">
            <p>{toptitle}</p>
                </div>
        <h2>{title}</h2>
     {/*    <h4>{textbig}</h4> */}
        <p>{text}</p>
        <Link className="link_button" to={url}>{button}</Link> 
        </div>
            </div>
        </div>
    )
}

export default HomeInfo2;

