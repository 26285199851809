import React from 'react'
import './HomeInfo5.css'

function HomeInfo5({titel1, titel2, text1, text2, img1, img2, backgroundImage}) {
  return (
    <div className="home_info_5">
        <div className="home_info_5_box">
          <div className="home_info_5_text">
        <h2>
        {titel1}
        </h2>
        <h3>
        {text1}
        </h3>
        </div>
        <img src={img1} alt="" />
        </div>
        <div className="home_info_5_box" style={{ backgroundImage: `url(${backgroundImage})` }}>
        <div className="home_info_5_text">
        <h2>
        {titel2}
        </h2>
        <h3>
       {text2}
        </h3>
        </div>
        <img src={img2} alt="" />
        </div>
        </div>
  )
}

export default HomeInfo5