import React from 'react';
import Funktion from '../Funktion';
import Funktion1 from '../Funktion1';
import "./EosFunktionen.css";

function EosRadiologie() {


    return (
        <div className="eos_funktionen">
            <div className="eos_funktionen_beschreibung">
                <h2>Eos für Radiologen</h2>
            </div>
            <Funktion 
            img="./icon/Radiologie_Icon.png"
            title1="Wartungs-Software RADSOFT (basic remote)"
            title2=""
            paket="Standardpaket"
            info="Mit dem EosWin tool können sie kinderleicht ihre Patienten online verwalten. Das vereinfacht ihren Ordinationsalltag und sie behalten den Überblick über Ihre Patienten"
            feature1="Softwareupdate"
            feature2="Softwareunterstützung"
            feature3="Telefonischer Support"
            />
             <Funktion 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Telearbeitsplatz"
            title2=""
            paket="Standardpaket"
            info="Für den Fernzugriff von Mitarbeitern wird eine Zugangslösung auf Basis einer VPN-Technologie eingerichtet. Die VPN-Einwahl ist eine leistungsfähige Funktion, mit deren Hilfe aus der Ferne auf geschlossene Verwaltungsnetze zugegriffen werden kann."
            feature1="Firewall/Router für VPN-Verbindung der Standorte"
            feature2="Zugangssoftware für Telearbeitsplatz"
            feature3=" Installation und Einrichtung"
            />
             <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT DICOM Worklist- Export"
            title2=""
            paket="Standardpaket"
            info="Beinhaltet Dicom Worklist und Dicom Arichv (PACS))"
            />
            <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Schnittstelle Spracherkennung"
            title2=""
            paket="Standardpaket"
            info=""
            />
             <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Spracherkennung Basislizenz"
            title2=""
            paket="Standardpaket"
            info="Basislizenzinstallation am Server,Konfiguration, Startunterstützung, Einschulung"
            />
             <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Externe Befundschreibung"
            title2=""
            paket="Standardpaket"
            info=""
            />
            <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Bildarchiv-Monitor"
            title2=""
            paket="Standardpaket"
            info="Der Bildarchiv Monitor ermöglicht den schnellen Zugriff auf das digitale Bildarchiv, direkt aus der Radiologie Software. Auf dem Monitorfenster erscheinen alle dem einzelnen Patientenbefund zugeordneten Untersuchungen, diese können per Knopfdruck aufgerufen werden. Ein Click auf den jeweilen Untersuchungs-Button zeigt übersichtlich die hinterlegten digitalen Aufnahmen zu dieser Untersuchung."
            />
             <Funktion 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Diktierarbeitsplatz"
            title2=""
            paket="Erweiterung"
            info="Erweiterung um einen Diktierarbeitsplatz bestehend aus:"
            feature1="Philips Speech Lizenz"
            feature2="Integrationsmodul für Spracherkennung"
            feature3="Schnittstellenprogramm zur EOSWIN Radiologie Software"
            />
             <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Magic View"
            title2=""
            paket="Standardpaket"
            info=""
            />
             <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Teleradiologie-Basis"
            title2=""
            paket="Standardpaket"
            info="Diese Softwaremodul ist nur erforderlich wenn die Doppelbefundung der Mammographien nicht in der selben Ordination erfolgt.
            In diesem Fall werden die Eingabemöglichkeiten (Birads und ACR)über einen technischen Link (Internet) verschickt.
            Weiters werden die Bilddaten für den entsprechenden Bildprovider für den Versand aufbereitet. Wichtig: Das Versenden der Bilder erfolgt über einen Provider (HCS,Siemens,...)"
            />
             <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT RIS for Windows Base (alle Kassen)"
            title2=""
            paket="Standardpaket"
            info="Das Setangebot RADSOFT beinhaltet:

            Patientenverwaltung, Befundschreibung Microsoft Word, Automatische Befundübermittlung E-mail, Voruntersuchungen, Abrechnung aller Kassen, Privat-Honorarnoten (Einzel-, Sammelhonorar), Statistik, Zuweiser, Untersuchung usw.., 10 Benutzerlizenzen"
            />
            <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="RADSOFT Terminkalender"
            title2=""
            paket="Standardpaket"
            info="Gleichzeitiges Verwalten von mehreren Terminkalendern von verschiedenen Arbeitsplätzen"
            />
             <Funktion1 
            img="./icon/Radiologie_Icon.png"
            title1="Wartung Software Radiologiesystem"
            title2=""
            paket="Standardpaket"
            info="Wartung pro Monat (Unterstützung per Telefon und Fernwartung, 3 Stunden im Jahr)"
            />
            <Funktion
            img="./icon/Radiologie_Icon.png"
            title1="Wartung Software Radiologiesystem (Komplett)"
            title2=""
            paket="Standardpaket"
            info="Wartung pro Monat (Unterstützung per Telefon und Fernwartung, 3 Stunden im Jahr)"
            feature1="Softwareupdates & Unterstützung"
            feature2="Telefonischer V.I.P. Support"
            feature3="Unterstützung vor Ort inkl. Fahrtkosten, An- und Abreise, Zeitaufwand"
            />
             <Funktion
            img="./icon/Radiologie_Icon.png"
            title1="EOSWIN Registrierkassen Software - Basic"
            title2=""
            paket="Standardpaket"
            info="Software für die Registrier und Belegerteilungspflicht ab 01.01.2016. Die erstellten Daten(Rechnungen / Honorarnoten) sind unveränderbar und die Belegausgabe ist mit einer Nummerierung vorgesehen die eine leichte Kontrolle der Vollständigkeit ermöglicht. Eine technische Manipulationssicherung ist vorgesehen. Die Erlöse werden eingebucht, also registriert, diese Eingabe erfolgt chronologisch.
            Die MCW-Registrierkassensoftware ist modulartig aufgebaut und kann mit Quittungsdrucker (Bon Drucker) Etikettendrucker und Barcodescanner kombiniert werden. Die MCW-Registrierkassen Software ist ein Softwareprodukt von MCW und somit 100% kompatibel zu dem bestehenden Ordinationssoftwareprogramm."
            feature1=" Eigener Belegkreis für registrierpflichtige Barumsätz & Tagesabschluss der erbrachten Leistungen und Artikel"
            feature2="Aufschlüsselung nach Zahlungsart und Steuersätzen & Monatsabschluss"
            feature3="Export des Protokolls auf einen externen Datenträger"
            />

        </div>
    )
}

export default EosRadiologie;
