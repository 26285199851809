import React from 'react';
import "./Datenschutz.css";

function Datenschutz() {
    return (
        <div className="datenschutz">
            <div className="datenschutz_text">
            <h1>
            Datenschutzerklärung
            </h1>
            <h3>
            MCW Handelsgesellschaft m.b.H.
            </h3>
            <p>
            <h4>Datenschutz</h4> <br />
Wir haben diese Datenschutzerklärung verfasst, um Ihnen gemäß der Vorgaben der EU-Datenschutz-Grundverordnung zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben. <br />

Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar zu beschreiben. <br /> <br />

<h4>Automatische Datenspeicherung</h4> <br />
Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie die IP-Adresse Ihres Gerätes, die Adressen der besuchten Unterseiten, Details zu Ihrem Browser (z.B. Chrome, Firefox, Edge,…) und Datum sowie Uhrzeit. Wir nutzen diese Daten nicht und geben Sie in der Regel nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden. <br /> <br />

<h4>Speicherung persönlicher Daten</h4> <br />
Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben. <br /> <br />

Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden. <br /> <br />

Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln. <br /> <br />

<h4>Ihre Rechte</h4> <br />
Ihnen stehen grundsätzlich die Rechte auf Auskunft, Berichtigung, Löschung, Einschränkung, Datenübertragbarkeit, Widerruf und Widerspruch zu. Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren. In Österreich ist dies die Datenschutzbehörde, deren Webseiten Sie unter https://www.dsb.gv.at/ finden. <br /> <br />

<h4>LS-Verschlüsselung mit https </h4><br />
Wir verwenden https um Daten abhörsicher im Internet zu übertragen. Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der Verwendung des Schemas https als Teil unserer Internetadresse. <br /> <br />

<h4>Cookies</h4> <br />
Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern. <br />
Ein Cookie ist ein kurzes Datenpaket, welches zwischen Webbrowser und Webserver ausgetauscht wird, für diese aber völlig bedeutungslos ist und erst für die Webanwendung, z. B. einen Online-Shop, eine Bedeutung erhält, etwa den Inhalt eines virtuellen Warenkorbes. Es gibt zwei Arten von Cookies: Erstanbieter-Cookies werden von unserer Website erstellt, Drittanbieter-Cookies werden von anderen Websites (z. B. Google Analytics) erstellt.
Man unterscheidet drei Kategorien von Cookies: unbedingt notwendige Cookies um grundlegende Funktionen der Website sicherzustellen, funktionelle Cookies um die Leistung der Webseite sicherzustellen und zielorientierte Cookies um das Benutzererlebnis zu verbessern.
Wir nutzen Cookies, um unsere Webseite nutzerfreundlicher zu gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser beim nächsten Besuch wiederzuerkennen.
Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben.
Sie können jederzeit Cookies, die sich bereits auf Ihrem Computer befinden, löschen oder Cookies deaktivieren. Die Vorgangsweise dazu ist nach Browser unterschiedlich, am besten Sie suchen die Anleitung in Google mit dem Suchbegriff „cookies löschen chrome“ oder „cookies deaktivieren chrome“ im Falle eines Chrome Browsers oder tauschen das Wort „chrome“ gegen den Namen Ihres Browsers, z. B. edge, firefox, safari aus.
Wenn Sie uns generell nicht gestatten, Cookies zu nutzen, d.h. diese per Browsereinstellung deaktivieren, können manche Funktionen und Seiten nicht wie erwartet funktionieren.
<br /> <br />
<h4>Newsletter</h4> <br />
Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die oben genannten persönlichen Daten und geben uns das Recht Sie per E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter gespeicherten Daten nutzen wir ausschließlich für unseren Newsletter und geben diese nicht weiter.<br />

Sollten Sie sich vom Newsletter abmelden – Sie finden den Link dafür in jedem Newsletter ganz unten – dann löschen wir alle Daten die mit der Anmeldung zum Newsletter gespeichert wurden. <br /> <br />

<h4>AdRoll</h4> <br />
Diese Website verwendet die Retargeting-Technologie AdRoll (AdRoll, 972 Mission St, 3rd Floor, San Francisco, CA 94103, Vereinigte Staaten). Diese ermöglicht es, gezielt jene Internet-User mit Werbung anzusprechen, die sich bereits für unsere Produkte interessiert haben. Aus Studien wissen wir, dass die Einblendung personalisierter, interessenbezogener Werbung für den Internet User interessanter ist als Werbung, die keinen solchen persönlichen Bezug hat. Die Einblendung der Werbemittel erfolgt beim Retargeting auf der Basis einer cookie-basierten Analyse des vorherigen Nutzerverhaltens. Selbstverständlich werden auch hierbei keine personenbezogenen Daten gespeichert und ebenso selbstverständlich erfolgt die Verwendung der Retargeting-Technologie unter Beachtung der geltenden gesetzlichen Datenschutzbestimmungen. Sie können sich allgemein über die Datenschutzerklärung und die Datenschutzrichtlinien von Adroll näher informieren und der anonymen Analyse Ihres Surfverhaltens widersprechen (Opt-Out) Zur Adroll-Datenschutzerklärung. <br /> <br />

<h4>Google Maps Datenschutzerklärung</h4> <br />
Wir verwenden Google Maps der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite. <br /> <br />

<br />
Durch die Nutzung der Funktionen dieser Karte werden Daten an Google übertragen. Welche Daten von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.

<br /> <br/>
<h4>Google Fonts Datenschutzerklärung</h4> <br />
Wir verwenden Google Fonts der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA) auf unserer Webseite. Die Verwendung von Google Fonts erfolgt ohne Authentisierung und es werden keine Cookies and die Google Fonts API gesendet. Sollten Sie ein Konto bei Google haben, werden keine Ihrer Google-Kontodaten an Google während der Nutzung von Google Fonts übermittelt. Google erfasst lediglich die Nutzung von CSS und der verwendeten Fonts und speichert diese Daten sicher. Mehr zu diesen und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq. <br />

Welche Daten von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen. <br />

<br /> <h4>Google reCAPTCHA Datenschutzerklärung</h4> <br />
Unser oberstes Ziel ist es, dass unsere Webseite für Sie und für uns bestmöglich geschützt und sicher ist. Um das zu gewährleisten, verwenden wir Google reCAPTCHA der Firma Google Inc. (1600 Amphitheatre Parkway Mountain View, CA 94043, USA). Mit reCAPTCHA können wir feststellen, ob Sie auch wirklich ein Mensch aus Fleisch und Blut sind und kein Roboter oder eine andere Spam-Software. Unter Spam verstehen wir jede, auf elektronischen Weg, unerwünschte Information, die uns ungefragter Weise zukommt. Bei den klassischen CAPTCHAS mussten Sie zur Überprüfung meist Text- oder Bildrätsel lösen. Mit reCAPTCHA von Google müssen wir Sie meist nicht mit solchen Rätseln belästigen. Hier reicht es in den meisten Fällen, wenn Sie einfach ein Häkchen setzen und so bestätigen, dass Sie kein Bot sind. Mit der neuen Invisible reCAPTCHA Version müssen Sie nicht mal mehr ein Häkchen setzen. Wie das genau funktioniert und vor allem welche Daten dafür verwendet werden, erfahren Sie im Verlauf dieser Datenschutzerklärung.
<br /> <br />
<h4>Was ist reCAPTCHA?</h4> <br />
reCAPTCHA ist ein freier Captcha-Dienst von Google, der Webseiten vor Spam-Software und den Missbrauch durch nicht-menschliche Besucher schützt. Am häufigsten wird dieser Dienst verwendet, wenn Sie Formulare im Internet ausfüllen. Ein Captcha-Dienst ist ein automatischer Turing-Test, der sicherstellen soll, dass eine Handlung im Internet von einem Menschen und nicht von einem Bot vorgenommen wird. Im klassischen Turing-Test (benannt nach dem Informatiker Alan Turing) stellt ein Mensch die Unterscheidung zwischen Bot und Mensch fest. Bei Captchas übernimmt das auch der Computer bzw. ein Softwareprogramm. Klassische Captchas arbeiten mit kleinen Aufgaben, die für Menschen leicht zu lösen sind, doch für Maschinen erhebliche Schwierigkeiten aufweisen. Bei reCAPTCHA müssen Sie aktiv keine Rätsel mehr lösen. Das Tool verwendet moderne Risikotechniken, um Menschen von Bots zu unterscheiden. Hier müssen Sie nur noch das Textfeld „Ich bin kein Roboter“ ankreuzen bzw. bei Invisible reCAPTCHA ist selbst das nicht mehr nötig. Bei reCAPTCHA wird ein JavaScript-Element in den Quelltext eingebunden und dann läuft das Tool im Hintergrund und analysiert Ihr Benutzerverhalten. Aus diesen Useraktionen berechnet die Software einen sogenannten Captcha-Score. Google berechnet mit diesem Score schon vor der Captcha-Eingabe wie hoch die Wahrscheinlichkeit ist, dass Sie ein Mensch sind. ReCAPTCHA bzw. Captchas im Allgemeinen kommen immer dann zum Einsatz, wenn Bots gewisse Aktionen (wie z.B. Registrierungen, Umfragen usw.) manipulieren oder missbrauchen könnten.

Warum verwenden wir reCAPTCHA auf unserer Website? <br />
Wir wollen nur Menschen aus Fleisch und Blut auf unserer Seite begrüßen. Bots oder Spam-Software unterschiedlichster Art dürfen getrost zuhause bleiben. Darum setzen wir alle Hebel in Bewegung, uns zu schützen und die bestmögliche Benutzerfreundlichkeit für Sie anzubieten. Aus diesem Grund verwenden wir Google reCAPTCHA der Firma Google. So können wir uns ziemlich sicher sein, dass wir eine „botfreie“ Webseite bleiben. Durch die Verwendung von reCAPTCHA werden Daten an Google übermittelt, die Google verwendet, um festzustellen, ob Sie auch wirklich ein Mensch sind. reCAPTCHA dient also der Sicherheit unserer Webseite und in weiterer Folge damit auch Ihrer Sicherheit. Zum Beispiel könnte es ohne reCAPTCHA passieren, dass bei einer Registrierung ein Bot möglichst viele E-Mail-Adressen registriert, um im Anschluss Foren oder Blogs mit unerwünschten Werbeinhalten „zuzuspamen“. Mit reCAPTCHA können wir solche Botangriffe vermeiden.

Welche Daten werden von reCAPTCHA gespeichert? <br />
ReCAPTCHA sammelt personenbezogene Daten von Usern, um festzustellen, ob die Handlungen auf unserer Webseite auch wirklich von Menschen stammen. Es kann also die IP-Adresse und andere Daten, die Google für den reCAPTCHA-Dienst benötigt, an Google versendet werden. IP-Adressen werden innerhalb der Mitgliedstaaten der EU oder anderer Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum fast immer zuvor gekürzt, bevor die Daten auf einem Server in den USA landen. Die IP-Adresse wird nicht mit anderen Daten von Google kombiniert, sofern Sie nicht während der Verwendung von reCAPTCHA mit Ihrem Google-Konto angemeldet sind. Zuerst prüft der reCAPTCHA-Algorithmus, ob auf Ihrem Browser schon Google-Cookies von anderen Google-Diensten (YouTube. Gmail usw.) platziert sind. Anschließend setzt reCAPTCHA ein zusätzliches Cookie in Ihrem Browser und erfasst einen Schnappschuss Ihres Browserfensters. <br /> <br />

Die folgende Liste von gesammelten Browser- und Userdaten, hat nicht den Anspruch auf Vollständigkeit. Vielmehr sind es Beispiele von Daten, die nach unserer Erkenntnis, von Google verarbeitet werden.
<br />
Referrer URL (die Adresse der Seite von der der Besucher kommt)
IP-Adresse (z.B. 256.123.123.1)
Infos über das Betriebssystem (die Software, die den Betrieb Ihres Computers ermöglicht. Bekannte Betriebssysteme sind Windows, Mac OS X oder Linux)
Cookies (kleine Textdateien, die Daten in Ihrem Browser speichern)
Maus- und Keyboardverhalten (jede Aktion, die Sie mit der Maus oder der Tastatur ausführen wird gespeichert)
Datum und Spracheinstellungen (welche Sprache bzw. welches Datum Sie auf Ihrem PC voreingestellt haben wird gespeichert)
Alle Javascript-Objekte (JavaScript ist eine Programmiersprache, die Webseiten ermöglicht, sich an den User anzupassen. JavaScript-Objekte können alle möglichen Daten unter einem Namen sammeln)
Bildschirmauflösung (zeigt an aus wie vielen Pixeln die Bilddarstellung besteht)
Unumstritten ist, dass Google diese Daten verwendet und analysiert noch bevor Sie auf das Häkchen „Ich bin kein Roboter“ klicken. Bei der Invisible reCAPTCHA-Version fällt sogar das Ankreuzen weg und der ganze Erkennungsprozess läuft im Hintergrund ab. Wie viel und welche Daten Google genau speichert, erfährt man von Google nicht im Detail.

reCAPTCHA Demo: Zur Demoseite

Wie lange und wo werden die Daten gespeichert? <br /> <br />
Durch das Einfügen von reCAPTCHA werden Daten von Ihnen auf den Google-Server übertragen. Wo genau diese Daten gespeichert werden, stellt Google, selbst nach wiederholtem Nachfragen, nicht klar dar. Ohne eine Bestätigung von Google erhalten zu haben, ist davon auszugehen, dass Daten wie Mausinteraktion, Verweildauer auf der Webseite oder Spracheinstellungen auf den europäischen oder amerikanischen Google-Servern gespeichert werden. Die IP-Adresse, die Ihr Browser an Google übermittelt, wird grundsätzlich nicht mit anderen Google-Daten aus weiteren Google-Diensten zusammengeführt. Wenn Sie allerdings während der Nutzung des reCAPTCHA-Plug-ins bei Ihrem Google-Konto angemeldet sind, werden die Daten zusammengeführt. Dafür gelten die abweichenden Datenschutzbestimmungen der Firma Google.

Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
Wenn Sie wollen, dass über Sie und über Ihr Verhalten keine Daten an Google übermittelt werden, müssen Sie sich, bevor Sie unsere Webseite besuchen bzw. die reCAPTCHA-Software verwenden, bei Google vollkommen ausloggen und alle Google-Cookies löschen. Grundsätzlich werden die Daten sobald Sie unsere Seite aufrufen automatisch an Google übermittelt. Um diese Daten wieder zu löschen, müssen Sie den Google-Support auf https://support.google.com/?hl=de&tid=211101959 kontaktieren.

Wenn Sie also unsere Webseite verwenden, erklären Sie sich einverstanden, dass Google LLC und deren Vertreter automatisch Daten erheben, bearbeiten und nutzen.

Etwas mehr über reCAPTCHA erfahren Sie auf der Webentwickler-Seite von Google auf https://developers.google.com/recaptcha/. Google geht hier zwar auf die technische Entwicklung der reCAPTCHA näher ein, doch genaue Informationen über Datenspeicherung und datenschutzrelevanten Themen sucht man auch dort vergeblich. Eine gute Übersicht über die grundsätzliche Verwendung von Daten bei Google finden Sie in der hauseigenen Datenschutzerklärung auf https://www.google.com/intl/de/policies/privacy/.

<br /> <br />
<h4>Facebook Datenschutzerklärung</h4> <br />
Wir verwenden Facebook Social Plug-Ins des sozialen Netzwerks Facebook, betrieben von den Unternehmen Facebook Ireland Limited, 4 Grand Canal Square Dublin 2, Irland, und Facebook Inc., 1601 S. California Avenue, Palo Alto, CA 94303, USA. Diese Plug-Ins sind Schaltflächen, mit denen Facebook misst, wer unsere Webseite aufruft. Ist der Besucher zugleich bei Facebook als Nutzer registriert und eingeloggt, werden darüber hinaus weitere Informationen von Facebook gespeichert. Facebook erhält aus technischen Gründen Ihre IP-Adresse. Auf die Verwendung dieser Daten durch Facebook haben wir keinen Einfluss. Wir verweisen diesbezüglich auf die Datenschutzerklärungen von Facebook, insbesondere die unter folgendem Link abrufbaren Informationen: https://www.facebook.com/help/568137493302217.
Sie erhalten dort auch Informationen, wie Sie mit Ihrem Browser Facebook Social Plug-Ins blockieren können.
<br /> <br />

<h4>Instagram Datenschutzerklärung</h4> <br /> 
Wir verwenden auf unserer Webseite Funktionen des Social Media Netzwerks Instagram der Firma Instagram LLC, 1601 Willow Rd, Menlo Park CA 94025, USA.

Mit den Funktionen zum Einbetten von Instagram-Inhalten (Embed-Funktion) können wir Bilder und Videos anzeigen.

Durch den Aufruf von Seiten die solche Funktionen nutzen werden Daten (IP-Adresse, Browserdaten, Datum und Zeitpunkt, Cookies) an Instagram übermittelt, gespeichert und ausgewertet.

Sollten Sie ein Instagram-Konto haben und angemeldet sein, werden diese Daten Ihrem persönlichen Konto und den darin gespeicherten Daten zugeordnet.

Die Datenschutzrichtlinien, welche Informationen Instagram sammelt und wie sie diese verwenden finden Sie auf https://help.instagram.com/155833707900388.

<br /> <br />
<h4>Name und Anschrift des für die Verarbeitung Verantwortlichen</h4><br /> 
Verantwortlicher im Sinne der Datenschutz-Grundverordnung und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:
<br />
MCW Handelsgesellschaft mbH <br />
Esterhazygasse 23 <br />
1060 Wien <br />
Austria <br />
Tel.: +43-(0)1-6675 665-0 <br />
E-Mail: service@mcw.at <br />

<br /> <br />
<h4>Der Datenschutzbeauftragte des für die Verarbeitung Verantwortlichen: </h4><br />
Edwin Skorepa <br />
MCW Handelsgesellschaft mbH <br />
Breuninggasse 6 <br />
1230 Wien <br />
Austria <br />
Tel.: +43-(0)1-6675 665-0 <br />
E-Mail: datenschutz@mcw.at
            </p>

            </div>

        </div>
    )
}

export default Datenschutz
