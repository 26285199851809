import React, {useState} from 'react';
import "./Funktion.css";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


function Funktion1({img, title1, title2, paket, info, feature1, feature2,feature3}) {

const [toggle, setToggle] = useState(false);
 
const toggler = () => {
    setToggle(!toggle) 
}



    return (
        <div onClick={toggler}  className={toggle ? "funktion" : "funktion_open"}>
            <div className="funktion_container">
            <div className="funktion_container_links">
                <img src={img} alt="Funktion-2"/>
                <h3> <span className="first_word">{title1}</span> {title2}</h3>
            </div>
            <div className={toggle? "funktion_container_rechts_open" : "funktion_container_rechts"} >
                <h4>{paket}</h4>
                <ExpandMoreIcon />
            </div>

            </div>
        <div className={toggle ? "funktion_container_hidden" : "funktion_container_hide"} 
       >
          <div className="funktion_hidden_text"> 
          <p>{info}</p>
            </div>
        </div> 
        </div> 
    )
}

export default Funktion1 ;